import { initialState } from '../constants';
import { InstrumentFormState } from '../types';

const onDeleteSucceed = (): InstrumentFormState => ({
  ...initialState,

  deleteStatus: {
    succeed: true,
    pending: false,
    error: false,
  },
});

export default onDeleteSucceed;
