import startCase from 'lodash/startCase';
import { FC } from 'react';
import { Checkbox, formatDate } from 'react-ui-kit-exante';

import { IEventTableProps } from '../../types';
import { EventTableStyled, ErrorMessage } from '../Event.styled';
import { DASH } from '../constants';

export const EventTable: FC<IEventTableProps> = ({ event }) => {
  const result = event?.result;
  const errorMessage = result?.exc_message;
  const successScheduled = event?.scheduled;
  const resultScheduled = result?.scheduled;
  const action = startCase(event?.step) || startCase(result?.step) || DASH;
  const dateDone = event?.dateDone;

  const dryRun =
    event && typeof event['dry-run'] === 'boolean' ? (
      <Checkbox checked={event['dry-run']} disabled />
    ) : (
      DASH
    );

  const getScheduled = () => {
    if (typeof successScheduled === 'boolean') {
      return <Checkbox checked={successScheduled} disabled />;
    }
    if (typeof resultScheduled === 'boolean') {
      return <Checkbox checked={resultScheduled} disabled />;
    }
    return DASH;
  };

  return (
    <>
      <EventTableStyled>
        <thead>
          <tr>
            <th>{event?.count?.checked || DASH}</th>
            <th>{startCase(event?.status.toLowerCase())}</th>
            <th>{dateDone && formatDate({ date: dateDone })}</th>
            <th>{action}</th>
            <th>{getScheduled()}</th>
            <th>{dryRun}</th>
            <th>{event?.taskId}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Check</td>
            <td>Status</td>
            <td>Date Done</td>
            <td>Action</td>
            <td>On Schedule</td>
            <td>Dry Run</td>
            <td>Task ID</td>
          </tr>
        </tbody>
      </EventTableStyled>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};
