import { yupResolver } from '@hookform/resolvers/yup';
import { orderBy, indexOf } from 'lodash';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Input,
  Panel,
  Notification,
  IconButton,
  Loader,
} from 'react-ui-kit-exante';

import { symbolDBService } from '../../../services/symbolDB.service';
import themeStyles from '../../Themes/themes.module.css';
import {
  EDITABLE_FIELDS,
  EXCLUDED_FIELDS,
  FIXED_ORDER,
  SuccessMessages,
} from '../constants';
import { ISymbolTypeProps, ISymbolTypeFormValues } from '../types';

import { schema } from './SymbolType.schema';
import styles from './SymbolTypeForm.module.css';

export const SymbolTypeForm: FC<ISymbolTypeProps> = ({
  symbolType,
  onClose,
  onUpdateSymbolType,
  title = 'New Symbol Type',
}) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ISymbolTypeFormValues>({
    defaultValues: symbolType,
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reset({ ...symbolType });
  }, [symbolType, reset]);

  const controls = useMemo(
    () => (
      <div className={themeStyles.Controls}>
        <IconButton
          data-test-id="symbol-type__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="save"
          iconSize={24}
          label="Save"
          type="submit"
        />
        <IconButton
          iconColor="secondary"
          iconName="close"
          iconSize={24}
          onClick={onClose}
        />
      </div>
    ),
    [isDirty, onClose],
  );

  const onSubmit = async (values: ISymbolTypeFormValues) => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      setIsLoading(true);
      const response = await symbolDBService().updateSymbolType(values);

      if (response) {
        Notification.success({ title: SuccessMessages.Update });
        onUpdateSymbolType();
        onClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderControl = (key: keyof ISymbolTypeFormValues): ReactNode => {
    if (EXCLUDED_FIELDS.includes(key)) {
      return null;
    }

    const isDisabled = !EDITABLE_FIELDS.includes(key);

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            disabled={isDisabled}
            error={Boolean(errors[key])}
            fullWidth
            label={key}
            {...field}
            sx={{ mt: '16px', width: '50%' }}
          />
        )}
      />
    );
  };

  const orderedFields = useMemo(
    () =>
      orderBy(Object.keys(symbolType), (name) => indexOf(FIXED_ORDER, name), [
        'desc',
      ]),
    [symbolType],
  );

  return !isLoading ? (
    <form className={styles.Wrapper} onSubmit={handleSubmit(onSubmit)}>
      <Panel title={symbolType?.name || title} action={controls}>
        <div className={styles.Fields}>
          {(orderedFields as Array<keyof typeof symbolType>).map(renderControl)}
        </div>
      </Panel>
    </form>
  ) : (
    <Loader size="l" isCentered />
  );
};
