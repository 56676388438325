import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const LambdaSettings = ({ id }: SelectedFeedProvider) => {
  const { current: handlerTypeOptions } = useRef<ISelectOption[]>([
    { value: 'simpleQuoteTransform', label: 'simpleQuoteTransform' },
  ]);

  return (
    <Section>
      <SectionTitle>Lambda settings</SectionTitle>
      <TextInput path={`feeds.providerOverrides.${id}.lambdaSettings.sources`}>
        Sources
      </TextInput>
      <Select
        options={handlerTypeOptions}
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.type`}
      >
        Type
      </Select>
      <TextInput
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.transform`}
      >
        Transform
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.description`}
      >
        Description
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.parameters`}
      >
        Parameters
      </TextInput>
    </Section>
  );
};

export default LambdaSettings;
