import { Table, Loader } from 'react-ui-kit-exante';

import CurrencyHolidaysTableStyles from '../CurrencyHolidays.module.css';
import { COLUMNS } from '../constants';

import { CurrencyHolidaysTableProps } from './CurrencyHolidaysTable.types';

export const CurrencyHolidaysTable = ({
  currency,
  data,
  onClose,
  loading,
}: CurrencyHolidaysTableProps) => {
  if (!data) {
    return null;
  }

  if (loading) {
    return (
      <div className={CurrencyHolidaysTableStyles.LoaderContainer}>
        <Loader size="l" />
      </div>
    );
  }

  return (
    <Table
      title={currency}
      tableId={`${currency}-holidays`}
      columns={COLUMNS}
      data={data}
      hasPagination
      isFlexLayout
      className={CurrencyHolidaysTableStyles.Table}
      showTableInfo
      isHiddenColumnSelect
      onClose={onClose}
    />
  );
};
