import { styled } from '@mui/material';
import { TDefaultTheme, Panel } from 'react-ui-kit-exante';

export const List = styled('ul')(({ theme }: TDefaultTheme) => ({
  backgroundColor: theme?.color?.bg?.primary,
  padding: 24,
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
}));

export const Wrapper = styled(Panel)(({ theme }: TDefaultTheme) => ({
  borderRight: `1px solid ${theme?.color?.dropdown?.border}`,
  overflow: 'scroll !important',
  maxHeight: 'calc(100vh - 201px) !important',
}));
