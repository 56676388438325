import React from 'react';

import { ReactComponent as ChatIcon } from '../../../../assets/i/Chat.svg';
import { StyledLink } from '../../../../components/StyledLink/StyledLink.styled';
import { FeedsRoutes } from '../../constants';

import styles from './FeedFormPlaceholder.module.css';

const FeedFormPlaceholder: React.FC = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Icon}>
        <ChatIcon />
      </div>
      <p className={styles.Message}>
        Select any Feed on the left or
        <StyledLink to={FeedsRoutes.NEW}>create</StyledLink>a new one
      </p>
    </div>
  );
};

export default FeedFormPlaceholder;
