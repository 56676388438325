import axios from 'axios';

import { UnauthorizedError } from '../../utils/errors';
import { sendUiKitErrorNotification } from '../../utils/notification';
import { Logger } from '../influxProxy/types';

import { SessionRepository } from './session.repository';

interface IRequestArguments<T = Record<string, never>> {
  params?: T;
  onSuccess?(): void;
  onError?(error: Error): void;
}

const UNAUTHORIZED_STATUS_CODE = 401;
const NO_TOKEN_STATUS_CODE = 403;
const INVALID_TOKE_STATUS_CODE = 404;

const authErrorCodes = [
  UNAUTHORIZED_STATUS_CODE,
  NO_TOKEN_STATUS_CODE,
  INVALID_TOKE_STATUS_CODE,
];

export class SessionService {
  public logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger;
  }

  public async registerSession({ onError, onSuccess }: IRequestArguments) {
    try {
      await SessionRepository.registerSession();

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      this.logger.error(error);
      sendUiKitErrorNotification(error);

      if (onError) {
        const isServerError = axios.isAxiosError(error);

        if (isServerError) {
          const status = Number(error.response?.status);
          const isAuthError = authErrorCodes.includes(status);

          if (isAuthError) {
            onError(new UnauthorizedError());
            return;
          }
        }

        onError(error as Error);
      }
    }
  }
}
