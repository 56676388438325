import styled from 'styled-components';

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  gap: 12px;
  flex: 0 1 auto;

  & div {
    grid-template-rows: unset;
  }
`;
