import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO } from 'date-fns';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import {
  Panel,
  Autocomplete,
  IconButton,
  Checkbox,
  Input,
  Notification,
} from 'react-ui-kit-exante';

import { symbolDBService } from '../../../services/symbolDB.service';
import { checkIsValidIsoString } from '../../../utils/checkIsValidISOString';
import { formatDate } from '../../../utils/formatDate';
import { SuccessMessages, LOCALIZATION_PROPS } from '../constants';
import { getProp } from '../helpers';
import styles from '../themes.module.css';
import { IFormValues, IThemeFormProps } from '../types';

import { schema } from './ThemeForm.schema';

export const ThemeForm: FC<IThemeFormProps> = ({
  isNewTheme,
  onClose,
  theme,
  onUpdateThemes,
  onDelete,
  title = 'New theme',
  localizationKeys,
  brandings,
  tags,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<IFormValues>({
    defaultValues: theme,
    resolver: yupResolver(schema),
  });

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id } = theme;
    onDelete(_id as string);
  }, [onDelete, theme]);

  useEffect(() => {
    reset({ ...theme });
  }, [reset, theme]);

  const onSubmit = async (values: IFormValues) => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id } = values;
      const response = isNewTheme
        ? await symbolDBService().createTheme(values)
        : await symbolDBService().updateTheme(values, _id);
      if (response) {
        Notification.success({
          title: isNewTheme ? SuccessMessages.Create : SuccessMessages.Update,
        });
        onUpdateThemes();
        onClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  };

  const controls = useMemo(
    () => (
      <div className={styles.Controls}>
        <IconButton
          data-test-id="themes__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="save"
          iconSize={24}
          label="Save"
          type="submit"
        />
        {!isNewTheme && (
          <IconButton
            data-test-id="themes__button--delete"
            iconColor="radical"
            iconName="delete"
            iconSize={24}
            label="Delete"
            onClick={handleDelete}
            type="button"
          />
        )}
        <IconButton
          data-test-id="themes__button--close"
          iconColor="secondary"
          iconName="close"
          iconSize={24}
          onClick={onClose}
        />
      </div>
    ),
    [isDirty, isNewTheme, handleDelete, onClose],
  );

  const renderControl = (key: keyof IFormValues): ReactNode => {
    const prop = getProp(theme, key);
    const isDisabled = key.startsWith('_');

    if (typeof prop === 'object' && !Array.isArray(prop) && prop !== null) {
      return Object.keys(prop).map((k) => {
        const nestedKey = `${key}.${k}`;

        return renderControl(nestedKey as keyof IFormValues);
      });
    }

    const isLocalizedProp = LOCALIZATION_PROPS.some((name) =>
      key.includes(name),
    );

    const error = getProp(errors, key) as FieldError;

    if (isLocalizedProp) {
      return (
        <Controller<IFormValues>
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => {
            const onChange = (event: any, newValue: any) =>
              field.onChange(newValue);

            const value = field.value as string;

            return (
              <Autocomplete
                data-test-id={`themes__input--${key}`}
                options={localizationKeys}
                controlProps={{
                  error: Boolean(error),
                  message: error?.message,
                  placeholder: key,
                }}
                sx={{ mt: '16px' }}
                {...field}
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
      );
    }

    if (key === 'taglist') {
      return (
        <Controller<IFormValues>
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => {
            const onBlur = (event: any) => {
              const value = field.value as string[];

              if (event.target.value) {
                field.onChange([...value.filter(Boolean), event.target.value]);
              }
            };

            return (
              <Autocomplete
                data-test-id={`themes__input--${key}`}
                freeSolo
                isMultiple
                clearOnBlur
                options={tags}
                {...field}
                value={field.value as string[]}
                onBlur={onBlur}
                onChange={(_e, newValue) => field.onChange(newValue)}
                controlProps={{
                  error: Boolean(error),
                  message: error?.message,
                  placeholder: key,
                }}
                sx={{ mt: '16px' }}
              />
            );
          }}
        />
      );
    }

    if (key === 'brandings') {
      return (
        <Controller<IFormValues>
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Autocomplete
              data-test-id={`themes__input--${key}`}
              freeSolo
              isMultiple
              options={brandings}
              {...field}
              value={field.value as string[]}
              onChange={(_e, newValue) => field.onChange(newValue)}
              controlProps={{
                error: Boolean(error),
                message: error?.message,
                placeholder: key,
              }}
              sx={{ mt: '16px' }}
            />
          )}
        />
      );
    }

    if (typeof prop === 'string' && checkIsValidIsoString(prop)) {
      const value = formatDate(parseISO(prop), true);

      return (
        <Controller
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              data-test-id={`themes__input--${key}`}
              disabled={isDisabled}
              fullWidth
              label={key}
              {...field}
              value={value}
              sx={{ mt: '16px' }}
            />
          )}
        />
      );
    }

    if (typeof prop === 'boolean') {
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          defaultValue={prop}
          render={({ field }) => (
            <Checkbox
              data-test-id={`themes__checkbox--${key}`}
              checked={field.value as boolean}
              label={key}
              {...field}
              sx={{ ml: '1px', mt: '16px' }}
            />
          )}
        />
      );
    }

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            data-test-id={`themes__input--${key}`}
            error={Boolean(error)}
            disabled={isDisabled}
            message={error?.message}
            fullWidth
            label={key}
            {...field}
            sx={{ mt: '16px' }}
          />
        )}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel action={controls} title={theme?.title.default || title}>
        {(Object.keys(theme) as Array<keyof typeof theme>).map(renderControl)}
      </Panel>
    </form>
  );
};
