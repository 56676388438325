import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { SortableListItem } from '../../../../../../../../components/SortableList/types';
import { getUUIDKey } from '../../../../../../../../utils/uuid';
import { FormValues } from '../../../../types';

const useAccounts = (gatewayIndex: number, form: UseFormReturn<FormValues>) => {
  const fields = form.watch(`gateways.${gatewayIndex}.accounts`);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { append, remove, swap } = useFieldArray({
    name: `gateways.${gatewayIndex}.accounts`,
    control: form.control,
  });

  const handleAppend = () =>
    append({
      name: 'New',
      uuid: getUUIDKey(),
      settlementCounterparty: '',
      executionCounterparty: '',
      legalEntity: '',
      settlementCounterpartyAccount: '',
      account: '',
      accountDescription: '',
      clientId: '',
    });

  const list = useMemo<Array<SortableListItem>>(() => {
    if (!fields) {
      return [];
    }

    return fields.map((field) => ({
      name: field.name,
      id: field.uuid,
    }));
  }, [fields]);

  const accountIndex = useMemo<number>(
    () => list.findIndex((item) => item.id === selectedId),
    [list, selectedId],
  );

  useEffect(() => {
    if (list.length > 0) {
      setSelectedId(list[0].id);
    }
  }, [list.length]);

  return {
    accountIndex,
    fields,
    remove,
    handleAppend,
    handleOrder: swap,
    list,
    selectedId,
    setSelectedId,
  };
};

export default useAccounts;
