export const findTreeItem = <T extends Record<string, any>>(
  nodesKey: keyof T,
  searchFn: (item: T) => boolean,
) => {
  const finder = (data: Array<T>): T | undefined => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      if (searchFn(item)) {
        return item;
      }

      const nodes = item[nodesKey];

      if (nodes && Array.isArray(nodes) && nodes.length > 0) {
        const result = finder(nodes);

        if (result) {
          return result;
        }
      }
    }

    return undefined;
  };

  return finder;
};

export const findTreeItemPath = <T extends Record<string, any>>(
  nodesKey: keyof T,
  searchFn: (item: T) => boolean,
  pathFn: (item: T) => string,
) => {
  const finder = (
    data: Array<T>,
    result: string[] = [],
  ): string[] | undefined => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      if (searchFn(item)) {
        return [...result, pathFn(item)];
      }

      const nodes = item[nodesKey];

      if (nodes && Array.isArray(nodes) && nodes.length > 0) {
        const path = finder(item[nodesKey], [...result, pathFn(item)]);

        if (path) {
          return path;
        }
      }
    }

    return undefined;
  };

  return finder;
};

export const getFlatTreeNodes = <T extends Record<string, any>>(
  data: Array<T>,
  nodesKey: keyof T,
  pathFn: (item: T) => string,
  result: string[] = [],
): string[] => {
  return data.reduce<string[]>((res, item) => {
    const nodes = item[nodesKey];

    if (nodes && Array.isArray(nodes) && nodes.length > 0) {
      return [...getFlatTreeNodes(nodes, nodesKey, pathFn, res), pathFn(item)];
    }

    return [...res, pathFn(item)];
  }, result);
};
