import equal from 'fast-deep-equal';
import * as objectPath from 'object-path-immutable';

import { filterFromEmpty } from '~/utils/object';

import { InstrumentFormState } from '../types';

const onRemove = (
  state: InstrumentFormState,
  path: string,
): InstrumentFormState => {
  const values = objectPath.del(state.values, path);

  return {
    ...state,

    values,
    dirty: !equal(filterFromEmpty(values), filterFromEmpty(state.ref)),
  };
};

export default onRemove;
