import { createContext, Dispatch } from 'react';

import { initialState } from './constants';
import { InstrumentFormActionsType, InstrumentFormState } from './types';

const InstrumentFormContext = createContext<{
  state: InstrumentFormState;
  dispatch: Dispatch<InstrumentFormActionsType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export default InstrumentFormContext;
