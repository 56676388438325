import React from 'react';

import { ReactComponent as ChatIcon } from '../../../assets/i/Chat.svg';
import { StyledSpan } from '../../../components/StyledSpan/StyledSpan.styled';

import styles from './Placeholder.module.css';

const Placeholder: React.FC<{ create: () => void }> = ({ create }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Icon}>
        <ChatIcon />
      </div>
      <p className={styles.Message}>
        Select any currency on the left or
        <StyledSpan onClick={create}>create</StyledSpan>a new one
      </p>
    </div>
  );
};

export default Placeholder;
