import qs from 'qs';

import { SESSION_ID_COOKIE_KEY } from '~/services/authorization/authorization.consts';
import { getSessionIdFromCookie } from '~/services/cookies/cookies.service';

export const convertNullToString = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.keys(obj).reduce<Record<string, unknown>>((result, key) => {
    const value = obj[key] as Record<string, unknown>;

    if (value === null) {
      return {
        ...result,
        [key]: 'null',
      };
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      return {
        ...result,
        [key]: convertNullToString(value),
      };
    }

    return {
      ...result,
      [key]: value,
    };
  }, {});
};

export const stringify = (obj?: Record<string, unknown>): string => {
  if (!obj || Object.keys(obj).length === 0) {
    return '';
  }

  const nullishConverted = convertNullToString(obj);

  return qs.stringify(nullishConverted);
};

export const getQueryURL = (
  path: string,
  params?: Record<string, unknown>,
): string => {
  const query = stringify(params);

  return query !== '' ? `${path}?${query}` : path;
};

export const getSessionProps = () => ({
  sessionToken: getSessionIdFromCookie(SESSION_ID_COOKIE_KEY) || '',
});
