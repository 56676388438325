import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '~/hooks/useFetchStatus';
import { symbolDBService } from '~/services/symbolDB.service';
import { ExchangeItem } from '~/types/models';

import useExchangesRouting from './useExchangesRouting';

const useExchangesList = () => {
  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();

  const [exchangeItems, setExchangeItems] = useState<ExchangeItem[]>([]);
  const { handleOpenExchange, handleCloseExchangeForm } = useExchangesRouting();

  const setSchedulesOptions = async (): Promise<void> => {
    fetchingStatusActions.handleStart();

    try {
      const data = await symbolDBService().getExchanges();

      setExchangeItems(data);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  const handleSuccessCreate = (payload: ExchangeItem) => {
    setExchangeItems([payload, ...exchangeItems]);
    handleOpenExchange(payload._id);
  };

  const handleSuccessUpdate = (payload: ExchangeItem) => {
    const existIndex = exchangeItems.findIndex(
      (schedule) => schedule._id === payload._id,
    );

    if (existIndex !== -1) {
      setExchangeItems([
        ...exchangeItems.slice(0, existIndex),
        payload,
        ...exchangeItems.slice(existIndex + 1),
      ]);
    }
  };

  const handleSuccessDelete = (id: string) => {
    const existIndex = exchangeItems.findIndex(
      (schedule) => schedule._id === id,
    );

    if (existIndex !== -1) {
      handleCloseExchangeForm();
      setExchangeItems([
        ...exchangeItems.slice(0, existIndex),
        ...exchangeItems.slice(existIndex + 1),
      ]);
    }
  };

  useEffect(() => {
    setSchedulesOptions();
  }, []);

  return {
    exchangeItems,
    isLoading: fetchingStatus.isPending && !fetchingStatus.isSucceed,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  };
};

export default useExchangesList;
