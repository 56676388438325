import { Skeleton, Table } from 'react-ui-kit-exante';

import TitleWithActions from '../TitleWithActions';
import { initialState } from '../TreeContext/constants';
import type { TreeState } from '../TreeContext/types';
import { createUniqueArr } from '../helpers';

import {
  Wrapper,
  Tree,
  Forms,
  SetupSelects,
  Content,
  WrapperTable,
  Form,
  ScheduleSelects,
  Indicators,
  ToggleSchedule,
  Indicator,
  Loader,
  Block,
} from './SkeletonMainPage.styled';

const fakeColumns = [
  {
    Header: 'STATUS',
    accessor: 'status',
    width: '90',
  },
  {
    Header: 'DATE DONE',
    accessor: 'date',
    width: '110',
  },
  {
    Header: 'ACTION',
    accessor: 'step',
    width: '70',
  },
  {
    Header: 'ON SCHEDULE',
    accessor: 'scheduled',
    width: '70',
  },
  {
    Header: 'DRY RUN',
    accessor: 'dry-run',
    width: '70',
  },
];

type SkeletonMainContentProps = {
  instrument: TreeState;
};

export const SkeletonMainContent = ({
  instrument,
}: SkeletonMainContentProps) => {
  const exchangeIdParam = instrument.exchangeId;

  return (
    <Forms>
      <TitleWithActions
        runAll={() => {}}
        taskId={null}
        eventId=""
        cronValue=""
        providerId=""
        scheduleId=""
        onSave={() => {}}
        disabledRun={() => true}
        isChangedSchedule={false}
        isChangedSetupForm={false}
        instrument={instrument}
      />
      {exchangeIdParam && (
        <Content>
          <Form>
            <Block title="Setup">
              <SetupSelects>
                <Skeleton variant="text" width={190} height={80} />
                <Skeleton variant="text" width={190} height={80} />
              </SetupSelects>
            </Block>
            <Block title="Schedule">
              <ScheduleSelects>
                <ToggleSchedule>
                  <Skeleton variant="rounded" width={40} height={25} />
                  <span>Enabled</span>
                </ToggleSchedule>
                <Skeleton variant="rounded" width={400} height={40} />
              </ScheduleSelects>
            </Block>
            <Loader title="Current">
              <Indicators>
                <Indicator>Load from source</Indicator>
                <Indicator>Prepare</Indicator>
                <Indicator>Sync</Indicator>
              </Indicators>
            </Loader>
          </Form>
          <WrapperTable>
            <Table
              data={[]}
              hasFilters
              isFlexLayout
              hasPagination
              showTableInfo
              title="History"
              columns={fakeColumns}
              tableId="fakeColumns"
              saveViewParamsAfterLeave
              isLoading
            />
          </WrapperTable>
        </Content>
      )}
    </Forms>
  );
};

export const SkeletonMainPage = () => {
  const uniqueKeys = createUniqueArr(23);

  return (
    <Wrapper>
      <Tree>
        {uniqueKeys.map((key: number) => (
          <Content key={key}>
            <Skeleton variant="text" width={25} height={30} />
            <Skeleton variant="text" width={120} height={30} />
          </Content>
        ))}
      </Tree>
      <SkeletonMainContent instrument={initialState} />
    </Wrapper>
  );
};
