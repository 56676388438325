import { TreeItem } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const TreeContainer = styled.div`
  display: grid;
  padding: 8px 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const LoadingItem = styled.div`
  display: flex;
  gap: 12px;
`;

export const TreeItemContainer = styled(TreeItem)`
  font-size: 14px;

  .loader {
    transform: scale(1.6);
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FetchMoreButton = styled.button`
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0;
  margin-left: 18px;
  user-select: none;
  gap: 8px;
  background: transparent;
  border: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.typo.action};

  & svg {
    transform: scale(0.85);
  }

  & .loader {
    transform: scale(1);
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export const Label = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const SyncIconContainer = styled.div`
  color: ${({ theme }) => theme.color.icon.secondary};
  display: flex;
`;
