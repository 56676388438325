import { FilledInputProps } from '@mui/material/FilledInput';
import dayjs from 'dayjs';
import * as objectPath from 'object-path-immutable';
import { useContext, useState } from 'react';

import { DIGITS } from '~/constants/regexp';
import { InstrumentSplit } from '~/types/models';
import {
  getIterableFormItem,
  mapIterableFormItems,
  restoreIterableFormItems,
} from '~/utils/form';

import { InstrumentFormContext } from '../../useForm';
import { InstrumentFormActions } from '../../useForm/actions';
import { getInheritValue, getSelfValue } from '../../useForm/utils';

import { getEmptyItem } from './utils';

export const RootPath = 'splits';

const useSplits = () => {
  const { state, dispatch } = useContext(InstrumentFormContext);

  const disabled = state.saveStatus.pending;
  const selfValue = getSelfValue<InstrumentSplit[]>(RootPath, state.values);
  const inheritValue = getInheritValue<InstrumentSplit[]>(
    RootPath,
    state.parents,
  );

  const [value, setValue] = useState(
    mapIterableFormItems<InstrumentSplit>(selfValue || inheritValue),
  );

  const handleAddItem = () => {
    if (disabled) {
      return;
    }

    const emptyItem = getEmptyItem();
    const result = [...value, getIterableFormItem<InstrumentSplit>(emptyItem)];

    setValue(result);

    dispatch({
      type: InstrumentFormActions.SetFieldValue,
      payload: { path: RootPath, value: restoreIterableFormItems(result) },
    });
  };
  const handleRemoveItem = (index: number) => {
    if (disabled) {
      return;
    }

    const result = [...value.slice(0, index), ...value.slice(index + 1)];

    setValue(result);

    dispatch({
      type: InstrumentFormActions.SetFieldValue,
      payload: { path: RootPath, value: restoreIterableFormItems(result) },
    });
  };

  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = isInherited && selfValue !== undefined;
  const inheritTitle = inheritValue ? inheritValue.length : '';
  const handleReset = () => {
    if (disabled) {
      return;
    }

    setValue(mapIterableFormItems<InstrumentSplit>(inheritValue));

    dispatch({
      type: InstrumentFormActions.SetFieldValue,
      payload: { path: RootPath, value: undefined },
    });
  };
  const registerDateField = (fieldPath: string) => {
    const onChange = (date: Date | null) => {
      if (disabled) {
        return;
      }

      const unixTime = dayjs(date).unix();

      const result = objectPath.set(value, fieldPath, unixTime);
      setValue(result);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: RootPath, value: restoreIterableFormItems(result) },
      });
    };

    const inputValue = objectPath.get(value, fieldPath) || null;

    const error = state.errors.has(fieldPath);
    const message = state.errors.get(fieldPath);

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: dayjs(inputValue * 1000).toDate(),
    };
  };

  const registerDigitsField = (fieldPath: string) => {
    const onChange: FilledInputProps['onChange'] = ({ target }) => {
      if (!DIGITS.test(target.value) && target.value !== '') {
        return;
      }

      const result = objectPath.set(value, fieldPath, target.value);
      setValue(result);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: RootPath, value: restoreIterableFormItems(result) },
      });
    };

    const inputValue = objectPath.get(value, fieldPath) || '';

    const error = false;
    const message = '';

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  return {
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    registerDateField,
    registerDigitsField,
    value,
    disabled,
  };
};

export default useSplits;
