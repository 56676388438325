import { IconButton, Loader } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const Container = styled.form`
  flex: 1 1 auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.bg.primary};
  position: sticky;
  z-index: 102;
  top: 0;
  background: ${({ theme }) => theme.color.bg.primary};
`;

export const Title = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const Loading = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 70vh;
  width: 100%;
`;

export const ActionButton = styled(IconButton)`
  display: flex;
  padding-right: 8px;
  & svg {
    transform: scale(0.75);
  }
`;

export const CloseButton = styled(IconButton)`
  display: flex;
  & svg {
    transform: scale(0.7);
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.color.typo.primary};
  width: 500px;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-weight: 400;
`;
