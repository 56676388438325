import { DateObject, DateTimeObject } from '~/types/shared';

export const getDateValue = (dateObject?: DateObject): Date | null => {
  if (!dateObject) {
    return null;
  }

  if (dateObject.day) {
    return new Date(dateObject.year, dateObject.month - 1, dateObject.day);
  }

  return new Date(dateObject.year, dateObject.month);
};

export const getDateString = (dateObject?: DateObject): string | null => {
  if (!dateObject) {
    return null;
  }

  return `${dateObject.day}/${dateObject.month}/${dateObject.year}`;
};

export const getDateTimeString = (
  dateTimeObject?: DateTimeObject,
): string | null => {
  if (!dateTimeObject) {
    return null;
  }

  if (dateTimeObject.day && dateTimeObject.month && dateTimeObject.year) {
    return `${dateTimeObject.day}/${dateTimeObject.month}/${dateTimeObject.year}`;
  }

  return null;
};

export const getDateObject = (date?: Date | null): DateObject | null => {
  if (!date) {
    return null;
  }

  return {
    day: date.getDay(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };
};

export const isDateObjectValid = (obj?: DateTimeObject): boolean => {
  if (!obj) {
    return false;
  }

  return (
    obj.day !== undefined && obj.month !== undefined && obj.year !== undefined
  );
};

export const getInitialDateValue = (
  self?: DateTimeObject,
  inherit?: DateTimeObject,
): DateObject | undefined => {
  if (isDateObjectValid(self)) {
    return self;
  }

  if (isDateObjectValid(inherit)) {
    return inherit;
  }

  return undefined;
};
