import { Notification } from 'react-ui-kit-exante';

export const showErrorNotification = (error: unknown) => {
  if (typeof error === 'string') {
    Notification.error({ title: error });
  } else if (error instanceof Error) {
    Notification.error({ title: error.message });
  } else {
    Notification.error({ title: 'Unknown error' });
  }
};
