import { useCallback, useRef } from 'react';

import { influxProxyService } from '~/resources';

export function useLogHandleTime(entity: string) {
  const start = useRef<string | null>(null);

  const setStartHandleTime = useCallback(() => {
    if (start.current === null) {
      start.current = new Date().toISOString();
    }
  }, []);

  const logHandleTime = useCallback(() => {
    if (start.current) {
      influxProxyService.postLogHandleTime({
        entity,
        start: start.current,
        end: new Date().toISOString(),
      });

      start.current = null;
    }
  }, []);

  return {
    setStartHandleTime,
    logHandleTime,
  };
}
