import { TextInput } from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const HttpProperties = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>HTTP properties</SectionTitle>
      <TextInput
        path={`feeds.providerOverrides.${id}.httpProperties.renewIntervalQuote`}
      >
        Renew interval quote
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.httpProperties.renewIntervalAuxData`}
      >
        Renew interval aux data
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.httpProperties.renewIntervalBondData`}
      >
        Renew interval bond data
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.httpProperties.renewIntervalOption`}
      >
        Renew interval option
      </TextInput>
    </Section>
  );
};

export default HttpProperties;
