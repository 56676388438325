export const getCapitalLetters = (str: string): string => {
  return str.replace(/[^A-Z]+/g, '');
};

export const getCapitalizedWords = (str: string): string => {
  if (!str) {
    return '';
  }

  const result = str.split(/(?=[A-Z])/).join(' ');

  const firstLetter = result.charAt(0);
  const phrase = result.slice(1);

  return firstLetter.toUpperCase() + phrase;
};

export const trimLowerCase = (str: string): string => {
  if (!str.length) {
    return '';
  }

  const isLowerCased = getCapitalLetters(str).length === 0;
  if (isLowerCased) {
    return str;
  }

  return `${str.charAt(0).toLowerCase()}${getCapitalLetters(str.slice(1))}`;
};
