import { IconButton, Input } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import {
  ArrayContainer,
  ButtonsContainer,
  DeleteButton,
  SpacedContainer,
  Title,
  VerticalContainer,
} from '../styled';

import { Item } from './styled';
import useLegs from './useLegs';

const Legs = () => {
  const {
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    disabled,
    register,
    value,
  } = useLegs();

  return (
    <ArrayContainer disabled={disabled}>
      <SpacedContainer>
        <Title>Legs</Title>

        <ButtonsContainer>
          {isInherited && (
            <InheritButton
              value={inheritTitle}
              isActive={isResetButtonActive}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={handleAddItem}
            iconName="add"
            iconColor="promo"
            iconSize={22}
          />
        </ButtonsContainer>
      </SpacedContainer>
      <VerticalContainer>
        {value.map((item, index) => (
          <Item key={item.uuid}>
            <Input
              fullWidth
              label="Quantity"
              {...register(`${index}.quantity`)}
            />

            <Input
              fullWidth
              label="Exante ID"
              {...register(`${index}.exanteId`)}
            />

            <DeleteButton
              iconName="delete"
              iconColor="promo"
              onClick={() => handleRemoveItem(index)}
            />
          </Item>
        ))}
      </VerticalContainer>
    </ArrayContainer>
  );
};

export default Legs;
