import cx from 'classnames';
import React, { HTMLProps } from 'react';
import { Loader } from 'react-ui-kit-exante';

import styles from './Button.module.css';
import { ButtonColor, ButtonProps } from './types';

type Props = HTMLProps<HTMLButtonElement> & ButtonProps;

const getColorClassName = (
  color: ButtonColor = 'default',
  isLoading?: boolean,
) => {
  if (isLoading) {
    return styles.ColorDefault;
  }

  switch (color) {
    // case 'green':
    //   return styles.ColorGreen;

    default:
      return styles.ColorDefault;
  }
};

const Button: React.FC<Props> = ({
  icon,
  children,
  className,
  color,
  type = 'button',
  isLoading,
  ...props
}) => {
  const buttonClassName = cx(
    styles.Container,
    getColorClassName(color, isLoading),
    className,
  );

  return (
    <button
      {...props}
      className={buttonClassName}
      type={type}
      disabled={isLoading || props.disabled}
    >
      {isLoading ? <Loader /> : icon}
      <span>{children}</span>
    </button>
  );
};

export default Button;
