import { IColumn } from 'react-ui-kit-exante';

import { RemoveButton } from './RemoveButton/RemoveButton';
import type { CurrencyHolidaysColumn } from './types';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const FIELDS_WIDTH = { minWidth: 195, width: 300 };

export const COLUMNS: IColumn<CurrencyHolidaysColumn>[] = [
  {
    Header: 'Date',
    accessor: 'date',
    minWidth: 150,
  },
  {
    Header: '',
    accessor: 'remove',
    minWidth: 50,
    disableSortBy: true,
    Cell: RemoveButton,
  },
];

export const LOCAL_STORAGE_KEY = 'currency-holidays-active';
