import { Link as LinkContainer } from 'react-router-dom';
import styled from 'styled-components';

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 210px;
  width: 100%;
`;

export const IconContainer = styled.div`
  color: ${(props) => props.theme.color.icon.promo};
`;

export const Message = styled.p`
  color: ${(props) => props.theme.color.typo.primary};
  width: 500px;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-weight: 400;
`;

export const Link = styled(LinkContainer)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.typo.action};
`;
