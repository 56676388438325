/* eslint-disable no-underscore-dangle */

import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '../../../hooks/useFetchStatus';
import { symbolDBService } from '../../../services/symbolDB.service';
import { ExecutionSchemaItem } from '../types';

import useExecutionSchemesRouting from './useExecutionSchemesRouting';

const useExecutionSchemesList = () => {
  const [items, setItems] = useState<Array<ExecutionSchemaItem>>([]);
  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();

  const { handleOpen, handleClose } = useExecutionSchemesRouting();

  const fetchItems = async (): Promise<void> => {
    fetchingStatusActions.handleStart();

    try {
      const data = await symbolDBService().getExecutionSchemes();

      setItems(data);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  const handleCreate = (payload: ExecutionSchemaItem) => {
    setItems([payload, ...items]);
    handleOpen(payload._id);
  };

  const handleUpdate = (payload: ExecutionSchemaItem) => {
    const existIndex = items.findIndex((item) => item._id === payload._id);

    if (existIndex !== -1) {
      setItems([
        ...items.slice(0, existIndex),
        payload,
        ...items.slice(existIndex + 1),
      ]);
    }
  };

  const handleDelete = (id: string) => {
    const existIndex = items.findIndex((item) => item._id === id);

    if (existIndex !== -1) {
      handleClose();
      setItems([...items.slice(0, existIndex), ...items.slice(existIndex + 1)]);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return {
    items,
    isLoading: fetchingStatus.isPending && !fetchingStatus.isSucceed,
    handleDelete,
    handleUpdate,
    handleCreate,
  };
};

export default useExecutionSchemesList;
