import { CURRENCIES } from '../../../services/constants';
import { CurrencyItem } from '../CurrencyItem/CurrencyItem';

import { List, Wrapper } from './Currencies.styled';
import { CurrenciesProps } from './Currencies.types';

export const Currencies = ({
  activeCurrency,
  onChangeActiveCurrency,
}: CurrenciesProps) => (
  <Wrapper title="Currencies">
    <List>
      {CURRENCIES.map((currency) => (
        <CurrencyItem
          key={currency}
          title={currency}
          id={currency}
          onChangeActiveCurrency={onChangeActiveCurrency}
          isActive={currency === activeCurrency}
        />
      ))}
    </List>
  </Wrapper>
);
