import React, { useContext, useEffect, useMemo, useState } from 'react';

import { InstrumentFormContext } from '../../useForm';
import { InstrumentFormActions } from '../../useForm/actions';
import { getInheritValue, getSelfValue } from '../../useForm/utils';

const useAutocomplete = (path: string) => {
  const { state, dispatch } = useContext(InstrumentFormContext);
  const selfValue = getSelfValue<string[]>(path, state.values);
  const inheritValue = getInheritValue<string[]>(path, state.parents);

  const [value, setValue] = useState<string[]>(selfValue || inheritValue);

  const disabled = state.saveStatus.pending;
  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const inheritTitle = useMemo(() => {
    return inheritValue ? inheritValue.join(', ') : '';
  }, [inheritValue]);

  const handleChange = (_event: React.SyntheticEvent, arr?: string[]) => {
    if (!disabled) {
      setValue(arr || []);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: arr || [] },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(inheritValue);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  useEffect(() => {
    setValue(selfValue || inheritValue);
  }, [path, selfValue, inheritValue]);

  return {
    isInherited,
    isResetButtonActive,
    inheritValue,
    inheritTitle,
    error,
    value,
    disabled,
    handleChange,
    handleReset,
  };
};

export default useAutocomplete;
