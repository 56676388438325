import dayjs from 'dayjs';

import { InstrumentSplit } from '~/types/models';

export const getEmptyItem = (): InstrumentSplit => {
  return {
    timestamp: dayjs().unix(),
    coefficient: {},
  };
};
