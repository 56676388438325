import { IRow } from 'react-ui-kit-exante';

import { formatDate } from '../../../utils/formatDate';

import { ExtraProps, OptionProps, ExtraObjProps } from './types';

export type StringKey<D> = Extract<keyof D, string>;
export type IdType<D> = StringKey<D> | string;

type TFilterTypeFn = <D extends Record<string, unknown>>(
  rows: Array<IRow<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: Array<string>,
) => Array<IRow<D>>;

export const multiSelectFilter: TFilterTypeFn = (
  rows,
  columnIds,
  filterValue,
) =>
  filterValue.length !== 0
    ? rows.filter((row) =>
        columnIds.some((id) => {
          const rowValue = row.values[id];
          return rowValue && rowValue.length && filterValue.includes(rowValue);
        }),
      )
    : rows;

export const multipleValuesFilter: TFilterTypeFn = (
  rows,
  columnIds,
  filterValue,
) =>
  filterValue.length === 0
    ? rows
    : rows.filter((row) =>
        columnIds.some((id) => {
          const rowValue = row.values[id].split(', ');
          return filterValue.every((value) => rowValue.includes(value));
        }),
      );

export const dateRangeFilter: TFilterTypeFn = (
  rows,
  columnIds,
  filterValue,
) => {
  const [dateMin, dateMax] = filterValue;

  return filterValue.length === 0
    ? rows
    : rows.filter((row) =>
        columnIds.some((id) => {
          const rowValue = formatDate(new Date(row.values[id]), true);
          const prepareDateMin = dateMin?.replace(',', '');
          const prepareDateMax = dateMax?.replace(',', '');

          return (
            (!prepareDateMin || prepareDateMin <= rowValue) &&
            (!prepareDateMax || prepareDateMax >= rowValue)
          );
        }),
      );
};

export const prepareBondFiltersData = (data: string[]) =>
  data?.map((item: string) => {
    return { value: item, label: item };
  }) || [];

export const createExtraObj = ({
  mapId,
  issueAmount,
  currency,
  issuerType,
  clearAgent,
  countryRisk,
  maturityType,
}: ExtraObjProps) => {
  const prepareData = (data: OptionProps[]) => data.map((item) => item?.value);

  const extra: ExtraProps = {
    bondFilters: {
      issueAmount,
      currency: prepareData(currency),
      issuerType: prepareData(issuerType),
      clearAgent: prepareData(clearAgent),
      countryRisk: prepareData(countryRisk),
      maturityType: prepareData(maturityType),
    },
  };

  if (mapId && mapId !== 'default') {
    extra.mapId = mapId;
  }

  return extra;
};

export const createUniqueArr = (count: number): number[] => {
  const uniqArr = [];

  while (uniqArr.length < count) {
    const randomNum = Math.floor(Math.random() * 100) + 1;
    if (uniqArr.indexOf(randomNum) === -1) {
      uniqArr.push(randomNum);
    }
  }

  return uniqArr;
};
