import styled from 'styled-components';

export const Container = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: auto 100px 42px 42px 42px;
  min-height: 32px;
  align-items: center;
  padding: 0 24px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 13px;
  font-weight: 400;
  padding: 8px 0;
`;
