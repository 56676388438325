import * as yup from 'yup';

const formSchema = yup
  .object({
    name: yup.string().required('Name is required'),
    providerType: yup.string(),
    gateways: yup.array(),
  })
  .required();

export default formSchema;
