import { IterableFormItem } from '~/types/shared';
import { getUUIDKey } from '~/utils/uuid';

export const mapUnsetBoolToFormValue = <T extends Record<string, unknown>>(
  key: keyof T,
  payload: T,
): string => {
  if (key in payload) {
    return payload[key] ? 'yes' : 'no';
  }

  return 'unset';
};

export const mapUnsetFormValueToBool = <T extends Record<string, unknown>>(
  key: keyof T,
  value: string,
) => {
  if (value === 'unset') {
    return {};
  }

  return {
    [key]: value === 'yes',
  };
};

export const getIterableFormItem = <T extends Record<string, unknown>>(
  obj: T,
): IterableFormItem<T> => ({
  ...obj,
  uuid: getUUIDKey(),
});

export const mapIterableFormItems = <T extends Record<string, unknown>>(
  data?: T[],
): Array<IterableFormItem<T>> => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({ ...item, uuid: getUUIDKey() }));
};

export const restoreIterableFormItems = <T extends Record<string, unknown>>(
  data?: Array<IterableFormItem<T>>,
): Array<Omit<IterableFormItem<T>, 'uuid'>> => {
  if (!data) {
    return [];
  }

  return data.map(({ uuid, ...item }) => item);
};
