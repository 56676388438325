import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
`;

export const Actions = styled.div<{ active: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  ${(props) => props.active && 'cursor: pointer;'}

  & svg {
    scale: 0.75;
    color: ${({ active, theme }) =>
      active ? theme.color.typo.primary : theme.color.typo.secondary};
  }
`;

export const InheritTitle = styled.span<{ active: boolean }>`
  font-size: 13px;
  color: ${({ active, theme }) =>
    active ? theme.color.typo.primary : theme.color.typo.secondary};
`;
