export const isObjectEmpty = (obj?: unknown): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }

  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }

  if (typeof obj !== 'object') {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const v of Object.values(obj)) {
    const isEmpty = isObjectEmpty(v);

    if (!isEmpty) {
      return false;
    }
  }

  return true;
};

export const filterFromEmpty = (
  obj?: Record<string, unknown>,
): Record<string, unknown> => {
  if (!obj) {
    return {};
  }

  return Object.keys(obj).reduce((result, key) => {
    const value = obj[key];

    if (Array.isArray(value) && value.length > 0) {
      return {
        ...result,
        [key]: value,
      };
    }

    if (
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.length === 0) ||
      isObjectEmpty(value)
    ) {
      return result;
    }

    if (typeof value === 'object' && value !== null) {
      return {
        ...result,
        [key]: filterFromEmpty(value as Record<string, unknown>),
      };
    }

    return {
      ...result,
      [key]: value,
    };
  }, {});
};

export const getObjectValue = <R, T extends Record<string, unknown>>(
  key: string,
  obj: T,
): R => {
  return obj[key] as R;
};

export const getDefinedObjectValue = <T>(
  key: string,
  value?: T,
): Partial<Record<string, T>> => {
  if (value === undefined) {
    return {};
  }

  return {
    [key]: value,
  };
};
