import { IconButton } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(IconButton)`
  display: grid;
  transform: scale(0.8);
`;
