import * as objectPath from 'object-path-immutable';

import { InstrumentData, InstrumentFormState } from '../../../../useForm/types';
import { OverrideEntity } from '../../types';

const getOverrideEntities = <T extends { order?: number }>(
  path: string,
  getter: (id: string, providerType?: boolean) => string,
  state: InstrumentFormState,
) => {
  return [state.values, ...state.parents].reduce<OverrideEntity[]>(
    (result, source) => {
      const target = objectPath.get<
        InstrumentData,
        Record<string, T> | undefined
      >(source, path);

      if (!target) {
        return result;
      }

      const sortedKeys = Object.keys(target).sort((keyA, keyB) => {
        const valueA = target[keyA];
        const valueB = target[keyB];

        if (valueA.order !== undefined && valueB.order !== undefined) {
          return valueA.order - valueB.order;
        }

        return 1;
      });

      return sortedKeys.reduce<OverrideEntity[]>((res, id) => {
        const name = getter(id);
        const providerType = getter(id, true);
        const existIndex = res.findIndex((entity) => entity.id === id);
        if (existIndex > -1) {
          return res;
        }

        return [...res, { id, name, providerType }];
      }, result);
    },
    [],
  );
};

export default getOverrideEntities;
