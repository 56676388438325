export const addToObject = (
  obj: Record<string, unknown>,
  key: string,
  value: string,
  index: number,
) => {
  const temp: any = {};
  let i = 0;

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const prop in obj) {
    if (i === index && key) {
      temp[key] = value;
    }

    temp[prop] = obj[prop];

    i += 1;
  }

  return temp;
};
