import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-ui-kit-exante';

import { staticDataService } from '../../../../services/staticData.service';
import styles from '../../Mapping/Mapping.module.css';
import { IServerEvent } from '../types';

import { PanelStyled } from './Event.styled';
import { EventTable } from './EventTable/EventTable';
import { EventTabs } from './EventTabs/EventTabs';
import SkeletonEvent from './SkeletonEvent';

export const Event = () => {
  const [event, setEventData] = useState<IServerEvent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await staticDataService().getSingleEvent(eventId);
        if (data.error) {
          setEventData(null);
          return;
        }
        setEventData(data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [eventId]);

  const handleClose = () => navigate(-1);

  const actionControls = (
    <div className={styles.Actions}>
      <CloseButton size="l" onClick={handleClose} />
    </div>
  );

  return isLoading ? (
    <SkeletonEvent />
  ) : (
    <>
      <PanelStyled
        title={<EventTable event={event} />}
        action={actionControls}
      />
      {event && <EventTabs event={event} />}
    </>
  );
};
