import { Autocomplete as AutocompleteComponent } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { AutocompleteWrapper } from './styled';
import { SelectProps } from './types';
import useAutocomplete from './useAutocomplete';

const Autocomplete = ({ children, options, path }: SelectProps) => {
  const {
    isInherited,
    isResetButtonActive,
    inheritTitle,
    disabled,
    error,
    value,
    handleChange,
    handleReset,
  } = useAutocomplete(path);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritTitle}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <AutocompleteWrapper>
        <AutocompleteComponent
          isMultiple
          freeSolo={!options}
          disabled={disabled}
          options={options || []}
          placeholder={children as string}
          value={value || []}
          onChange={handleChange}
          controlProps={{
            error: Boolean(error),
            message: error,
          }}
        />
      </AutocompleteWrapper>
    </InputContainer>
  );
};

export default Autocomplete;
