import { IAceEditorProps } from 'react-ace';

export const DEFAULT_EDITOR_PARAMS: IAceEditorProps = {
  mode: 'html',
  theme: 'twilight',
  editorProps: { $blockScrolling: true },
  fontSize: 13,
  width: '100%',
  showPrintMargin: false,
  setOptions: { useWorker: false },
};
