import { ReactComponent as ChatIcon } from '../../../assets/i/Chat.svg';

import styles from './Placeholder.module.css';

const Placeholder = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Icon}>
        <ChatIcon />
      </div>
      <p className={styles.Message}>Select any Symbol Type on the left</p>
    </div>
  );
};

export default Placeholder;
