import { useRef, useCallback, useState } from 'react';

export const useModal = ({ defaultOpen = false } = {}) => {
  const value = useRef();
  const [opened, setOpened] = useState(defaultOpen);

  const onClose = useCallback(() => {
    setOpened(false);
    value.current = undefined;
  }, []);

  const onOpen = useCallback((data) => {
    value.current = data;
    setOpened(true);
  }, []);

  return {
    opened,
    closed: !opened,
    data: value.current as unknown,
    onOpen,
    onClose,
  };
};
