import { useAsyncCallback } from 'react-async-hook';

import { symbolDBService } from '../../../services/symbolDB.service';
import { CurrencyHoliday, ErrorHandler } from '../types';

export const useRemoveCurrencyHolidays = () => {
  const state = useAsyncCallback(
    async (
      payload: CurrencyHoliday,
      onError: ErrorHandler,
      onRefresh: () => Promise<unknown>,
    ) => {
      try {
        await symbolDBService().removeCurrencyHoliday(payload);
        await onRefresh();
      } catch (err) {
        onError(err);
      }
    },
  );

  return {
    removeCurrencyHoliday: state.execute,
    loading: state.loading,
  };
};
