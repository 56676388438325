import React, { useMemo } from 'react';
import {
  IOption,
  SelectedListWithSearch,
  IUIISelectedListWithSearchProps,
  IconButton,
} from 'react-ui-kit-exante';

import { IFeedProvider } from '../../types';

import styles from './FeedsList.module.css';
import { FeedsListProps } from './types';
import { getListOptions } from './utils';

const FeedsList: React.FC<FeedsListProps> = ({
  list,
  selected,
  warned,
  onAddButtonClick,
  onOpenFeed,
  isLoading,
}) => {
  const options = useMemo<IOption[]>(() => getListOptions(list), [list]);

  const handleListItemClick = (values: Partial<IFeedProvider>, id: string) => {
    onOpenFeed(id);
  };

  const props = {
    onListItemClick: handleListItemClick,
    selected,
  } as IUIISelectedListWithSearchProps;

  return (
    <div className={styles.Container}>
      <div className={styles.Body}>
        <SelectedListWithSearch
          {...props}
          options={options}
          isLoading={isLoading}
          selectedListProps={{ warned, ...props }}
        />
      </div>
      <div className={styles.Footer}>
        <IconButton
          iconColor="action"
          iconName="add"
          iconSize={24}
          label="Add Feed"
          onClick={onAddButtonClick}
        />
      </div>
    </div>
  );
};

export default FeedsList;
