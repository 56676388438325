import { AxiosError } from 'axios';

import { InstrumentFormState } from '../types';

const getError = (
  error: AxiosError | 'DELETED',
): InstrumentFormState['fetchStatus']['error'] => {
  if (error === 'DELETED') {
    return 404;
  }

  if (error.response?.data?.description?.childId === 'bad uuid') {
    return 400;
  }

  return 500;
};

const onFetchError = (
  state: InstrumentFormState,
  error: AxiosError | 'DELETED',
): InstrumentFormState => {
  return {
    ...state,

    ready: true,

    fetchStatus: {
      error: getError(error),
      pending: false,
      succeed: false,
    },
  };
};

export default onFetchError;
