import { Loader } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.color.bg.primary};
  height: calc(100vh - 70px);
  color: ${({ theme }) => theme.color.typo.primary};
  overflow-y: auto;
`;

export const Header = styled.div`
  height: 96px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
  font-size: 32px;
  flex: 0 0 auto;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  flex: 1 1 auto;
`;

export const Content = styled.div`
  display: grid;
  height: calc(100vh - 24px);
  overflow-y: auto;
`;

export const Loading = styled(Loader)`
  max-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
