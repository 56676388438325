import { Route, Routes } from 'react-router-dom';

import { InstrumentFormProps } from '../../types';

import { Preview, Accounts, Brokers, Feeds, Gateways } from './components';
import { OverridesRoutes } from './constants';

const Overrides = (props: InstrumentFormProps) => {
  return (
    <Routes>
      <Route path={OverridesRoutes.Preview} element={<Preview {...props} />} />
      <Route
        path={`${OverridesRoutes.FeedsGateways}/*`}
        element={<Gateways {...props} />}
      />
      <Route
        path={`${OverridesRoutes.FeedsProvider}/*`}
        element={<Feeds {...props} />}
      />
      <Route
        path={`${OverridesRoutes.BrokersAccounts}/*`}
        element={<Accounts {...props} />}
      />
      <Route
        path={`${OverridesRoutes.BrokersProvider}/*`}
        element={<Brokers {...props} />}
      />
    </Routes>
  );
};

export default Overrides;
