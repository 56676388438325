import { isObjectEmpty } from '~/utils/object';

import { IDMaps, SourceValue, ValueConfig } from '../../types';

import getValueColor from './getValueColor';
import getValueTitle from './getValueTitle';
import getValueTooltip from './getValueTooltip';

const getSourceValue = (
  { path, type }: ValueConfig,
  value: unknown,
  idMaps: IDMaps,
): SourceValue | undefined => {
  if (isObjectEmpty(value) || value === '') {
    return undefined;
  }

  const title = getValueTitle(path, type, value, idMaps);
  const tooltip = getValueTooltip(path);
  const color = getValueColor(value, type);

  return { color, path, title, tooltip };
};

export default getSourceValue;
