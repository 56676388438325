import { InstrumentData, InstrumentFormState } from '../types';

const onFetchSucceed = (
  state: InstrumentFormState,
  payload: InstrumentData[],
): InstrumentFormState => ({
  ...state,

  ready: true,

  fetchStatus: {
    error: null,
    pending: false,
    succeed: true,
  },

  parents: payload.slice(1),
  values: payload[0],
  ref: payload[0],
});

export default onFetchSucceed;
