import equal from 'fast-deep-equal';
import * as objectPath from 'object-path-immutable';

import { filterFromEmpty } from '~/utils/object';

import { FieldValue, InstrumentData, InstrumentFormState } from '../types';

const onSetFieldValue = (
  state: InstrumentFormState,
  path: string,
  value: FieldValue,
): InstrumentFormState => {
  const values = objectPath.set<InstrumentData>(state.values, path, value);

  return {
    ...state,
    dirty: !equal(filterFromEmpty(values), filterFromEmpty(state.ref)),
    values,
  };
};

export default onSetFieldValue;
