import { DatePicker } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { DatePickerWrapper } from './styled';
import { DateProps } from './types';
import useDate from './useDate';

const Date = ({ children, path, inputFormat, views }: DateProps) => {
  const {
    isInherited,
    isResetButtonActive,
    inheritTitle,
    disabled,
    error,
    value,
    handleChange,
    handleReset,
  } = useDate(path);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritTitle}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <DatePickerWrapper>
        <DatePicker
          controlProps={{ disabled }}
          disabled={disabled}
          label={children}
          value={value}
          onChange={handleChange}
          message={error}
          mask="dd/MM/yyyy"
          inputFormat={inputFormat}
          views={views}
        />
      </DatePickerWrapper>
    </InputContainer>
  );
};

export default Date;
