import { InstrumentFormState } from '../types';

const onConvertError = (state: InstrumentFormState): InstrumentFormState => ({
  ...state,

  convertStatus: {
    succeed: false,
    pending: false,
    error: true,
  },
});

export default onConvertError;
