import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.color.bg.primary};
  z-index: 1;

  & > div:first-of-type > div:first-of-type {
    width: 100%;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > div:first-of-type {
    width: 100%;
    display: flex;
    grid-template-columns: 1fr 1fr;
    height: 32px;
  }

  & > div:first-of-type button {
    width: 50%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.color.line.secondary};
  border-radius: 4px;
  gap: 24px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
`;

export const RefreshButton = styled.button`
  background: transparent;
  outline: 0;
  border: 0;
  display: flex;
  color: ${({ theme }) => theme.color.typo.secondary};
  align-items: center;
  gap: 6px;
  padding: 0;
  cursor: pointer;
  font-size: 14px;

  &:active {
    transform: translateY(1px);
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.color.typo.ghost};
  gap: 8px;

  & svg {
    color: #047cbf;
  }

  &:first-of-type svg {
    color: ${({ theme }) => theme.color.icon.action};
  }
`;
