import { SourceValue, ValueConfig } from '../../types';

const getValueColor = (
  value: unknown,
  type: ValueConfig['type'],
): SourceValue['color'] => {
  switch (type) {
    case 'boolean':
      return value ? 'green' : 'red';

    case 'executionSchemeID':
      return 'orange';

    default:
      return 'default';
  }
};

export default getValueColor;
