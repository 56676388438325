import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '../../../hooks/useFetchStatus';
import { symbolDBService } from '../../../services/symbolDB.service';
import { IBrokerProvider } from '../../Brokers/types';
import { IFeedProvider } from '../../Feeds/types';

const useExchangesDependencies = () => {
  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();

  const [brokers, setBrokers] = useState<Array<IBrokerProvider>>([]);
  const [feeds, setFeeds] = useState<Array<IFeedProvider>>([]);

  const fetchDependencies = async () => {
    fetchingStatusActions.handleStart();

    try {
      const [feedsResponse, brokersResponse] = await Promise.all([
        symbolDBService().getFeedProviders(),
        symbolDBService().getBrokerProviders(),
      ]);

      setFeeds(feedsResponse);
      setBrokers(brokersResponse);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  return {
    isDependenciesLoading: fetchingStatus.isPending,
    feeds,
    brokers,
  };
};

export default useExchangesDependencies;
