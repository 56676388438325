import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DatePicker, Input } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { Container, DatePickerWrapper } from './styled';
import { DateProps } from './types';
import useDateTime from './useDateTime';

const DateTime = ({ children, path }: DateProps) => {
  const {
    timeError,
    dateError,
    dateValue,
    timeValue,
    disabled,
    handleDateChange,
    handleTimeChange,
    handleDateReset,
    handleTimeReset,
    isDateInherited,
    isTimeInherited,
    inheritDateTitle,
    inheritTimeTitle,
    isResetDateButtonActive,
    isResetTimeButtonActive,
  } = useDateTime(path);

  return (
    <Container>
      <InputContainer disabled={disabled} inherited>
        {isDateInherited ? (
          <InheritButton
            value={inheritDateTitle}
            isActive={isResetDateButtonActive}
            onReset={handleDateReset}
          />
        ) : (
          <div />
        )}

        <DatePickerWrapper>
          <DatePicker
            controlProps={{
              disabled,
              message: dateError,
              error: Boolean(dateError),
            }}
            disabled={disabled}
            label={children}
            value={dateValue}
            onChange={handleDateChange}
            mask="dd/MM/yyyy"
          />
        </DatePickerWrapper>
      </InputContainer>
      <InputContainer disabled={disabled} inherited>
        {isTimeInherited ? (
          <InheritButton
            value={inheritTimeTitle}
            isActive={isResetTimeButtonActive}
            onReset={handleTimeReset}
          />
        ) : (
          <div />
        )}

        <Input
          placeholder="hh:mm:ss"
          value={timeValue}
          error={Boolean(timeError)}
          message={timeError}
          iconRight={<AccessTimeIcon />}
          onChange={(event) => handleTimeChange(event.target.value)}
        />
      </InputContainer>
    </Container>
  );
};

export default DateTime;
