import { IOption } from 'react-ui-kit-exante';

import { ExchangeItem } from '~/types/models';

export const getListOptions = (data: ExchangeItem[]): IOption[] => {
  const sortedData = [...data].sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  );

  return sortedData.map((item) => ({
    ...item,
    id: item._id,
    name: item.name,
  }));
};
