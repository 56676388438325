export const enum SchedulesRoutes {
  LIST = '',
  NEW = 'new',
  EDIT = ':id',
}

export const enum ScheduleSessionType {
  PREMARKET = 'PREMARKET',
  MAIN_SESSION = 'MAIN_SESSION',
  AFTERMARKET = 'AFTERMARKET',
  CLEARING = 'CLEARING',
}
