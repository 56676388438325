import { styled } from '@mui/material/styles';
import { TDefaultTheme } from 'react-ui-kit-exante';

export const StyledSpan = styled('span')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    color: theme?.color.typo?.action,
    cursor: 'pointer',
    marginRight: '6px',
    marginLeft: '6px',
  }),
);
