import { useAsyncCallback } from 'react-async-hook';

import { symbolDBService } from '../../../services/symbolDB.service';
import { CurrencyHoliday, ErrorHandler } from '../types';

export const useAddCurrencyHolidays = () => {
  const state = useAsyncCallback(
    async (
      payload: CurrencyHoliday,
      onError: ErrorHandler,
      onRefresh: () => Promise<unknown>,
      onSuccess?: () => void,
    ) => {
      try {
        await symbolDBService().addCurrencyHoliday(payload);
        await onRefresh();
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } catch (err) {
        onError(err);
      }
    },
  );

  return {
    addCurrencyHoliday: state.execute,
    loading: state.loading,
  };
};
