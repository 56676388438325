import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  BoolRow,
  BoolTable,
  Select,
  TextInput,
  Legs,
  Date,
} from '../components';
import { InstrumentFormProps } from '../types';

import { Container, Column, Title } from './styled';

const MinorSettings = ({ options }: InstrumentFormProps) => {
  const { current: marketEmulationModeOptions } = useRef<ISelectOption[]>([
    { value: 'percentage', label: 'Percentage' },
    { value: 'mpiOffset', label: 'MPI Offset' },
  ]);

  const { current: whenExceedingLimitsOptions } = useRef<ISelectOption[]>([
    { value: 'reject', label: 'Reject' },
    { value: 'pending', label: 'Pending' },
  ]);

  const { current: stopTriggerPolicyOptions } = useRef<ISelectOption[]>([
    { label: 'EXECUTION_SIDE', value: 'EXECUTION_SIDE' },
    { label: 'EXECUTION_SIDE_INVERSE', value: 'EXECUTION_SIDE_INVERSE' },
  ]);

  return (
    <Container columns={2}>
      <Column>
        <BoolTable>
          <BoolRow path="useHistoricalQuotes">Use historical quotes</BoolRow>
          <BoolRow path="tradeDataAvailable">Trade data available</BoolRow>
          <BoolRow path="physicalDelivery">Physical delivery</BoolRow>
          <BoolRow path="showAsFund">Show as fund</BoolRow>
          <BoolRow path="settlePnLOnExpiryDate">
            Settle PnL on expiry date
          </BoolRow>
          <BoolRow path="showQuoteAsFx">Show Quote as FX</BoolRow>
          <BoolRow path="popular">Popular</BoolRow>
          <BoolRow path="isLiquid">Is Liquid</BoolRow>
          <BoolRow path="hasNegativePrice">Has negative price</BoolRow>
          <BoolRow path="sendFixOriginatorTags">
            Send fix originator tags
          </BoolRow>
          <BoolRow path="skipClosePrice">Skip close price</BoolRow>
          <BoolRow path="showTradesChartByDefault">
            Show trades chart by default
          </BoolRow>
          <BoolRow path="invertedPnL">Inverted PnL</BoolRow>
          <BoolRow path="isRobotTradable">Is robot tradable</BoolRow>
          <BoolRow path="voiceTradingOnly">Voice trading only</BoolRow>
          <BoolRow path="testing">Testing</BoolRow>
          <BoolRow path="isSettlePNLOnExpiryDate">
            Settle PnL on expiry date
          </BoolRow>
        </BoolTable>

        <Title>Order Automation</Title>
        <TextInput path="orderAutomation.pendingPlaceDelay">
          Pending place delay
        </TextInput>

        <TextInput path="orderAutomation.fallbackTimeout">
          Fallback timeout
        </TextInput>

        <TextInput path="orderAutomation.recurrentAttempts">
          Recurrent attempts
        </TextInput>

        <TextInput path="orderAutomation.cancelEmulatedDay">
          Cancel emulated day before end interval
        </TextInput>

        <TextInput path="orderAutomation.minPlaceInterval">
          Min place interval (in milliseconds)
        </TextInput>

        <TextInput path="orderAutomation.minChunkSize">
          Min chunk size
        </TextInput>

        <Select
          options={marketEmulationModeOptions}
          path="orderAutomation.marketEmulationMode"
        >
          Market emulation mode
        </Select>

        <Select
          options={whenExceedingLimitsOptions}
          path="orderAutomation.whenExceedingLimits"
        >
          When exceeding limits
        </Select>

        <Title>StampDuty</Title>
        <BoolTable>
          <BoolRow path="stampDuty.eligibleForLevyFee">
            Eligible For Levy Fee
          </BoolRow>
          <BoolRow path="stampDuty.eligibleForStampDuty">
            Eligible For Stamp Duty
          </BoolRow>
          <BoolRow path="stampDuty.overrideSincData">
            Override Sinc Data
          </BoolRow>
        </BoolTable>

        <Title>Instant Execution</Title>
        <Select
          path="instantExecution.volumeCurrency"
          options={options.currency}
        >
          Volume Currency
        </Select>
        <TextInput path="instantExecution.maxDayPriceLimit">
          Max Day Price Limit
        </TextInput>
        <TextInput path="instantExecution.maxPositionVolume">
          Max Position Volume
        </TextInput>
        <TextInput path="instantExecution.maxPossibleDayDeviation">
          Max Possible Day Deviation
        </TextInput>
        <TextInput path="instantExecution.minDayPriceLimit">
          Min Day Price Limit
        </TextInput>
        <TextInput path="instantExecution.orderPriceMaxDeviation">
          Order Price Max Deviation
        </TextInput>
        <TextInput path="instantExecution.quoteLifetime">
          Quote Lifetime
        </TextInput>
        <TextInput path="instantExecution.placementDelay.duration">
          Duration
        </TextInput>
        <TextInput path="instantExecution.placementDelay.maxDelay">
          Max delay
        </TextInput>
        <TextInput path="instantExecution.placementDelay.minDelay">
          Min delay
        </TextInput>

        <BoolTable>
          <BoolRow path="instantExecution.reportErrors">Report Errors</BoolRow>
          <BoolRow path="instantExecution.placementDelay.useBestQuote">
            Use Best Quote
          </BoolRow>
        </BoolTable>

        <Select path="stopTriggerPolicy" options={stopTriggerPolicyOptions}>
          Stop trigger policy
        </Select>

        <TextInput path="lastAvailableDev">Last Available Dev</TextInput>

        <TextInput path="lastTradingDev">Last Trading Dev</TextInput>

        <TextInput path="delayFeedDepth">Delay feed depth</TextInput>
      </Column>

      <Column>
        <Legs />

        <TextInput path="intradayCoefficient">Intraday coefficient</TextInput>

        <TextInput path="valueDateDelta">Value date delta</TextInput>

        <TextInput path="closeOutPeriod">Close out period</TextInput>

        <TextInput path="faceValue">Face value</TextInput>

        <TextInput path="maxCloseByMarketVolume">
          Max close by market volume
        </TextInput>

        <TextInput path="localDescription">Local description</TextInput>

        <TextInput path="realExchangeName">Real exchange name</TextInput>

        <TextInput path="marketDataGroup">Market Data Group</TextInput>

        <TextInput path="maxPriceDeviation">Max price deviation</TextInput>

        <TextInput path="maxMarketOrderValue">
          Max market order value (in EUR)
        </TextInput>

        <TextInput path="units">Units</TextInput>

        <TextInput path="searchWeight">Search Weight</TextInput>

        <Date path="defaultDate">Default Date</Date>

        <Title>Asset Informations</Title>
        <TextInput path="assetInformations.CFI">CFI</TextInput>

        <TextInput path="assetInformations.assetClass">Asset Class</TextInput>

        <TextInput path="assetInformations.assetSubClass">
          Asset Sub Class
        </TextInput>

        <Title>Aux Data Calc Settings</Title>
        <BoolTable>
          <BoolRow path="auxDataCalcSettings.changeCalculation">
            Change Calculation
          </BoolRow>

          <BoolRow path="auxDataCalcSettings.isPublic">Is Public</BoolRow>

          <BoolRow path="auxDataCalcSettings.volumesCalculation">
            Volumes Calculation
          </BoolRow>
        </BoolTable>

        <Title>Feed Status</Title>
        <TextInput path="feedStatus.description">Description</TextInput>
        <TextInput path="feedStatus.url">URL</TextInput>

        <Title>Gics Fields</Title>
        <TextInput path="gicsFields.industry">Industry</TextInput>
        <TextInput path="gicsFields.industryGroup">Industry Group</TextInput>
        <TextInput path="gicsFields.sector">Sector</TextInput>
        <TextInput path="gicsFields.subIndustry">Sub Industry</TextInput>

        <TextInput path="issuer">Issuer full name</TextInput>
      </Column>
    </Container>
  );
};

export default MinorSettings;
