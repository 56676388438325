import { ScheduleSessionType } from '../../constants';
import { RuleSchedule, ScheduleForm } from '../../types';

export const defaultRule = {
  description: 'New rule',
  mergeNext: false,
};

export const defaultValues: ScheduleForm = {
  name: '',
  timezone: 'UTC',
  description: '',
  rules: [],
};

export const defaultRuleSchedule: RuleSchedule = {
  feed: true,
  from: { time: '00:00:00', marker: 'yesterday' },
  to: { time: '00:00:00', marker: 'yesterday' },
  type: ScheduleSessionType.MAIN_SESSION,
};
