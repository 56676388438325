import { IOption } from 'react-ui-kit-exante';

import { IBrokerProvider } from '../../types';

export const mapFeedProviderToOption = (item: IBrokerProvider): IOption => {
  return {
    ...item,
    // eslint-disable-next-line no-underscore-dangle
    id: item._id,
    name: item.name,
  };
};

export const getListOptions = (data: IBrokerProvider[]): IOption[] => {
  const sortedData = [...data].sort((a, b) => (a.name > b.name ? 1 : -1));

  return sortedData.map((item) => mapFeedProviderToOption(item));
};
