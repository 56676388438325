import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { CronScheduler, Notification } from 'react-ui-kit-exante';

import { useInterval } from '../../../hooks/useInterval';
import { staticDataService } from '../../../services/staticData.service';
import { SuccessMessages } from '../../Themes/constants';

import EventsTable from './EventsTable';
import LoaderProcess from './LoaderProcess';
import {
  ScheduleBlock,
  StyledSwitch,
  Content,
  Form,
  Forms,
} from './Main.styled';
import Setup from './Setup';
import {
  Options,
  MultiFieldProps,
  StringFieldProps,
  NumberFieldProps,
} from './Setup/Setup.types';
import { SkeletonMainContent } from './SkeletonMainPage';
import TitleWithActions from './TitleWithActions';
import { DefaultBondFilters, Statuses, pollingDelay } from './constants';
import { prepareBondFiltersData, createExtraObj } from './helpers';
import { useBeforeUnload } from './hooks/useBeforeUnload';
import { useMaps } from './hooks/useMaps';
import { useProviders } from './hooks/useProviders';
import { useTree } from './useTree';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryCodes = require('country-codes-list');

export const Main = () => {
  const [mapId, setMapId] = useState<StringFieldProps>({
    value: '',
    isDirty: false,
  });
  const [cronValue, setCronValue] = useState<string>('');
  const [providerId, setProviderId] = useState<StringFieldProps>({
    value: '',
    isDirty: false,
  });
  const [taskId, setTaskId] = useState<null | string>(null);
  const [eventId, setEventId] = useState<string>('');
  const [scheduleId, setScheduleId] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isChangedSchedule, setIsChangedSchedule] = useState<boolean>(false);
  const [isChangedSetupForm, setIsChangedSetupForm] = useState<boolean>(false);
  const [dataStep, setDataStep] = useState<any>('');
  const [isShowActionPrepare, setIsShowActionPrepare] =
    useState<boolean>(false);
  const [isCheckedDryRunPrepare, setIsCheckedDryRunPrepare] =
    useState<boolean>(true);
  const [isShowActionSync, setIsShowActionSync] = useState<boolean>(false);
  const [isCheckedDryRunSync, setIsCheckedDryRunSync] = useState<boolean>(true);
  const [isRunStep, setIsRunStep] = useState<boolean>(false);
  const [isEventsUpdate, setIsEventsUpdate] = useState<boolean | null>(null);
  const [isLoadingAllData, setIsLoadingAllData] = useState<boolean>(false);
  const [isShowBondFilters, setIsShowBondFilters] = useState<boolean>(false);
  const [issueAmount, setIssueAmount] = useState<NumberFieldProps>({
    value: 0,
    isDirty: false,
  });
  const [currency, setCurrency] = useState<MultiFieldProps>(
    DefaultBondFilters.currency,
  );
  const [clearAgent, setClearAgent] = useState<MultiFieldProps>(
    DefaultBondFilters.clearAgent,
  );
  const [issuerType, setIssuerType] = useState<MultiFieldProps>({
    value: [],
    isDirty: false,
  });
  const [countryRisk, setCountryRisk] = useState<MultiFieldProps>(
    DefaultBondFilters.countryRisk,
  );
  const [maturityType, setMaturityType] = useState<MultiFieldProps>(
    DefaultBondFilters.maturityType,
  );
  const [isDirtyPage, setIsDirtyPage] = useState(false);

  const { data: dataMap, isLoading: isLoadingMaps } = useMaps(providerId.value);
  const { data: dataProvider, isLoading: isLoadingProvider } = useProviders();

  const { instrument } = useTree();

  const folderIdParam = instrument.id;
  const symbolTypeParam = instrument.type;
  const exchangeIdParam = instrument.exchangeId;

  const countryCodeOptions = countryCodes
    .all()
    .map((item: { countryCode: string; countryNameEn: string }) => {
      const { countryCode, countryNameEn } = item;
      return { value: countryCode, label: countryNameEn };
    });

  const resetDirtyPage = () => {
    setIsDirtyPage(false);

    setMapId({ ...mapId, isDirty: false });
    setProviderId({ ...providerId, isDirty: false });

    setIssueAmount({ ...issueAmount, isDirty: false });
    setCurrency({ ...currency, isDirty: false });
    setClearAgent({ ...clearAgent, isDirty: false });
    setIssuerType({ ...issuerType, isDirty: false });
    setCountryRisk({ ...countryRisk, isDirty: false });
    setMaturityType({ ...maturityType, isDirty: false });
  };

  const getAllData = useCallback(async () => {
    setMapId({ value: '', isDirty: false });
    setTaskId('');
    setEventId('');
    setDataStep('');
    setCronValue('');
    setProviderId({ value: '', isDirty: false });
    setScheduleId('');
    setIsChecked(false);
    setIsShowActionSync(false);
    setIsShowBondFilters(false);
    setIsCheckedDryRunSync(true);
    setIsShowActionPrepare(false);
    setIsCheckedDryRunPrepare(true);
    setCurrency(DefaultBondFilters.currency);
    setIssuerType(DefaultBondFilters.issuerType);
    setClearAgent(DefaultBondFilters.clearAgent);
    setIssueAmount(DefaultBondFilters.issueAmount);
    setCountryRisk({
      value: [],
      isDirty: false,
    });
    setMaturityType(DefaultBondFilters.maturityType);

    setIsLoadingAllData(true);

    try {
      const { tasks } = await staticDataService().getTaskId(folderIdParam);
      const resTaskId = tasks?.[0]?.taskId;

      if (resTaskId) {
        const { extra, providerId: resProviderId } =
          await staticDataService().getTask(resTaskId);

        const { schedules } = await staticDataService().getSchedulesId(
          resTaskId,
        );
        const schedulesObj = schedules?.[0];
        const schedule = schedulesObj?.schedule || {};
        const {
          minute,
          hour,
          day_of_week: dayOfWeek,
          day_of_month: dayOfMonth,
          month_of_year: monthOfYear,
        } = schedule;
        const scheduleStr = !isEmpty(schedule)
          ? `${minute} ${hour} ${dayOfWeek} ${dayOfMonth} ${monthOfYear}`
          : '';
        const bondFilters = extra?.bondFilters;

        setTaskId(resTaskId);
        setProviderId({ value: resProviderId, isDirty: false });
        setMapId({ value: extra?.mapId || '', isDirty: false });
        setIsChecked(schedulesObj?.enabled);
        setScheduleId(schedulesObj?.scheduleId);
        setCurrency({
          value: prepareBondFiltersData(bondFilters?.currency),
          isDirty: false,
        });

        setIssuerType({
          value: prepareBondFiltersData(bondFilters?.issuerType),
          isDirty: false,
        });
        setMaturityType({
          value: prepareBondFiltersData(bondFilters?.maturityType),
          isDirty: false,
        });
        setCountryRisk({
          value:
            countryCodeOptions.filter((item: { value: Options }) =>
              bondFilters?.countryRisk.includes(item.value),
            ) || [],
          isDirty: false,
        });
        setIssueAmount({
          value: bondFilters?.issueAmount || 0,
          isDirty: false,
        });
        setClearAgent({
          value: prepareBondFiltersData(bondFilters?.clearAgent),
          isDirty: false,
        });

        setCronValue(scheduleStr === '* * * * *' ? '' : scheduleStr);
      }

      setIsLoadingAllData(false);
    } catch (error: any) {
      setIsLoadingAllData(false);
      Notification.error({
        title: `GetAllData ${error?.message}`,
      });
    }
  }, [folderIdParam]);

  const isRun =
    dataStep?.status === Statuses.SUCCESS ||
    dataStep?.status === Statuses.FAILURE ||
    dataStep?.sync?.state === Statuses.SUCCESS ||
    dataStep?.sync?.state === Statuses.FAILURE ||
    dataStep?.update?.state === Statuses.FAILURE ||
    dataStep?.prepare?.state === Statuses.FAILURE;

  const getDataStep = useCallback(async () => {
    try {
      if (eventId) {
        const isAll = isRunStep ? 'false' : 'true';
        const { status, result } = await staticDataService().getRunStep(
          eventId,
          isAll,
        );
        const statusStep = result?.status;
        const statusAllStepSync = result?.sync?.state;
        const statusAllStepUpdate = result?.update?.state;
        const statusAllStepPrepare = result?.prepare?.state;

        if (
          statusStep === Statuses.SUCCESS ||
          statusStep === Statuses.FAILURE ||
          statusAllStepSync === Statuses.SUCCESS ||
          statusAllStepSync === Statuses.FAILURE ||
          statusAllStepUpdate === Statuses.FAILURE ||
          statusAllStepPrepare === Statuses.FAILURE
        ) {
          setIsEventsUpdate(!isEventsUpdate);
        }

        setDataStep(status || result);
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, [eventId, isRunStep]);

  const createTask = useCallback(async () => {
    try {
      const payload = {
        providerId: providerId.value,
        extra: createExtraObj({
          mapId: mapId.value,
          issueAmount: issueAmount.value,
          currency: currency.value,
          issuerType: issuerType.value,
          clearAgent: clearAgent.value,
          countryRisk: countryRisk.value,
          maturityType: maturityType.value,
        }),
        symbolType: symbolTypeParam,
        folderId: folderIdParam,
        exchangeId: exchangeIdParam,
      };

      if (countryRisk.value.length === 0 && symbolTypeParam === 'BOND') {
        throw new Error(
          'Please fill Country of risk in Filters or in SymbolDB for this folder',
        );
      }

      const response = await staticDataService().createTask(payload);
      setTaskId(response?.taskId);

      if (response) {
        resetDirtyPage();
        Notification.success({ title: `${SuccessMessages.Create} task` });
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, [
    mapId,
    providerId,
    currency,
    issuerType,
    maturityType,
    issueAmount,
    countryRisk,
    clearAgent,
  ]);

  const changedTask = useCallback(async () => {
    try {
      const payload = {
        providerId: providerId.value,
        extra: createExtraObj({
          mapId: mapId.value,
          issueAmount: issueAmount.value,
          currency: currency.value,
          issuerType: issuerType.value,
          clearAgent: clearAgent.value,
          countryRisk: countryRisk.value,
          maturityType: maturityType.value,
        }),
        symbolType: symbolTypeParam,
        folderId: folderIdParam,
        exchangeId: exchangeIdParam,
      };

      if (countryRisk.value.length === 0 && symbolTypeParam === 'BOND') {
        throw new Error(
          'Please fill Country of risk in Filters or in SymbolDB for this folder',
        );
      }

      const response = await staticDataService().changedTask(payload, taskId);
      setIsChangedSetupForm(false);

      if (response) {
        resetDirtyPage();
        Notification.success({ title: `${SuccessMessages.Update} task` });
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, [
    mapId,
    providerId,
    currency,
    issuerType,
    maturityType,
    issueAmount,
    countryRisk,
    clearAgent,
  ]);

  const createSchedule = useCallback(async () => {
    try {
      const payload = {
        taskId,
        string: true,
        action: 'all',
        dry_run: false,
        enabled: isChecked,
        schedule: cronValue,
      };

      const response = await staticDataService().createSchedule(payload);
      setScheduleId(response?.scheduleId);

      if (response) {
        setIsDirtyPage(false);
        Notification.success({ title: `${SuccessMessages.Create} schedule` });
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, [taskId, isChecked, cronValue]);

  const changedSchedule = useCallback(async () => {
    try {
      const payload = {
        taskId,
        string: true,
        action: 'all',
        dry_run: false,
        enabled: isChecked,
        schedule: cronValue,
      };

      const response = await staticDataService().changeSchedule(
        payload,
        scheduleId,
      );

      setIsChangedSchedule(false);

      if (response) {
        setIsDirtyPage(false);
        Notification.success({ title: `${SuccessMessages.Update} schedule` });
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, [taskId, isChecked, cronValue, scheduleId]);

  const runAll = useCallback(
    async (isDryRun) => {
      try {
        const payload = {
          taskId,
          debug: false,
          dry_run: isDryRun,
        };

        const response = await staticDataService().runAll(payload);

        setIsRunStep(false);
        setEventId(response?.eventId);
      } catch (error: any) {
        Notification.error(error?.message);
      }
    },
    [taskId],
  );

  const runStep = useCallback(
    async (action) => {
      try {
        const payload = {
          taskId,
          action,
          debug: false,
          dry_run:
            action === 'prepare' ? isCheckedDryRunPrepare : isCheckedDryRunSync,
        };

        const response = await staticDataService().runStep(payload);

        setIsRunStep(true);
        if (response) {
          setEventId(response?.eventId);
          Notification.success({ title: `${SuccessMessages.Create} step` });
        }
      } catch (error: any) {
        Notification.error(error?.message);
      }
    },
    [taskId, isCheckedDryRunPrepare, isCheckedDryRunSync],
  );

  useEffect(() => {
    if (folderIdParam && exchangeIdParam) {
      getAllData();
    }
  }, [folderIdParam, exchangeIdParam]);

  useEffect(() => {
    getDataStep();
  }, [eventId]);

  useInterval(() => {
    if (!isRun) {
      getDataStep();
    }
  }, pollingDelay);

  useBeforeUnload(isDirtyPage);

  const disabledRun = () => {
    if (!exchangeIdParam) {
      return true;
    }
    if (!taskId) {
      return true;
    }
    if (isRun) {
      return false;
    }
    if (dataStep) {
      return true;
    }
    return false;
  };

  const onSaveMethod = () => {
    if (taskId && isChangedSetupForm) {
      return changedTask;
    }

    if (scheduleId) {
      return changedSchedule;
    }

    if (taskId) {
      return createSchedule;
    }

    return createTask;
  };

  const handleScheduleSwitch = useCallback(() => {
    setIsDirtyPage(true);
    setIsChecked(!isChecked);
    setIsChangedSchedule(!!scheduleId);
  }, [isChecked, scheduleId]);

  const handleSchedule = useCallback((value) => {
    setCronValue(value);
  }, []);

  if (isLoadingAllData) {
    return <SkeletonMainContent instrument={instrument} />;
  }

  return (
    <Forms>
      <TitleWithActions
        runAll={runAll}
        taskId={taskId}
        eventId={eventId}
        cronValue={cronValue}
        scheduleId={scheduleId}
        onSave={onSaveMethod()}
        disabledRun={disabledRun}
        providerId={providerId.value}
        isChangedSchedule={isChangedSchedule}
        isChangedSetupForm={isChangedSetupForm}
        instrument={instrument}
      />
      <Content>
        {exchangeIdParam && (
          <>
            <Form>
              <Setup
                mapId={mapId}
                taskId={taskId}
                dataMap={dataMap}
                setMapId={setMapId}
                providerId={providerId}
                scheduleId={scheduleId}
                dataProvider={dataProvider}
                setProviderId={setProviderId}
                isLoadingMaps={isLoadingMaps}
                isLoadingProvider={isLoadingProvider}
                isShowBondFilters={isShowBondFilters}
                setIsShowBondFilters={setIsShowBondFilters}
                setIsChangedSetupForm={setIsChangedSetupForm}
                setCurrency={setCurrency}
                currency={currency}
                setIssuerType={setIssuerType}
                issuerType={issuerType}
                setMaturityType={setMaturityType}
                maturityType={maturityType}
                setIssueAmount={setIssueAmount}
                issueAmount={issueAmount}
                setCountryRisk={setCountryRisk}
                countryRisk={countryRisk}
                setClearAgent={setClearAgent}
                clearAgent={clearAgent}
                countryCodeOptions={countryCodeOptions}
                setIsDirtyPage={setIsDirtyPage}
                instrument={instrument}
              />
              <ScheduleBlock>
                <h2>Schedule</h2>
                <StyledSwitch
                  label="Enabled"
                  disabled={!taskId}
                  checked={isChecked}
                  onChange={handleScheduleSwitch}
                />
                <span
                  role="presentation"
                  onClick={() => {
                    setIsDirtyPage(true);
                    setIsChangedSchedule(!!scheduleId);
                  }}
                >
                  <CronScheduler
                    value={cronValue}
                    disabled={!taskId}
                    clearButton={false}
                    displayError={false}
                    setValue={handleSchedule}
                  />
                </span>
              </ScheduleBlock>
              <LoaderProcess
                taskId={taskId}
                onRun={runStep}
                dataStep={dataStep}
                disabledRun={disabledRun}
                isShowActionSync={isShowActionSync}
                isCheckedDryRunSync={isCheckedDryRunSync}
                setIsShowActionSync={setIsShowActionSync}
                isShowActionPrepare={isShowActionPrepare}
                isCheckedDryRunPrepare={isCheckedDryRunPrepare}
                setIsCheckedDryRunSync={setIsCheckedDryRunSync}
                setIsShowActionPrepare={setIsShowActionPrepare}
                setIsCheckedDryRunPrepare={setIsCheckedDryRunPrepare}
              />
            </Form>
            <EventsTable isUpdate={isEventsUpdate} taskId={taskId || ''} />
          </>
        )}
      </Content>
    </Forms>
  );
};
