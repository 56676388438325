import { MouseEventHandler } from 'react';

import ButtonsStyles from './Buttons.module.css';

export const AddButton = ({
  onClick,
  text,
  dataTestId,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  text: string;
  dataTestId?: string;
}) => (
  <span
    data-test-id={dataTestId}
    className={ButtonsStyles.AddButton}
    role="button"
    tabIndex={0}
    onClick={onClick}
  >
    {text}
  </span>
);
