import styled from 'styled-components';

type ContainerProps = {
  columns?: number;
};

export const getContainerTemplateRows = (length?: number): string => {
  if (!length) {
    return '1fr';
  }

  return Array.from({ length }, () => '1fr').join(' ');
};

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => getContainerTemplateRows(props.columns)};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 64px 24px;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.line.secondary};
  }
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.typo.primary};

  &:not(:first-child) {
    padding-top: 24px;
  }
`;
