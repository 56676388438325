import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { NAV } from '~/pages/routing';

import { InstrumentRouteParams } from '../../../../../../types';
import { InstrumentFormContext } from '../../../../useForm';
import { InstrumentFormActions } from '../../../../useForm/actions';
import { FormProps } from '../types';

const useGatewayForm = (stackValue: FormProps['stackValue'], id?: string) => {
  const navigate = useNavigate();
  const { id: instrumentId } = useParams<InstrumentRouteParams>();
  const { state, dispatch } = useContext(InstrumentFormContext);

  const { name, sortable } = useMemo(() => {
    const result = stackValue.find((item) => item.key === id);

    return { name: result?.label, sortable: result?.sortable };
  }, [id, stackValue]);

  const handleAppend = () => {
    dispatch({
      type: InstrumentFormActions.AppendOverride,
      payload: {
        key: id,
        path: 'feeds.gateways',
        data: {
          order: stackValue.length,
        },
      },
    });
  };

  const handleDelete = () => {
    dispatch({
      type: InstrumentFormActions.Remove,
      payload: `feeds.gateways.${id}`,
    });
  };

  const handleClose = () => {
    dispatch({
      type: InstrumentFormActions.DiscardChanges,
      payload: 'feeds.gateways',
    });

    navigate(`${NAV.INSTRUMENTS}/${instrumentId}/overrides`);
  };

  const handleSave = () => {
    navigate(`${NAV.INSTRUMENTS}/${instrumentId}/overrides`);
  };

  return {
    name,
    sortable,

    dirty: state.dirty,

    handleAppend,
    handleClose,
    handleDelete,
    handleSave,
  };
};

export default useGatewayForm;
