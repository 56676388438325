import { ChangeEventHandler, PropsWithChildren, useState } from 'react';
import { Button, Input } from 'react-ui-kit-exante';

import { Container, Header, Body, Footer } from './styled';
import { NamePanelProps } from './types';

const NamePanel = ({
  onCancel,
  onSubmit,
}: PropsWithChildren<NamePanelProps>) => {
  const [name, setName] = useState('');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    if (name !== '') {
      onSubmit(name);
    }
  };

  return (
    <Container>
      <Header>Enter new instrument name</Header>
      <Body>
        <Input
          fullWidth
          placeholder="Instrument Name"
          value={name}
          onChange={handleChange}
        />
      </Body>
      <Footer>
        <Button type="button" onClick={handleSubmit}>
          Submit
        </Button>
        <Button type="button" onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </Footer>
    </Container>
  );
};

export default NamePanel;
