import { FC, useMemo } from 'react';
import { Tooltip, Checkbox } from 'react-ui-kit-exante';

import { DurationType, DurationTypeValue } from '~/types/models';

import styles from './DurationTypes.module.css';
import { DurationTypesProps } from './types';
import { Schema } from './utils';

const initialValue = {
  MARKET: [],
  LIMIT: [],
  STOP: [],
  STOP_LIMIT: [],
  TWAP: [],
  ICEBERG: [],
  TRAILING_STOP: [],
};

const DurationTypes: FC<DurationTypesProps> = ({ value, title, onChange }) => {
  const parsedValue = useMemo<DurationTypeValue>(
    () => ({ ...initialValue, ...value }),
    [value],
  );

  const handleChange = (key: keyof DurationTypeValue, type: DurationType) => {
    const existIndex = parsedValue[key].findIndex((t) => t === type);

    const payload =
      existIndex === -1
        ? { [key]: [...parsedValue[key], type] }
        : {
            [key]: parsedValue[key].filter((t) => t !== type),
          };

    onChange({ ...parsedValue, ...payload });
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <span>{title}</span>
        <Tooltip placement="top" title="Day">
          <span>DAY</span>
        </Tooltip>
        <Tooltip placement="top" title="Good till cancel">
          <span>GTC</span>
        </Tooltip>
        <Tooltip placement="top" title="Good till time">
          <span>GTT</span>
        </Tooltip>
        <Tooltip placement="top" title="Immediate or cancel">
          <span>IOC</span>
        </Tooltip>
        <Tooltip placement="top" title="Fill or kill">
          <span>FOK</span>
        </Tooltip>
        <Tooltip placement="top" title="At the opening">
          <span>ATO</span>
        </Tooltip>
        <Tooltip placement="top" title="At the close">
          <span>ATC</span>
        </Tooltip>
      </div>
      <div className={styles.Table}>
        {Schema.map((row) => (
          <>
            <div className={styles.Row}>{row.title}</div>
            {row.cells.map((cell) => (
              <div className={styles.Cell} key={cell.id}>
                <Checkbox
                  id={cell.id}
                  className={styles.Checkbox}
                  checked={parsedValue[cell.key].includes(cell.type)}
                  onChange={() => handleChange(cell.key, cell.type)}
                />
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  );
};

export default DurationTypes;
