import qs from 'qs';

import { SDB_APIV1, PROD_API, TEST_API } from './constants';

export const paramsSerializer = (params: Record<string, unknown>) =>
  qs.stringify(params, {
    arrayFormat: 'comma',
    encode: true,
    skipNulls: true,
  });

export function isProd() {
  const isStage = window.location.host.includes('stage');
  const isLocalHost = window.location.host.includes('localhost');
  return !(isStage || isLocalHost);
}

export function getSymbolDBBaseUrl(apiVersion = SDB_APIV1) {
  const bridgedApiUrl = window.SYMBOLDB_UI_REACT?.api_urls?.SYMBOLDB_EDITOR;

  if (!bridgedApiUrl) {
    return `https://${
      isProd() ? PROD_API.SYMBOLDB_EDITOR : TEST_API.SYMBOLDB_EDITOR
    }${apiVersion}`;
  }

  return `https://${bridgedApiUrl}${apiVersion}`;
}

export function getStaticDataBaseUrl() {
  const bridgedApiUrl = window.SYMBOLDB_UI_REACT?.api_urls?.STATIC_DATA;

  if (!bridgedApiUrl) {
    return `https://${isProd() ? PROD_API.STATIC_DATA : TEST_API.STATIC_DATA}`;
  }

  return `https://${bridgedApiUrl}`;
}

export function getBOBaseUrl() {
  const bridgedApiUrl = window.SYMBOLDB_UI_REACT?.api_urls?.BO;

  if (!bridgedApiUrl) {
    return `https://${isProd() ? PROD_API.BO : TEST_API.BO}`;
  }

  return `https://${bridgedApiUrl}`;
}
