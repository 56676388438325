import React from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input, RadioGroup } from 'react-ui-kit-exante';

import styles from './GatewayForm.module.css';
import { GatewayFormProps } from './types';

const GatewayForm: React.FC<GatewayFormProps> = ({ index, form, onDelete }) => {
  return (
    <div className={styles.Container}>
      <Controller
        key={`gateways.${index}.name`}
        name={`gateways.${index}.name`}
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Gateway Name *"
            sx={{ mt: '16px' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={
              fieldState.error?.message && 'Gateway name is required field'
            }
          />
        )}
      />

      <Controller
        key={`gateways.${index}.source`}
        name={`gateways.${index}.source`}
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Feed Source *"
            sx={{ mt: '16px' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={
              fieldState.error?.message && 'Feed source is required field'
            }
          />
        )}
      />

      <Controller
        key={`gateways.${index}.address`}
        name={`gateways.${index}.address`}
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Feed Address *"
            sx={{ mt: '16px' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={
              fieldState.error?.message && 'Feed Address is required field'
            }
          />
        )}
      />

      <Controller
        key={`gateways.${index}.environment`}
        name={`gateways.${index}.environment`}
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Environment (type ‘demo’ ‘prod’ ‘cprod’) *"
            sx={{ mt: '16px' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={
              fieldState.error?.message && 'Environment is required field'
            }
          />
        )}
      />

      <Controller
        key={`gateways.${index}.capacity`}
        name={`gateways.${index}.capacity`}
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Capacity"
            sx={{ mt: '16px' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={fieldState.error?.message && 'Capacity should be integer'}
          />
        )}
      />

      <Controller
        key={`gateways.${index}.description`}
        name={`gateways.${index}.description`}
        control={form.control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Description"
            sx={{ mt: '16px' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <div className={styles.RadioGroup}>
        <Controller
          name={`gateways.${index}.delayed`}
          control={form.control}
          render={({ field }) => (
            <RadioGroup
              formLabel="Delayed feed"
              radioOptions={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' },
              ]}
              value={field.value}
              handleChange={field.onChange}
            />
          )}
        />
      </div>

      <IconButton
        iconColor="radical"
        iconName="delete"
        iconSize={20}
        onClick={() => onDelete(index)}
        style={{
          justifyContent: 'flex-start',
          marginRight: '5px',
          marginTop: '24px',
        }}
      />
    </div>
  );
};

export default GatewayForm;
