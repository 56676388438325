import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Notification,
  SelectedListWithSearch,
  PanelHeader,
  Panel,
  IUIISelectedListWithSearchProps,
  IconButton,
} from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';
import { SuccessMessages } from '../Themes/constants';

import { Placeholder } from './Placeholder';
import styles from './Reporting.module.css';
import { ReportingForm } from './ReportingForm/ReportingForm';
import { IReportingFormValues } from './types';

const initialValue = {
  providerType: '',
  name: '',
  isImported: true,
};

export const Reporting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState<Array<IReportingFormValues>>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [isNewProvider, setIsNewProvider] = useState(false);

  const [isShowProvider, setIsShowProvider] = useState(false);
  const [provider, setProvider] = useState<
    IReportingFormValues | typeof initialValue
  >(initialValue);

  const getSingleReportingProvider = useCallback(async (id: string) => {
    try {
      const response = await symbolDBService().getSingleReportingProvider(id);
      setProvider(response);
    } catch (error: any) {
      Notification.error(error?.message);
    }
    setIsNewProvider(false);
  }, []);

  const openReportingForm = (values: IReportingFormValues, id: string) => {
    setIsShowProvider(true);
    getSingleReportingProvider(id);
    setSelected(id);
  };

  const closeReportingForm = () => {
    setIsShowProvider(false);
    setProvider(initialValue);
    setSelected(null);
  };

  const getProviders = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await symbolDBService().getReportingProviders();
      setProviders(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteProvider = useCallback(async (id?: string) => {
    try {
      const response = await symbolDBService().deleteReportingProvider(id);
      if (response) {
        Notification.success({ title: SuccessMessages.Delete });
        setIsShowProvider(false);
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, []);

  const createNewProvider = useCallback(() => {
    setProvider(initialValue);
    setIsNewProvider(true);
    setIsShowProvider(true);
  }, []);

  useEffect(() => {
    getProviders();
  }, [getProviders]);

  const options = useMemo(() => {
    return providers.map((item: IReportingFormValues) => ({
      ...item,
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
    })) as IUIISelectedListWithSearchProps['options'];
  }, [providers]);

  const onDelete = useCallback(
    async (id?: string) => {
      await deleteProvider(id);
      await getProviders();
    },
    [deleteProvider, getProviders],
  );

  return (
    <div className={styles.Wrapper}>
      <PanelHeader className={styles.Head} title="Reporting" />
      <div className={styles.Content}>
        <div className={styles.SelectedListBlock}>
          <Panel className={styles.Panel}>
            <SelectedListWithSearch
              onListItemClick={openReportingForm}
              options={options}
              selected={selected}
              isLoading={isLoading}
            />
          </Panel>
          <div className={styles.AddBlock}>
            <IconButton
              iconColor="action"
              iconName="add"
              iconSize={24}
              label="Add Reporting"
              onClick={createNewProvider}
            />
          </div>
        </div>
        {isShowProvider && provider !== null ? (
          <ReportingForm
            onClose={closeReportingForm}
            onDelete={onDelete}
            provider={provider}
            isNewProvider={isNewProvider}
            onUpdateProviders={getProviders}
          />
        ) : (
          <Placeholder create={createNewProvider} />
        )}
      </div>
    </div>
  );
};
