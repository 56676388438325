import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Notification,
  SelectedListWithSearch,
  PanelHeader,
  Panel,
  IUIISelectedListWithSearchProps,
} from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';

import { Placeholder } from './Placeholder';
import { SymbolTypeForm } from './SymbolTypeForm/SymbolTypeForm';
import styles from './SymbolTypes.module.css';
import { ISymbolTypeFormValues } from './types';

const initialValue = {
  name: '',
  displayName: '',
  expiredTtl: 0,
};

export const SymbolTypes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [symbolTypes, setSymbolTypes] = useState<Array<ISymbolTypeFormValues>>(
    [],
  );
  const [selected, setSelected] = useState<string | null>(null);

  const [isShowSymbolType, setIsShowSymbolType] = useState(false);
  const [symbolType, setSymbolType] = useState<
    ISymbolTypeFormValues | typeof initialValue
  >(initialValue);

  const openSymbolTypeForm = (values: ISymbolTypeFormValues, id: string) => {
    setIsShowSymbolType(true);
    // eslint-disable-next-line no-underscore-dangle
    const symbolTypeItem = symbolTypes.find((item) => item._id === id);
    if (symbolTypeItem) {
      setSymbolType(symbolTypeItem);
    }
    setSelected(id);
  };

  const closeSymbolTypeForm = () => {
    setIsShowSymbolType(false);
    setSymbolType(initialValue);
    setSelected(null);
  };

  const getSymbolTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await symbolDBService().getSymbolTypes();
      setSymbolTypes(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getSymbolTypes();
  }, [getSymbolTypes]);

  const options = useMemo(() => {
    return symbolTypes.map((item: ISymbolTypeFormValues) => ({
      ...item,
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
      name: item.displayName,
    })) as IUIISelectedListWithSearchProps['options'];
  }, [symbolTypes]);

  return (
    <div className={styles.Wrapper}>
      <PanelHeader title="Symbol types" className={styles.Head} />
      <div className={styles.Content}>
        <div className={styles.SelectedListBlock}>
          <Panel className={styles.Panel}>
            <SelectedListWithSearch
              options={options}
              selected={selected}
              isLoading={isLoading}
              onListItemClick={openSymbolTypeForm}
            />
          </Panel>
        </div>
        {isShowSymbolType && symbolType ? (
          <SymbolTypeForm
            onClose={closeSymbolTypeForm}
            symbolType={symbolType}
            onUpdateSymbolType={getSymbolTypes}
          />
        ) : (
          <Placeholder />
        )}
      </div>
    </div>
  );
};
