import * as objectPath from 'object-path-immutable';

import { InstrumentFeedsProvider } from '~/types/models';

import { InstrumentFormProps } from '../../../types';
import { InstrumentData, InstrumentFormState } from '../../../useForm';
import FEEDS_PROVIDER_OVERRIDES from '../configs/feedsProviderOverrides';
import { OverrideNode } from '../types';

import { getIDMaps, getOverrideEntities, getOverrideNodes } from './helpers';

const ROOT_PATH = 'feeds.providerOverrides';

const getFeedsProvider = (
  state: InstrumentFormState,
  dependencies: InstrumentFormProps['dependencies'],
): OverrideNode[] => {
  const getter = (id: string, providerType = false): string => {
    const result = dependencies.feedProviders?.find(
      (feedProvider) => feedProvider._id === id,
    );

    if (providerType) {
      return result?.providerType || '';
    }

    return result?.name || 'Unknown Feed Provider';
  };

  const extractor = (
    source: InstrumentData,
    id: string,
  ): InstrumentFeedsProvider | undefined => {
    return objectPath.get<typeof source, InstrumentFeedsProvider | undefined>(
      source,
      `${ROOT_PATH}.${id}`,
    );
  };

  const entities = getOverrideEntities<InstrumentFeedsProvider>(
    ROOT_PATH,
    getter,
    state,
  );

  const rooter = (id: string): string | undefined => {
    const result = dependencies.feedProviders?.find(
      (feedProvider) => feedProvider._id === id,
    );

    return result?._id;
  };

  return getOverrideNodes<InstrumentFeedsProvider>(
    state,
    extractor,
    rooter,
    entities,
    FEEDS_PROVIDER_OVERRIDES,
    getIDMaps(dependencies),
  );
};

export default getFeedsProvider;
