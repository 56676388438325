import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Table } from 'react-ui-kit-exante';

import { ROUTES } from '~/pages/routing';

import { columns, DISPLAYED_COLUMN_KEYS } from './constants';
import { useMappings } from './useMappings';

export const Mapping = () => {
  const { isLoading, mappings } = useMappings();
  const navigate = useNavigate();

  const handleOpenDetailsModal = useCallback(
    ({ mapId }) => {
      navigate(`${ROUTES.MAPPING}/${encodeURIComponent(mapId)}`);
    },
    [navigate],
  );

  const handleOpenNewCA = useCallback(() => {
    navigate(ROUTES.NEW_MAPPING_CONFIG);
  }, [navigate]);

  const additionalActions = useMemo(
    () => [
      {
        component: (
          <IconButton
            iconName="add"
            iconColor="action"
            label="Add mapping"
            iconSize={24}
            onClick={handleOpenNewCA}
          />
        ),
      },
    ],
    [handleOpenNewCA],
  );

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={mappings || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      handleRowClick={handleOpenDetailsModal}
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      showTableInfo
      tableId="mappings"
      title="Mappings"
    />
  );
};
