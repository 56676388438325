import { styled } from '@mui/material/styles';
import { IconButton, Panel, TDefaultTheme } from 'react-ui-kit-exante';

export const Wrapper = styled(Panel)(({ theme }: TDefaultTheme) => ({
  borderBottom: `1px ${theme?.color?.dropdown?.border} solid`,
  div: {
    h2: {
      fontSize: '32px',
    },
  },
}));

export const Icons = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const IconButtonStyled = styled(IconButton)(() => ({
  fontSize: '17px',
  marginRight: '20px',
}));

export const Info = styled('p')(({ theme }: TDefaultTheme) => ({
  background: theme?.color?.notifications?.warning,
  width: 'fit-content',
  fontSize: '15px',
  maxWidth: '720px',
  padding: '20px',
  margin: '0',
  borderRadius: '4px',
  opacity: '0.6',
}));
