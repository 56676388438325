import {
  BoolRow,
  BoolTable,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const ZeusCalcData = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>Zeus calc data</SectionTitle>
      <BoolTable>
        <BoolRow
          path={`feeds.providerOverrides.${id}.zeusCalcData.fetchImpliedVolatility`}
        >
          Fetch implied volatility
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.zeusCalcData.fetchTheoPrice`}
        >
          Fetch theo price
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.zeusCalcData.fetchGreeks`}
        >
          Fetch greeks
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.zeusCalcData.fetchRiskFreeRate`}
        >
          Fetch risk free rate
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.zeusCalcData.fetchImpliedForwardPrice`}
        >
          Fetch implied forward price
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.zeusCalcData.fetchVolatilityIndex`}
        >
          Fetch volatility index
        </BoolRow>
      </BoolTable>
    </Section>
  );
};

export default ZeusCalcData;
