import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { PanelHeader, PanelGroup, IconButton } from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';

import { Currencies } from './Currencies/Currencies';
import { CurrencyHolidaysTable } from './CurrencyHolidayTable/CurrencyHolidaysTable';
import CurrencyHolidaysStyles from './CurrencyHolidays.module.css';
import { CurrencyHolidaysForm } from './CurrencyHolidaysForm/CurrencyHolidaysForm';
import { Placeholder } from './Placeholder';
import { useActiveCurrency } from './hooks/useActiveCurrency';
import { Currency, CurrencyHoliday } from './types';

export const CurrencyHolidays = () => {
  const [showForm, setShowForm] = useState(false);
  const { activeCurrency, onChangeActiveCurrency } = useActiveCurrency();
  const {
    execute: getCurrencyHolidays,
    loading,
    result = [],
  } = useAsyncCallback<CurrencyHoliday[]>((currency: Currency) =>
    symbolDBService().getCurrencyHolidays({
      currencies: currency,
    }),
  );

  const handleCloseForm = useCallback(() => setShowForm(false), []);
  const handleOpenForm = useCallback(() => setShowForm(true), []);

  useEffect(() => {
    if (activeCurrency) {
      getCurrencyHolidays(activeCurrency);
    }
  }, [activeCurrency, getCurrencyHolidays]);

  const handleChangeActiveCurrency = useCallback(
    async (currency: Currency) => {
      handleCloseForm();
      onChangeActiveCurrency(currency);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [onChangeActiveCurrency, handleCloseForm],
  );

  const handleCloseTable = useCallback(() => {
    onChangeActiveCurrency(null);
  }, [onChangeActiveCurrency]);

  const preparedData = useMemo(
    () =>
      result.map(({ currency, ...other }) => ({
        ...other,
        remove: {
          currency,
          ...other,
          refresh: getCurrencyHolidays,
        },
      })),
    [result, getCurrencyHolidays],
  );

  return (
    <div className={CurrencyHolidaysStyles.Wrapper}>
      <PanelHeader
        className={CurrencyHolidaysStyles.Head}
        title="Currency Holidays"
      >
        <IconButton
          data-test-id="currency-holidays__button--add"
          iconColor="action"
          iconName="add"
          iconSize={24}
          label="Add holiday"
          onClick={handleOpenForm}
        />
      </PanelHeader>
      <PanelGroup equalWidth className={CurrencyHolidaysStyles.PanelGroup}>
        <Currencies
          activeCurrency={activeCurrency}
          onChangeActiveCurrency={handleChangeActiveCurrency}
        />
        {showForm && (
          <CurrencyHolidaysForm
            open={showForm}
            onClose={handleCloseForm}
            refresh={getCurrencyHolidays}
            onChangeActiveCurrency={onChangeActiveCurrency}
          />
        )}
        {!showForm && activeCurrency && (
          <div className={CurrencyHolidaysStyles.TableWrapper}>
            <CurrencyHolidaysTable
              currency={activeCurrency}
              data={preparedData}
              onClose={handleCloseTable}
              loading={loading}
            />
          </div>
        )}
        {!showForm && !activeCurrency && (
          <Placeholder create={handleOpenForm} />
        )}
      </PanelGroup>
    </div>
  );
};
