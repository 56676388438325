import * as yup from 'yup';

export const schema = yup.object().shape({
  weight: yup
    .number()
    .integer()
    .moreThan(-1, 'weight must be greater than or equal to 0'),
  type: yup.string(),
  themePic: yup.string().required().url(),
  taglist: yup.array().required().min(1),
  name: yup.string().required(),
  description: yup.object().shape({
    key: yup.string().required(),
    default: yup.string().required(),
  }),
  title: yup.object().shape({
    key: yup.string().required(),
    default: yup.string().required(),
  }),
  subTitle: yup.object().shape({
    key: yup.string().required(),
    default: yup.string().required(),
  }),
  backgroundColour: yup.lazy((value) => {
    if (value !== '') {
      return yup
        .string()
        .matches(
          /^#([0-9a-f]{3}){1,2}$/i,
          'The color must be in valid hex format',
        );
    }
    return yup.string();
  }),
});
