import { GetInstrumentsParams, GetTreeParams, InstrumentItem } from '~/api';
import { UUID_LENGTH } from '~/constants';
import { getUnifiedTreeItemFromInstrument } from '~/pages/Instruments/components/Sidebar/transformers';
import { getUUIDKey } from '~/utils/uuid';

import { FilterState, UnifiedTreeItem } from './types';

export const getID = (pathname: string) => {
  const [, , , res] = pathname.split('/');

  if (res && res.length === UUID_LENGTH) {
    return res;
  }

  return '';
};

export const getEmptyUnifiedTreeItem = (
  props: Partial<UnifiedTreeItem>,
): UnifiedTreeItem => {
  return {
    abstract: false,
    expiry: false,
    id: getUUIDKey(),
    isLoading: false,
    isPlaceholder: false,
    name: '',
    nodes: [],
    trading: false,
    ...props,
  };
};

export const getBaseParams = (
  filter: FilterState,
): Partial<
  Pick<GetInstrumentsParams | GetTreeParams, 'isExpired' | 'isTrading'>
> => {
  return {
    ...(filter.expired && { isExpired: filter.expired }),
    ...(filter.trading && { isTrading: filter.trading }),
  };
};

export const insertTreeNodes = (
  pid: string,
  tree: UnifiedTreeItem[],
  nodes: UnifiedTreeItem[],
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, nodes, isLoading: false }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: insertTreeNodes(pid, node.nodes, nodes),
          isLoading: false,
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setNodeLoading = (
  pid: string,
  tree: UnifiedTreeItem[],
  isLoading: boolean,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, isLoading }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        { ...node, nodes: setNodeLoading(pid, node.nodes, isLoading) },
      ];
    }

    return [...result, node];
  }, []);
};

export const setFetchButtonLoading = (
  pid: string,
  tree: UnifiedTreeItem[],
  isFetchMoreButtonLoading: boolean,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, isFetchMoreButtonLoading }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: setFetchButtonLoading(
            pid,
            node.nodes,
            isFetchMoreButtonLoading,
          ),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setFetchButtonCount = (
  pid: string,
  tree: UnifiedTreeItem[],
  count: number,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, isFetchMoreButton: count }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: setFetchButtonCount(pid, node.nodes, count),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setNodePlaceholder = (
  pid: string,
  tree: UnifiedTreeItem[],
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [
        ...result,
        {
          ...node,
          isLoading: false,
          nodes: [
            getEmptyUnifiedTreeItem({ name: 'No Data', isPlaceholder: true }),
          ],
        },
      ];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        { ...node, nodes: setNodePlaceholder(pid, node.nodes) },
      ];
    }

    return [...result, node];
  }, []);
};

export const getLoadableNodes = (
  items: InstrumentItem[],
  total: number,
  count = 0,
  length?: number,
): UnifiedTreeItem[] => {
  const nodes = items.map((item) =>
    getUnifiedTreeItemFromInstrument(item.id, item.data),
  );

  const item = { name: 'Load More', isFetchMoreButton: count };

  if (length) {
    if (length < total) {
      nodes.push(getEmptyUnifiedTreeItem(item));
    }

    return nodes;
  }

  if (items.length < total) {
    nodes.push(getEmptyUnifiedTreeItem(item));
  }

  return nodes;
};

export const getParentId = (
  pid: string,
  tree: UnifiedTreeItem[],
): string | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of tree) {
    if (node.nodes.find((item) => item.id === pid)) {
      return node.id;
    }

    if (node.nodes.length > 0) {
      const id = getParentId(pid, node.nodes);

      if (id) {
        return id;
      }
    }
  }

  return undefined;
};

export const getPagedNodes = (
  pid: string,
  tree: UnifiedTreeItem[],
  items: InstrumentItem[],
  total: number,
  count: number,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === getParentId(pid, tree)) {
      const payload = node.nodes.slice(0, node.nodes.length - 1);

      return [
        ...result,
        {
          ...node,
          nodes: [
            ...payload,
            ...getLoadableNodes(
              items,
              total,
              count,
              payload.length + items.length,
            ),
          ],
        },
      ];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        { ...node, nodes: getPagedNodes(pid, node.nodes, items, total, count) },
      ];
    }

    return [...result, node];
  }, []);
};
