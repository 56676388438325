import { useMemo } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

import { NAV } from '~/pages/routing';

import { BrokersRoutes } from '../constants';

const useBrokersRouting = () => {
  const nav = useNavigate();
  const match = useMatch(`${NAV.BROKERS}/${BrokersRoutes.EDIT}`);

  const selected = useMemo(() => {
    if (!match?.params.id || match?.params.id === BrokersRoutes.NEW) {
      return null;
    }

    return match?.params.id;
  }, [match?.params.id]);

  const handleCloseBrokersForm = () => {
    // TODO: show leave confirmation modal
    nav(NAV.BROKERS, { replace: false });
  };

  const handleCreateBroker = () => {
    nav(`${NAV.BROKERS}/new`, { replace: false });
  };

  const handleOpenBroker = (id: string) => {
    nav(`${NAV.BROKERS}/${id}`, { replace: false });
  };

  return {
    selected,
    handleCloseBrokersForm,
    handleCreateBroker,
    handleOpenBroker,
  };
};

export default useBrokersRouting;
