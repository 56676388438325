import { InstrumentFormState } from '../types';

const onSaveError = (state: InstrumentFormState): InstrumentFormState => ({
  ...state,

  saveStatus: {
    succeed: false,
    pending: false,
    error: true,
  },
});

export default onSaveError;
