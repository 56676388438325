import { useCallback } from 'react';
import { Select, Autocomplete, Input } from 'react-ui-kit-exante';

import {
  defaultOption,
  selectWidth,
  IssuerTypes,
  MaturityTypes,
  CleatAgentsOptions,
  controlCls,
} from '../constants';
import { useCurrency } from '../hooks/useCurrency';

import { Wrapper, Selects, Div } from './Setup.styled';
import { SetupProps } from './Setup.types';

const Setup = ({
  mapId,
  taskId,
  dataMap,
  setMapId,
  providerId,
  dataProvider,
  setProviderId,
  isLoadingMaps,
  isLoadingProvider,
  isShowBondFilters,
  setIsShowBondFilters,
  setIsChangedSetupForm,
  setCurrency,
  currency,
  setIssuerType,
  issuerType,
  setMaturityType,
  maturityType,
  issueAmount,
  setIssueAmount,
  setCountryRisk,
  countryRisk,
  setClearAgent,
  clearAgent,
  countryCodeOptions,
  setIsDirtyPage,
  instrument,
}: SetupProps) => {
  const symbolTypeParam = instrument.type;
  const { data: optionsCurrency, isLoading: isLoadingCurrency } = useCurrency();
  const mapValue =
    !mapId.value && !isLoadingMaps && providerId.value
      ? 'default'
      : mapId.value;

  const onChangeProvider = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setMapId({ value: '', isDirty: false });
      setIsChangedSetupForm(!!taskId);
      setProviderId({ value, isDirty: true });
    },
    [taskId],
  );

  const onChangeMap = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setMapId({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  const onChangeCurrency = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setCurrency({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  const onChangeIssuerType = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setIssuerType({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  const onChangeMaturityType = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setMaturityType({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  const onChangeCountryRisk = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setCountryRisk({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  const onChangeIssueAmount = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setIssueAmount({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  const onChangeClearAgent = useCallback(
    (value) => {
      setIsDirtyPage(true);
      setClearAgent({ value, isDirty: true });
      setIsChangedSetupForm(!!taskId);
    },
    [taskId],
  );

  return (
    <Wrapper title="Setup">
      <div>
        {symbolTypeParam === 'BOND' && (
          <Div onClick={() => setIsShowBondFilters(!isShowBondFilters)}>
            Filters
          </Div>
        )}
        {isShowBondFilters && (
          <>
            <Input
              type="number"
              sx={controlCls}
              label="Issue Amount"
              value={issueAmount.value}
              warning={issueAmount.isDirty}
              style={{ minWidth: '384px' }}
              onChange={(e) => onChangeIssueAmount(e.target.value)}
            />
            <Autocomplete
              isMultiple
              value={currency.value}
              onChange={(_e, newValue) => onChangeCurrency(newValue)}
              options={optionsCurrency}
              controlProps={{
                disabled: isLoadingCurrency,
                placeholder: 'Currency',
                warning: currency.isDirty,
              }}
              sx={controlCls}
            />
            <Autocomplete
              isMultiple
              value={clearAgent.value}
              onChange={(_e, newValue) => onChangeClearAgent(newValue)}
              options={CleatAgentsOptions}
              controlProps={{
                placeholder: 'Clear Agent',
                warning: clearAgent.isDirty,
              }}
              sx={controlCls}
            />
            <Autocomplete
              isMultiple
              value={issuerType.value}
              onChange={(_e, newValue) => onChangeIssuerType(newValue)}
              options={IssuerTypes}
              controlProps={{
                placeholder: 'Issuer Type',
                warning: issuerType.isDirty,
              }}
              sx={controlCls}
            />
            <Autocomplete
              isMultiple
              value={countryRisk.value}
              onChange={(_e, newValue) => onChangeCountryRisk(newValue)}
              options={countryCodeOptions}
              controlProps={{
                placeholder: 'Country Risk',
                warning: countryRisk.isDirty,
              }}
              sx={controlCls}
            />
            <Autocomplete
              isMultiple
              value={maturityType.value}
              onChange={(_e, newValue) => onChangeMaturityType(newValue)}
              options={MaturityTypes}
              controlProps={{
                placeholder: 'Maturity Type',
                warning: maturityType.isDirty,
              }}
              sx={controlCls}
            />
          </>
        )}
        <Selects>
          <Select
            label="Provider"
            sx={selectWidth}
            options={dataProvider}
            placeholder="Provider"
            value={providerId.value}
            warning={providerId.isDirty}
            disabled={isLoadingProvider}
            onChange={(e) => onChangeProvider(e.target.value)}
          />
          <Select
            label="Mapping"
            value={mapValue}
            sx={selectWidth}
            placeholder="Mapping"
            warning={mapId.isDirty}
            disabled={!providerId.value || isLoadingMaps}
            options={[...dataMap, ...defaultOption]}
            onChange={(e) => onChangeMap(e.target.value)}
          />
        </Selects>
      </div>
    </Wrapper>
  );
};

export default Setup;
