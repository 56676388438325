import * as yup from 'yup';

const gatewaySchema = yup.object({
  name: yup.string().required(),
  source: yup.string().required(),
  address: yup.string().required(),
  environment: yup.string().required(),
  delayed: yup.boolean(),
  description: yup.string(),
  capacity: yup.number(),
});

const formSchema = yup
  .object({
    name: yup.string().required(),
    providerType: yup.string(),
    gateways: yup.array().of(gatewaySchema).required(),
  })
  .required();

export default formSchema;
