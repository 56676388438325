import { IColumn } from 'react-ui-kit-exante';

import { DEFAULT_COLUMN_WIDTH, CHECKBOX_COLUMN_WIDTH } from '../../constants';

import { ITheme } from './types';

export const COLUMNS: IColumn<ITheme>[] = [
  {
    Header: 'id',
    accessor: '_id',
    ...DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Name',
    accessor: 'name',
    ...DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Visible',
    accessor: 'visible',
    ...CHECKBOX_COLUMN_WIDTH,
  },
  {
    Header: 'Highlight',
    accessor: 'highlight',
    ...CHECKBOX_COLUMN_WIDTH,
  },
  {
    Header: 'Brandings',
    accessor: 'brandings',
    ...DEFAULT_COLUMN_WIDTH,
  },
];

export enum SuccessMessages {
  Create = 'Successfully created',
  Update = 'Successfully updated',
  Delete = 'Successfully deleted',
}

export const DISPLAYED_COLUMN_KEYS = [
  'brandings',
  'name',
  'visible',
  'highlight',
];

export const LOCALIZATION_PROPS = [
  'title.key',
  'subTitle.key',
  'description.key',
];
