import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { BoolRow, BoolTable, Select, TextInput } from '../components';
import { InstrumentFormProps } from '../types';

import { Column, Container } from './styled';

const BaseFields = ({ isCreating, options }: InstrumentFormProps) => {
  const { current: typeOptions } = useRef<ISelectOption[]>([
    { label: 'Bond', value: 'BOND' },
    { label: 'Stock', value: 'STOCK' },
    { label: 'Future', value: 'FUTURE' },
    { label: 'Fx Spot', value: 'FX_SPOT' },
    { label: 'Forex', value: 'FOREX' },
    { label: 'Option', value: 'OPTION' },
    { label: 'Calendar Spread', value: 'CALENDAR_SPREAD' },
    { label: 'Cfd', value: 'CFD' },
  ]);

  return (
    <Container columns={2}>
      <Column>
        {isCreating && <TextInput path="name">Name</TextInput>}

        <BoolTable>
          <BoolRow path="trading">Trading</BoolRow>
        </BoolTable>
        <TextInput path="ticker">Ticker</TextInput>
        <TextInput path="shortName">Short Name</TextInput>
        <TextInput path="description">Description</TextInput>
        <TextInput path="exchangeLink">Exchange Link</TextInput>
        <TextInput path="tickerIcon">Ticker Icon</TextInput>
        <TextInput path="comments">Comments</TextInput>
        <Select path="type" options={typeOptions}>
          Type
        </Select>
        <Select path="exchangeId" options={options.exchange}>
          Exchange
        </Select>
        <Select path="currency" options={options.currency}>
          Currency
        </Select>
        <Select path="settlementCurrency" options={options.currency}>
          Settlement currency
        </Select>
        <TextInput path="feedMinPriceIncrement">
          Feed minimum price increment
        </TextInput>

        <TextInput path="orderMinPriceIncrement">
          Order minimum price increment
        </TextInput>
      </Column>
      <Column>
        <Select path="scheduleId" options={options.schedule}>
          Schedule
        </Select>
        <Select path="sectionId" options={options.section}>
          Section
        </Select>
        <TextInput path="underlying">Underlying</TextInput>
        <TextInput path="leverageRate">Leverage rate</TextInput>

        <TextInput path="leverageRateShort">Leverage rate short</TextInput>

        <TextInput path="extremeLeverageRate">Extreme leverage rate</TextInput>

        <TextInput path="extremeLeverageRateShort">
          Extreme leverage rate short
        </TextInput>

        <TextInput path="contractMultiplier">Contract multiplier</TextInput>

        <TextInput path="initialMargin">Initial margin</TextInput>

        <TextInput path="maintenanceMargin">Maintenance margin</TextInput>
        <TextInput path="lotSize">Lot size</TextInput>

        <TextInput path="minLotSize">Min lot size</TextInput>
        <TextInput path="priceUnit">Price unit</TextInput>

        <TextInput path="couponRate">Coupon rate</TextInput>
        <TextInput path="minOrderQuantity">Min order quantity</TextInput>
      </Column>
    </Container>
  );
};

export default BaseFields;
