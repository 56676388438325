import React from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input, Switch } from 'react-ui-kit-exante';

import { Placeholder } from '../../../../../components/Placeholder';
import { SortableList } from '../../../../../components/SortableList';

import styles from './Gateways.module.css';
import { Accounts } from './components/Accounts';
import useGateways from './hooks/useGateways';
import { GatewaysProps } from './types';

const Gateways: React.FC<GatewaysProps> = ({ form }) => {
  const {
    handleAppend,
    handleDelete,
    list,
    selectedId,
    setSelectedId,
    index,
    control,
  } = useGateways(form);

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.Title}>Gateways</div>
        <div className={styles.Actions}>
          <IconButton
            iconColor="action"
            iconName="add"
            iconSize={24}
            label="Add Gateway"
            onClick={handleAppend}
          />
        </div>
      </div>

      {list.length === 0 && (
        <Placeholder title="The broker does not have gateways" />
      )}

      {list.length > 0 && (
        <div className={styles.Body}>
          <SortableList
            items={list}
            selectedId={selectedId}
            onSelect={setSelectedId}
          />

          <div className={styles.Form}>
            <div className={styles.Main}>
              <Controller
                key={`gateways.${index}.name`}
                name={`gateways.${index}.name`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Gateway name *"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.address`}
                name={`gateways.${index}.address`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Connection address *"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.environment`}
                name={`gateways.${index}.environment`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Environment *"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.capacity`}
                name={`gateways.${index}.capacity`}
                control={control}
                render={({ field }) => (
                  <Input
                    label="Capacity"
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.manualExecution`}
                name={`gateways.${index}.manualExecution`}
                control={control}
                render={({ field }) => (
                  <Switch
                    label="Manual execution"
                    checked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <IconButton
                iconColor="radical"
                iconName="delete"
                iconSize={20}
                onClick={() => handleDelete(index)}
                style={{ marginRight: '5px' }}
              />
            </div>

            <Accounts index={index} form={form} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gateways;
