import { AxiosError } from 'axios';

import { GenericAction, GenericReducer } from '~/types/shared';

export const enum FetchStatusAction {
  FETCH_START = 'FetchStatusAction > FETCH_START',
  FETCH_SUCCESS = 'FetchStatusAction > FETCH_SUCCESS',
  FETCH_ERROR = 'FetchStatusAction > FETCH_ERROR',
}

export type FetchStatusActionType =
  | GenericAction<FetchStatusAction.FETCH_START>
  | GenericAction<FetchStatusAction.FETCH_SUCCESS>
  | GenericAction<FetchStatusAction.FETCH_ERROR, AxiosError>;

export type FetchStatusState = {
  isSucceed: boolean;
  isPending: boolean;
  isError: boolean;
  error: AxiosError | undefined;
};

export type FetchStatusReducer = GenericReducer<
  FetchStatusState,
  FetchStatusActionType
>;

export type UseFetchStatusActions = {
  handleError: (error: AxiosError, options?: ErrorOptions) => void;
  handleStart: () => void;
  handleSuccess: () => void;
};

export type ErrorOptions = {
  quiet?: boolean;
};

export type UseFetchStatus = [FetchStatusState, UseFetchStatusActions];
