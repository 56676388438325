/* eslint-disable no-underscore-dangle */

import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '../../../hooks/useFetchStatus';
import { symbolDBService } from '../../../services/symbolDB.service';
import { IBrokerProvider } from '../types';

import useBrokersRouting from './useBrokersRouting';

const useBrokersList = () => {
  const [brokers, setBrokers] = useState<Array<IBrokerProvider>>([]);
  const { handleOpenBroker, handleCloseBrokersForm } = useBrokersRouting();

  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();

  const setBrokersOptions = async (): Promise<void> => {
    fetchingStatusActions.handleStart();

    try {
      const data = await symbolDBService().getBrokerProviders();

      setBrokers(data);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  const handleSuccessCreate = (payload: IBrokerProvider) => {
    setBrokers([payload, ...brokers]);
    handleOpenBroker(payload._id);
  };

  const handleSuccessUpdate = (payload: IBrokerProvider) => {
    const existIndex = brokers.findIndex(
      (broker) => broker._id === payload._id,
    );

    if (existIndex !== -1) {
      setBrokers([
        ...brokers.slice(0, existIndex),
        payload,
        ...brokers.slice(existIndex + 1),
      ]);
    }
  };

  const handleSuccessDelete = (id: string) => {
    const existIndex = brokers.findIndex((broker) => broker._id === id);

    if (existIndex !== -1) {
      handleCloseBrokersForm();
      setBrokers([
        ...brokers.slice(0, existIndex),
        ...brokers.slice(existIndex + 1),
      ]);
    }
  };

  useEffect(() => {
    setBrokersOptions();
  }, []);

  return {
    brokers,
    isLoading: fetchingStatus.isPending && !fetchingStatus.isSucceed,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  };
};

export default useBrokersList;
