import { ExecutionSchemaItem } from '~/pages/ExecutionSchemes/types';

import { InstrumentFormProps } from '../../../../types';
import { IDMaps } from '../../types';

const mapExecutionSchemes = (
  executionSchemes?: ExecutionSchemaItem[] | undefined,
) =>
  new Map((executionSchemes || []).map((scheme) => [scheme._id, scheme.name]));

const getIDMaps = (
  dependencies: InstrumentFormProps['dependencies'],
): IDMaps => ({
  executionSchemes: mapExecutionSchemes(dependencies.executionSchemes),
});

export default getIDMaps;
