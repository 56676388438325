import { styled } from '@mui/material/styles';
import { Panel, TDefaultTheme } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  height: '100%',
  width: '100%',
  display: 'flex',
}));

export const Tree = styled('div')(() => ({
  padding: '10px',
  border: '1px solid rgb(235, 235, 235)',
}));

export const Forms = styled('div')(() => ({
  width: '100%',
}));

export const Block = styled(Panel)(({ theme }: TDefaultTheme) => ({
  borderBottom: `1px ${theme?.color?.dropdown?.border} solid`,
  borderRight: `1px ${theme?.color?.dropdown?.border} solid`,
}));

export const Form = styled('div')(() => ({
  width: '45%',
}));

export const SetupSelects = styled('div')(() => ({
  display: 'flex',
  span: {
    margin: '-15px 15px -15px 0',
  },
}));

export const ScheduleSelects = styled('div')(({ theme }: TDefaultTheme) => ({
  display: 'flex',
  flexDirection: 'column',
  span: {
    marginRight: '8px',
    fontSize: '15px',
    fontFamily: ' Inter, sans-serif',
    color: theme?.color?.typo?.promo,
  },
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  span: {
    marginRight: '5px',
  },
}));

export const WrapperTable = styled('div')(() => ({
  width: '55%',
  maxHeight: '300px',
}));

export const ToggleSchedule = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '15px',
}));

export const Loader = styled(Panel)(({ theme }: TDefaultTheme) => ({
  borderBottom: `1px ${theme?.color?.dropdown?.border} solid`,
  borderRight: `1px ${theme?.color?.dropdown?.border} solid`,
  h2: {
    margin: '20px 0',
  },
}));

export const Indicator = styled('div')(({ theme }: TDefaultTheme) => ({
  width: '32%',
  height: '40px',
  textAlign: 'center',
  borderBottom: `solid ${theme?.color?.bg?.basic} 5px`,
  color: theme?.color?.typo?.promo,
}));

export const Indicators = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '45px 0 40px',
  fontSize: '13px',
}));
