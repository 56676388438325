import { BoolValue } from '~/pages/Instruments/components/InstrumentForm/components/BoolRow/types';

export const getValue = (value?: boolean): BoolValue => {
  if (value === undefined) {
    return 'unset';
  }

  return value ? 'yes' : 'no';
};

export const getPayload = (value: BoolValue): boolean | undefined => {
  if (value === 'unset') {
    return undefined;
  }

  return value === 'yes';
};

export const getInitialValue = (
  selfValue: BoolValue,
  inheritValue: BoolValue,
): BoolValue => {
  if (inheritValue !== 'unset') {
    return inheritValue;
  }

  if (selfValue !== 'unset') {
    return selfValue;
  }

  return 'unset';
};
