import { FilledInputProps } from '@mui/material/FilledInput';
import * as objectPath from 'object-path-immutable';
import { useContext, useState } from 'react';

import { getIterableFormItem, mapIterableFormItems } from '~/utils/form';

import { InstrumentFormContext } from '../../useForm';
import { InstrumentFormActions } from '../../useForm/actions';
import { getInheritValue, getSelfValue } from '../../useForm/utils';

import { StringArrayItem } from './types';

export const mapToStringArray = (data?: StringArrayItem[]): string[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => item.value);
};

export const mapToStringArrayItem = (data?: string[]): StringArrayItem[] => {
  if (!data) {
    return [];
  }

  return data.map((value) => ({ value }));
};

const useStringArray = (path: string) => {
  const { state, dispatch } = useContext(InstrumentFormContext);
  const disabled = state.saveStatus.pending;

  const selfValue = getSelfValue<string[]>(path, state.values);
  const inheritValue = getInheritValue<string[]>(path, state.parents);

  const [value, setValue] = useState(
    mapIterableFormItems<StringArrayItem>(
      mapToStringArrayItem(selfValue || inheritValue),
    ),
  );

  const handleAddItem = () => {
    if (disabled) {
      return;
    }

    const result = [
      ...value,
      getIterableFormItem<StringArrayItem>({ value: '' }),
    ];

    setValue(result);

    dispatch({
      type: InstrumentFormActions.SetFieldValue,
      payload: { path, value: mapToStringArray(result) },
    });
  };
  const handleRemoveItem = (index: number) => {
    if (disabled) {
      return;
    }

    const result = [...value.slice(0, index), ...value.slice(index + 1)];

    setValue(result);

    dispatch({
      type: InstrumentFormActions.SetFieldValue,
      payload: { path, value: mapToStringArray(result) },
    });
  };

  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = isInherited && selfValue !== undefined;
  const inheritTitle = inheritValue ? inheritValue.length : '';
  const handleReset = () => {
    if (disabled) {
      return;
    }

    setValue(
      mapIterableFormItems<StringArrayItem>(mapToStringArrayItem(inheritValue)),
    );

    dispatch({
      type: InstrumentFormActions.SetFieldValue,
      payload: { path, value: undefined },
    });
  };
  const register = (fieldPath: string) => {
    const onChange: FilledInputProps['onChange'] = ({ target }) => {
      if (disabled) {
        return;
      }

      const result = objectPath.set(value, fieldPath, target.value);
      setValue(result);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: mapToStringArray(result) },
      });
    };

    const inputValue = objectPath.get(value, fieldPath) || '';

    const error = state.errors.has(fieldPath);
    const message = state.errors.get(fieldPath);

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  return {
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    register,
    value,
    disabled,
  };
};

export default useStringArray;
