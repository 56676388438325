import { useEffect, useState } from 'react';

import { staticDataService } from '../../../../services/staticData.service';

import { MapProps } from './types';

export const useMaps = (providerId: string) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (providerId) {
          const { maps } = await staticDataService().getMaps(providerId);
          const formattedData = maps.map((item: MapProps) => {
            const { mapId, name } = item;
            return { value: mapId, label: name };
          });
          setData(formattedData);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [providerId]);

  return {
    data,
    isLoading,
  };
};
