import React, { useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Input,
  ISelectOption,
  Select,
  RadioGroup,
  IconButton,
} from 'react-ui-kit-exante';

import { Placeholder } from '../../../../../../components/Placeholder';
import { SortableList } from '../../../../../../components/SortableList';

import styles from './Trades.module.css';
import useTrades from './hooks/useTrades';
import { TradesProps } from './types';

const Trades: React.FC<TradesProps> = ({
  form,
  legalEntitiesList,
  defaultLegalEntity,
}) => {
  const {
    handleAppend,
    handleDelete,
    handleOrder,
    list,
    selectedId,
    setSelectedId,
    index,
    control,
  } = useTrades(form, defaultLegalEntity);

  const { current: unsetOptions } = useRef([
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
    {
      value: 'unset',
      label: 'Unset',
    },
  ]);

  const { current: sideOptions } = useRef<Array<ISelectOption>>([
    {
      value: 'revert',
      label: 'Revert',
    },
    {
      value: 'same',
      label: 'Same',
    },
  ]);

  const { current: logicTypeOptions } = useRef<Array<ISelectOption>>([
    { label: 'CLIENT', value: 'client' },
    { label: 'PROXY', value: 'proxy' },
    { label: 'MARKUP', value: 'markup' },
    { label: 'COUNTER', value: 'counter' },
  ]);

  const legalEntitiesOptions = useMemo<Array<ISelectOption>>(() => {
    return legalEntitiesList.map((value) => ({
      label: value,
      value,
    }));
  }, [legalEntitiesList]);

  const logicType = form.watch(`trades.${index}.logicType`);

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.Title}>Trades</div>
        <div className={styles.Actions}>
          <IconButton
            iconColor="action"
            iconName="add"
            iconSize={24}
            label="Add Trade"
            onClick={handleAppend}
          />
        </div>
      </div>

      {list.length === 0 && (
        <Placeholder title="The schema does not have trades" />
      )}

      {list.length > 0 && (
        <div className={styles.Body}>
          <SortableList
            items={list}
            selectedId={selectedId}
            onSelect={setSelectedId}
            onOrderChange={handleOrder}
          />

          <div className={styles.Form}>
            <div className={styles.Main}>
              <Controller
                key={`trades.${index}.name`}
                name={`trades.${index}.name`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    fullWidth
                    label="Trade name *"
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`trades.${index}.executionCounterparty`}
                name={`trades.${index}.executionCounterparty`}
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Execution Counterparty"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`trades.${index}.settlementCounterparty`}
                name={`trades.${index}.settlementCounterparty`}
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Settlement Counterparty"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`trades.${index}.counterpartyAccountId`}
                name={`trades.${index}.counterpartyAccountId`}
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Counterparty Account ID"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`trades.${index}.counterpartyClientId`}
                name={`trades.${index}.counterpartyClientId`}
                control={control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Counterparty Client ID"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className={styles.Main}>
              <Controller
                key={`trades.${index}.logicType`}
                name={`trades.${index}.logicType`}
                control={form.control}
                render={({ field }) => (
                  <Select
                    fullWidth
                    options={logicTypeOptions}
                    label="Trade Type"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`trades.${index}.legalEntity`}
                name={`trades.${index}.legalEntity`}
                control={form.control}
                render={({ field }) => (
                  <Select
                    fullWidth
                    options={legalEntitiesOptions}
                    label="Legal Entity"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              {logicType !== 'client' && (
                <>
                  <Controller
                    key={`trades.${index}.side`}
                    name={`trades.${index}.side`}
                    control={form.control}
                    render={({ field }) => (
                      <Select
                        fullWidth
                        options={sideOptions}
                        label="Side"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    key={`trades.${index}.accountId`}
                    name={`trades.${index}.accountId`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        label="Account ID"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    key={`trades.${index}.userId`}
                    name={`trades.${index}.userId`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        label="User ID"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </>
              )}
            </div>

            {logicType !== 'client' && (
              <>
                <div className={styles.SubRow}>
                  <Controller
                    key={`trades.${index}.allowedEntities`}
                    name={`trades.${index}.allowedEntities`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        isMultiple
                        options={legalEntitiesList}
                        controlProps={{
                          placeholder: 'Allowed Entities',
                        }}
                        sx={{ mt: '16px' }}
                        value={field.value}
                        onChange={(_e, value) => field.onChange(value)}
                      />
                    )}
                  />

                  <Controller
                    key={`trades.${index}.forbiddenEntities`}
                    name={`trades.${index}.forbiddenEntities`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        fullWidth
                        isMultiple
                        options={legalEntitiesList}
                        placeholder="Forbidden Entities"
                        value={field.value}
                        onChange={(_e, value) => field.onChange(value)}
                      />
                    )}
                  />
                </div>

                <div className={styles.Radio}>
                  <Controller
                    key={`trades.${index}.overrideSymbolId`}
                    name={`trades.${index}.overrideSymbolId`}
                    control={form.control}
                    render={({ field }) => (
                      <RadioGroup
                        radioOptions={unsetOptions}
                        formLabel="Override Symbol ID"
                        value={field.value}
                        handleChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    key={`trades.${index}.applyMarkup`}
                    name={`trades.${index}.applyMarkup`}
                    control={form.control}
                    render={({ field }) => (
                      <RadioGroup
                        radioOptions={unsetOptions}
                        formLabel="Apply Markup"
                        value={field.value}
                        handleChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </>
            )}

            <IconButton
              iconColor="radical"
              iconName="delete"
              iconSize={20}
              onClick={() => handleDelete(index)}
              style={{
                justifyContent: 'flex-start',
                marginRight: '5px',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Trades;
