import { initResources } from './resources';
import { initApp } from './utils/initApp';
import { Logger } from './utils/logger';

const logger = new Logger();

initResources(logger);

window.SYMBOLDB_UI_REACT = {
  ...window.SYMBOLDB_UI_REACT,
  render: (node: Element) => initApp(node),
};

// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
