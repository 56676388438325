import { useTheme } from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import { AppVersion } from '../AppVersion/AppVersion';

import { NAV_LINKS } from './constants';
import { Container, ListContainer, ListItem } from './styled';

export const Navigation = () => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ListContainer>
          {NAV_LINKS.map(({ to, label }) => (
            <li key={to}>
              <ListItem to={to}>{label}</ListItem>
            </li>
          ))}
        </ListContainer>
        <AppVersion />
      </Container>
    </ThemeProvider>
  );
};
