import { PropsWithChildren } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { Container } from './styled';
import { OverrideProps } from './types';

const OverrideProp = ({
  children,
  tooltip,
  color = 'default',
}: PropsWithChildren<OverrideProps>) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <Container color={color}>{children}</Container>
    </Tooltip>
  );
};

export default OverrideProp;
