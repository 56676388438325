import { useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTheme } from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import RegistrationSessionContainer from '~/components/RegistrationSessionContainer';

import { InstrumentForm, Placeholder, Sidebar } from './components';
import { routes } from './constants';
import { Body, Container, Content, Loading } from './styled';
import { SidebarRef } from './types';
import useDependencies from './useDependencies';

const Instruments = () => {
  const theme = useTheme();
  const sidebarRef = useRef<SidebarRef>(null);

  const { dependencies, options, status } = useDependencies();

  return (
    <RegistrationSessionContainer>
      <ThemeProvider theme={theme}>
        <Container>
          <Body>
            <Sidebar ref={sidebarRef} />

            {status.pending && <Loading size="l" />}
            {status.succeed && (
              <Content>
                <Routes>
                  <Route path={routes.LIST} element={<Placeholder />} />
                  <Route
                    path={routes.NEW}
                    element={
                      <InstrumentForm
                        dependencies={dependencies}
                        options={options}
                        onForceRefresh={(id) => sidebarRef.current?.refresh(id)}
                      />
                    }
                  />
                  <Route
                    path={routes.AS_NEW}
                    element={
                      <InstrumentForm
                        dependencies={dependencies}
                        options={options}
                        onForceRefresh={(id) => sidebarRef.current?.refresh(id)}
                      />
                    }
                  />
                  <Route
                    path={routes.EDIT}
                    element={
                      <InstrumentForm
                        dependencies={dependencies}
                        options={options}
                        onForceRefresh={(id) => sidebarRef.current?.refresh(id)}
                      />
                    }
                  />
                </Routes>
              </Content>
            )}
          </Body>
        </Container>
      </ThemeProvider>
    </RegistrationSessionContainer>
  );
};

export default Instruments;
