import { AxiosError } from 'axios';
import { FormEvent, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  createLegacyInstrument,
  fetchLegacyInstrument,
  saveLegacyInstrument,
  deleteLegacyInstrument,
} from '~/api/SymbolDBService';
import { NAV } from '~/pages/routing';
import { InstrumentType } from '~/types/models';

import { InstrumentRouteParams } from '../../../types';
import { InstrumentFormProps } from '../types';

import { InstrumentFormActions } from './actions';
import { initialState } from './constants';
import reducer from './reducer';
import { InstrumentFormReducer } from './types';
import {
  getInheritValue,
  getSelfValue,
  getErrorTitle,
  getInstrumentData,
  getInstrumentPayload,
} from './utils';

const useForm = (
  dependencies: InstrumentFormProps['dependencies'],
  onForceRefresh?: InstrumentFormProps['onForceRefresh'],
) => {
  const nav = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [isSetNameDialogShown, setIsSetNameDialogShown] = useState(false);

  const [state, dispatch] = useReducer<InstrumentFormReducer>(
    reducer,
    initialState,
  );

  const type = useMemo(() => {
    return (
      getSelfValue<InstrumentType>('type', state.values) ||
      getInheritValue<InstrumentType>('type', state.parents)
    );
  }, [state]);

  const handleClose = () => {
    nav(NAV.INSTRUMENTS);
  };

  const fetchFormValues = async () => {
    dispatch({ type: InstrumentFormActions.FetchStart });

    try {
      const response = await fetchLegacyInstrument(id);

      if (response.length > 0) {
        dispatch({
          payload: getInstrumentData(response),
          type: InstrumentFormActions.FetchSucceed,
        });
      } else {
        dispatch({
          type: InstrumentFormActions.FetchError,
          payload: 'DELETED',
        });
      }
    } catch (e) {
      dispatch({
        type: InstrumentFormActions.FetchError,
        payload: e as AxiosError,
      });
    }
  };

  const handleConvert = async () => {
    if (state.dirty) {
      Notification.error({
        title: "Can't convert when form is dirty",
        isPermanent: true,
      });

      return;
    }

    dispatch({ type: InstrumentFormActions.ConvertStart });

    const payload = getInstrumentPayload(
      { ...state.values, isAbstract: !state.values.isAbstract },
      dependencies,
    );

    try {
      if (id && onForceRefresh) {
        await saveLegacyInstrument(id, payload);
        onForceRefresh(id);
      }

      dispatch({ type: InstrumentFormActions.ConvertSucceed });
    } catch (error) {
      dispatch({
        type: InstrumentFormActions.ConvertError,
        payload: error as AxiosError,
      });

      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message;

      Notification.error({
        title: getErrorTitle(
          message,
          (error as AxiosError).response?.data?.description,
        ),
        isPermanent: true,
      });
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
        isPermanent: true,
      });

      return;
    }

    dispatch({ type: InstrumentFormActions.SaveStart });

    const payload = getInstrumentPayload({ ...state.values }, dependencies);

    try {
      if (id) {
        await saveLegacyInstrument(id, payload);
      } else if (onForceRefresh) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id } = await createLegacyInstrument(payload);
        onForceRefresh(_id);
      }

      dispatch({ type: InstrumentFormActions.SaveSucceed });
    } catch (error) {
      dispatch({
        type: InstrumentFormActions.SaveError,
        payload: error as AxiosError,
      });

      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message;

      Notification.error({
        title: getErrorTitle(
          message,
          (error as AxiosError).response?.data?.description,
        ),
        isPermanent: true,
      });
    }
  };

  const handleDelete = async () => {
    if (id && onForceRefresh) {
      dispatch({ type: InstrumentFormActions.DeleteStart });

      try {
        await deleteLegacyInstrument(id);
        dispatch({ type: InstrumentFormActions.DeleteSucceed });
        onForceRefresh();
        handleClose();
      } catch (error) {
        dispatch({
          type: InstrumentFormActions.DeleteError,
          payload: error as AxiosError,
        });

        setIsDeleteDialogShown(false);

        const message =
          (error as AxiosError).response?.data?.description?.reason ||
          (error as AxiosError).response?.data?.message;

        Notification.error({
          title: getErrorTitle(
            message,
            (error as AxiosError).response?.data?.description,
          ),
          isPermanent: true,
        });
      }
    }
  };

  const handleCreateChild = () => {
    nav(`${NAV.INSTRUMENTS}/create`);
    dispatch({ type: InstrumentFormActions.AddChild });
  };

  const handleDeleteDecline = () => {
    setIsDeleteDialogShown(false);
  };
  const handleDeleteRequest = () => {
    setIsDeleteDialogShown(true);
  };

  const handleSaveAsNew = async (name: string) => {
    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
        isPermanent: true,
      });

      return;
    }

    dispatch({ type: InstrumentFormActions.SaveStart });
    setIsSetNameDialogShown(false);

    const payload = getInstrumentPayload(
      { ...state.values, name, path: [] },
      dependencies,
    );

    try {
      if (onForceRefresh) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id } = await createLegacyInstrument(payload);
        onForceRefresh(_id);
      }

      dispatch({ type: InstrumentFormActions.SaveSucceed });
    } catch (error) {
      dispatch({
        type: InstrumentFormActions.SaveError,
        payload: error as AxiosError,
      });

      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message;

      Notification.error({
        title: getErrorTitle(
          message,
          (error as AxiosError).response?.data?.description,
        ),
        isPermanent: true,
      });
    }
  };

  const handleSaveAsRequest = () => {
    setIsSetNameDialogShown(true);
  };
  const handleDeclineSaveAsRequest = () => {
    setIsSetNameDialogShown(false);
  };

  useEffect(() => {
    if (id) {
      fetchFormValues();
    } else {
      dispatch({ type: InstrumentFormActions.SetReady });
    }
  }, [id]);

  return {
    type,
    isCreating: !id,
    isDeleteDialogShown,
    isSetNameDialogShown,

    state,
    dispatch,

    handleClose,
    handleConvert,
    handleCreateChild,
    handleDelete,
    handleDeleteDecline,
    handleDeleteRequest,
    handleSaveAsRequest,
    handleDeclineSaveAsRequest,
    handleSaveAsNew,
    handleSubmit,
  };
};

export * from './types';
export { default as InstrumentFormContext } from './context';
export default useForm;
