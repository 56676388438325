import { apiService } from '~/services/api/api.service';
import { getBOBaseUrl } from '~/services/api/helpers';

import { getQueryURL, getSessionProps } from '../utils';

import {
  GetInstrumentsParams,
  GetInstrumentsResponse,
  GetTreeParams,
  GetTreeResponse,
} from './types';

const api = apiService(getBOBaseUrl());

export const getInstruments = async (
  params: GetInstrumentsParams,
): GetInstrumentsResponse => {
  const endPoint = getQueryURL('/api/instruments', params);

  const { data } = await api.get({ endPoint, ...getSessionProps() });

  return data;
};

export const getInstrumentsTree = async (
  params?: GetTreeParams,
): GetTreeResponse => {
  const endPoint = getQueryURL('/api/instruments/tree', params);

  const { data } = await api.get({ endPoint, ...getSessionProps() });

  return data;
};
