import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import styles from './Brokers.module.css';
import { BrokerForm, BrokersList, Placeholder } from './components';
import { BrokersRoutes } from './constants';
import { useBrokersList, useBrokersRouting } from './hooks';

export const Brokers = () => {
  const location = useLocation();
  const [dirtyItem, setDirtyItem] = useState<string | null>(null);

  const {
    selected,
    handleCreateBroker,
    handleOpenBroker,
    handleCloseBrokersForm,
  } = useBrokersRouting();

  useEffect(() => {
    setDirtyItem(null);
  }, [location]);

  const {
    brokers,
    isLoading,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  } = useBrokersList();

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>Brokers</div>
      <div className={styles.Body}>
        <BrokersList
          list={brokers}
          selected={selected}
          warned={dirtyItem}
          isLoading={isLoading}
          onAddButtonClick={handleCreateBroker}
          onOpenBroker={handleOpenBroker}
        />
        <Routes>
          <Route path={BrokersRoutes.LIST} element={<Placeholder />} />
          <Route
            path={BrokersRoutes.NEW}
            element={
              <BrokerForm
                onClose={handleCloseBrokersForm}
                onSuccess={handleSuccessCreate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
          <Route
            path={BrokersRoutes.EDIT}
            element={
              <BrokerForm
                onClose={handleCloseBrokersForm}
                onSuccess={handleSuccessUpdate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};
