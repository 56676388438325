import { NAV } from '~/pages/routing';

export const NAV_LINKS = [
  {
    to: NAV.BROKERS,
    label: 'Brokers',
  },
  {
    to: NAV.THEMES,
    label: 'Themes',
  },
  {
    to: NAV.LOCALIZATIONS,
    label: 'Localizations',
  },
  {
    to: NAV.REPORTING,
    label: 'Reporting',
  },
  {
    to: NAV.CURRENCY_HOLIDAYS,
    label: 'Currency Holidays',
  },
  {
    to: NAV.EXCHANGES,
    label: 'Exchanges',
  },
  {
    to: NAV.FEEDS,
    label: 'Feeds',
  },
  {
    to: NAV.INSTRUMENTS,
    label: 'Instruments',
  },
  {
    to: NAV.SCHEDULES,
    label: 'Schedules',
  },
  {
    to: NAV.MAPPING,
    label: 'Configs',
  },
  {
    to: NAV.STATIC_DATA,
    label: 'Static Data',
  },
  {
    to: NAV.CURRENCIES,
    label: 'Currencies',
  },
  {
    to: NAV.EXECUTION_SCHEMES,
    label: ' Execution Schemes',
  },
  {
    to: NAV.SYMBOL_TYPES,
    label: 'Symbol Types',
  },
];
