import { initialState } from '../constants';
import { InstrumentFormState } from '../types';

const onAddChild = (state: InstrumentFormState): InstrumentFormState => {
  return {
    ...initialState,
    ready: true,
    values: {
      ...initialState.values,
      path: [...state.values.path],
    },
  };
};

export default onAddChild;
