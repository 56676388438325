import * as objectPath from 'object-path-immutable';

import { FeedGateway } from '~/types/models';

import { InstrumentFormProps } from '../../../types';
import {
  InstrumentData,
  InstrumentFormState,
  PatchedGateway,
} from '../../../useForm/types';
import GATEWAYS from '../configs/gateways';
import { OverrideNode } from '../types';

import { getIDMaps, getOverrideEntities, getOverrideNodes } from './helpers';

const ROOT_PATH = 'feeds.gateways';

export const getGatewayName = (gateway: FeedGateway): string =>
  `${gateway.providerName} : ${gateway.name}`;

const getGateways = (
  state: InstrumentFormState,
  dependencies: InstrumentFormProps['dependencies'],
): OverrideNode[] => {
  const getter = (id: string): string => {
    const result = dependencies.feedGateways?.find(
      (feedGateway) => feedGateway._id === id,
    );

    return result ? getGatewayName(result) : 'Unknown Gateway';
  };

  const extractor = (
    source: InstrumentData,
    id: string,
  ): PatchedGateway | undefined => {
    return objectPath.get<typeof source, PatchedGateway | undefined>(
      source,
      `${ROOT_PATH}.${id}`,
    );
  };

  const rooter = (id: string): string | undefined => {
    const result = dependencies.feedGateways?.find(
      (feedGateway) => feedGateway._id === id,
    );

    return result?.providerId;
  };

  const entities = getOverrideEntities<PatchedGateway>(
    ROOT_PATH,
    getter,
    state,
  );

  return getOverrideNodes<PatchedGateway>(
    state,
    extractor,
    rooter,
    entities,
    GATEWAYS,
    getIDMaps(dependencies),
  );
};

export default getGateways;
