import equal from 'fast-deep-equal';
import * as objectPath from 'object-path-immutable';

import { filterFromEmpty } from '~/utils/object';

import { AppendValue, InstrumentFormState } from '../types';

const onAppendOverride = (
  state: InstrumentFormState,
  payload: AppendValue,
): InstrumentFormState => {
  const values = objectPath.set(
    state.values,
    `${payload.path}.${payload.key}`,
    payload.data || {},
  );

  return {
    ...state,

    values,
    dirty: !equal(filterFromEmpty(values), filterFromEmpty(state.ref)),
  };
};

export default onAppendOverride;
