import * as cookies from 'js-cookie';

import {
  SESSION_ID_COOKIE_KEY,
  SESSION_GOOGLE_COOKIE_KEY,
} from '../authorization/authorization.consts';

export const accessToken = cookies.get(SESSION_GOOGLE_COOKIE_KEY);

export function getSessionIdFromCookie(sessionKey?: string) {
  return cookies.get(sessionKey || SESSION_ID_COOKIE_KEY);
}
