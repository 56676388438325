import { InstrumentType } from '~/types/models';

export const TypedTabs: Record<InstrumentType, string> = {
  BOND: 'Bond',
  CALENDAR_SPREAD: 'Calendar Spread',
  CFD: 'Cfd',
  FOREX: 'Forex',
  FUND: 'Fund',
  FUTURE: 'Future',
  FX_SPOT: 'Fx Spot',
  OPTION: 'Option',
  STOCK: 'Stock',
};
