import { useEffect } from 'react';

export const useBeforeUnload = (isDirtyPage: boolean) => {
  const beforeUnLoad = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    returnValue: string;
  }) => {
    if (isDirtyPage) {
      e.preventDefault();
      e.stopPropagation();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnLoad);

    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [isDirtyPage]);
};
