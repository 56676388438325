import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.color.line.secondary};
  border-radius: 8px;
  outline: hidden;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 42px 42px 42px;
  height: 32px;
  color: ${(props) => props.theme.color.typo.secondary};
  text-transform: uppercase;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid ${(props) => props.theme.color.line.secondary};
  padding: 0 24px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    padding: 0 24px;
  }

  & > *:nth-child(odd) {
    background-color: ${(props) => props.theme.color.bg.basic};
  }

  & > *:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const Title = styled.span`
  display: flex;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
