import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  BoolRow,
  BoolTable,
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import { DefaultSequences, Sequences } from './components';
import type { SelectedFeedProvider } from './types';

const GeneratorSettings = ({ id }: SelectedFeedProvider) => {
  const { current: priceModeTypeOptions } = useRef<ISelectOption[]>([
    { value: 'normal', label: 'normal' },
    { value: 'fixed', label: 'fixed' },
  ]);

  return (
    <Section>
      <SectionTitle>Generator Settings</SectionTitle>

      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.priceChangeInterval`}
      >
        Price change interval
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.sizeChangeInterval`}
      >
        Size change interval
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.tradeInterval`}
      >
        Trade interval
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.auxChangeInterval`}
      >
        Aux change interval
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.optionDataChangeInterval`}
      >
        Option data change interval
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.bondDataChangeInterval`}
      >
        Bond data change interval
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.minDepth`}
      >
        Min depth
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.maxDepth`}
      >
        Max depth
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.minSize`}
      >
        Min size
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.maxSize`}
      >
        Max size
      </TextInput>
      <BoolTable>
        <BoolRow
          path={`feeds.providerOverrides.${id}.generatorSettings.generateYield`}
        >
          Generate yield
        </BoolRow>
      </BoolTable>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.optionDataValueProbability`}
      >
        Option data value probability
      </TextInput>
      <Select
        options={priceModeTypeOptions}
        path={`feeds.providerOverrides.${id}.generatorSettings.priceMode`}
      >
        Price mode
      </Select>

      <DefaultSequences id={id} />

      <Sequences id={id} />

      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.sequences.from`}
      >
        Sequence From
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.generatorSettings.sequences.to`}
      >
        Sequence To
      </TextInput>
    </Section>
  );
};

export default GeneratorSettings;
