import { styled } from '@mui/material/styles';
import { FC } from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';
import { TDefaultTheme } from 'react-ui-kit-exante';

import { DEFAULT_EDITOR_PARAMS } from './CodeEditor.consts';

const CodeEditorContainer = styled('div')(({ theme }: TDefaultTheme) => ({
  padding: '24px 0 0 24px',
  backgroundColor: theme?.color?.bg?.promo,
  borderBottom: `1px solid ${theme?.color?.icon?.primary}`,
}));

const CodeEditorComponent: FC<IAceEditorProps> = (props) => {
  return (
    <CodeEditorContainer>
      <AceEditor {...DEFAULT_EDITOR_PARAMS} {...props} />
    </CodeEditorContainer>
  );
};

export const CodeEditor = styled(CodeEditorComponent)(
  ({ theme }: TDefaultTheme) => ({
    '.ace_content, .ace_gutter': {
      background: theme?.color?.bg?.promo,
    },
  }),
);
