import React from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input } from 'react-ui-kit-exante';

import Placeholder from '../../../../../../../components/Placeholder/Placeholder';
import { SortableList } from '../../../../../../../components/SortableList';

import styles from './Accounts.module.css';
import useAccounts from './hooks/useAccounts';
import { AccountsProps } from './types';

const Accounts: React.FC<AccountsProps> = ({ index, form }) => {
  const {
    handleAppend,
    list,
    selectedId,
    setSelectedId,
    accountIndex,
    remove,
  } = useAccounts(index, form);

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.Title}>Accounts</div>
        <div className={styles.Actions}>
          <IconButton
            iconColor="action"
            iconName="add"
            iconSize={24}
            label="Add Account"
            onClick={handleAppend}
          />
        </div>
      </div>

      {list.length === 0 && (
        <Placeholder title="The gateway does not have accounts" />
      )}

      {list.length > 0 && (
        <div className={styles.Body}>
          <SortableList
            items={list}
            selectedId={selectedId}
            onSelect={setSelectedId}
          />

          <div className={styles.Form}>
            <div className={styles.Row}>
              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.name`}
                name={`gateways.${index}.accounts.${accountIndex}.name`}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    fullWidth
                    label="Account name *"
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.settlementCounterparty`}
                name={`gateways.${index}.accounts.${accountIndex}.settlementCounterparty`}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    fullWidth
                    label="Settlement counterparty *"
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <div className={styles.Row}>
              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.executionCounterparty`}
                name={`gateways.${index}.accounts.${accountIndex}.executionCounterparty`}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    fullWidth
                    label="Execution Counterparty *"
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.legalEntity`}
                name={`gateways.${index}.accounts.${accountIndex}.legalEntity`}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    fullWidth
                    label="Legal Entity *"
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <div className={styles.Row}>
              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.account`}
                name={`gateways.${index}.accounts.${accountIndex}.account`}
                control={form.control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Account"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.accountDescription`}
                name={`gateways.${index}.accounts.${accountIndex}.accountDescription`}
                control={form.control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Account Description"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>

            <div className={styles.Row}>
              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.settlementCounterpartyAccount`}
                name={`gateways.${index}.accounts.${accountIndex}.settlementCounterpartyAccount`}
                control={form.control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Settlement Counterparty Account"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.accounts.${accountIndex}.clientId`}
                name={`gateways.${index}.accounts.${accountIndex}.clientId`}
                control={form.control}
                render={({ field }) => (
                  <Input
                    fullWidth
                    label="Client ID"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>

            <IconButton
              iconColor="radical"
              iconName="delete"
              iconSize={20}
              onClick={() => remove(accountIndex)}
              style={{
                justifyContent: 'flex-start',
                marginRight: '5px',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Accounts;
