export const getUUIDKey = (withHyphen = true, uuidv4 = false) => {
  let letters =
    'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
  let result = '';

  if (uuidv4) {
    letters = '0123456789abcdef';
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 32; i++) {
    if (withHyphen && (i === 8 || i === 12 || i === 16 || i === 20)) {
      result += '-';
    }

    result += letters[Math.floor(Math.random() * letters.length)];
  }

  return result;
};
