import { InstrumentFormState } from '../types';

const onDeleteError = (state: InstrumentFormState): InstrumentFormState => ({
  ...state,

  deleteStatus: {
    succeed: false,
    pending: false,
    error: true,
  },
});

export default onDeleteError;
