import React, { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { IconButton, Loader } from 'react-ui-kit-exante';

import { DeleteDialog } from '../../../../components/DeleteDialog';

import styles from './ScheduleForm.module.css';
import { MainForm, RulesForm } from './components';
import { useSchedule } from './hooks';
import { ScheduleFormProps } from './types';

const ScheduleForm: FC<ScheduleFormProps> = ({
  onClose,
  onDelete,
  onSuccess,
  onDirty,
}) => {
  const {
    deletingStatus,
    fetchingStatus,
    form,
    handleDelete,
    handleAppendRule,
    isNew,
    isSaveDisabled,
    savingStatus,
    title,
    onSubmit,
    isDirty,
    id,
  } = useSchedule({ onDelete, onSuccess });

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <div className={styles.Container}>
        <div className={styles.Loader}>
          <Loader size="l" isCentered />
        </div>
      </div>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit} className={styles.Container}>
          <div className={styles.Header}>
            <span className={styles.Title}>{title}</span>
            <div className={styles.Actions}>
              <IconButton
                disabled={isSaveDisabled || savingStatus.isPending}
                iconColor={isSaveDisabled ? 'secondary' : 'action'}
                iconName="save"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="delete"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="close"
                iconSize={24}
                onClick={onClose}
              />
            </div>
          </div>

          <MainForm form={form} />

          <div className={styles.Rules}>
            <div className={styles.Header}>
              <div className={styles.Title}>Rules</div>
              <div className={styles.Actions}>
                <IconButton
                  iconColor="action"
                  iconName="add"
                  iconSize={24}
                  label="Add Rule"
                  onClick={handleAppendRule}
                />
              </div>
            </div>
            <RulesForm form={form} />
          </div>
        </form>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDelete}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default ScheduleForm;
