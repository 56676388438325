import { ISelectOption } from 'react-ui-kit-exante';

import { SchedulesResponseItem } from '~/pages/Schedules/types';
import { Currency, ExchangeItem, Section } from '~/types/models';

export const getExchangeOptions = (data?: ExchangeItem[]): ISelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    label: item.name,
    value: item._id,
  }));
};

export const getCurrenciesOptions = (data?: Currency[]): ISelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item: { _id: string }) => ({
    label: item._id,
    value: item._id,
  }));
};

export const getSchedulesOptions = (
  data?: SchedulesResponseItem[],
): ISelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    label: item.name,
    value: item._id,
  }));
};

export const getSectionsOptions = (data?: Section[]): ISelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    label: item.name,
    value: item._id,
  }));
};
