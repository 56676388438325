import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Notification, useTableData } from 'react-ui-kit-exante';

import { ROUTES } from '~/pages/routing';
import { staticDataService } from '~/services/staticData.service';

import { Wrapper } from './EventsTable.styled';
import {
  EVENTS_TABLE_ID,
  getEventsTableColumns,
  displayedColumnKeys,
  calculateCountOfPages,
} from './helpersEvensTable';
import { DataTable } from './types';

interface EventsTableProps {
  isUpdate: boolean | null;
  taskId: string;
}

const EventsTable = ({ isUpdate, taskId }: EventsTableProps) => {
  const [tableParams, setTableParams] = useState({ taskId });
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [dataTableEvents, setDataTableEvents] = useState<DataTable>({
    count: 0,
    events: [],
  });

  const navigate = useNavigate();
  const totalTable = dataTableEvents?.count;

  const getEventsData = useCallback(async ({ params }) => {
    if (!params.taskId) {
      return [];
    }

    setIsLoadingTable(true);

    try {
      const response = await staticDataService().getEvents(params);

      setDataTableEvents(response);

      setIsLoadingTable(false);

      return response;
    } catch (error: any) {
      setIsLoadingTable(false);

      Notification.error(error?.message);

      return [];
    }
  }, []);

  useEffect(() => {
    if (typeof isUpdate === 'boolean') {
      getEventsData({ params: tableParams });
    }

    return () => {
      /**
       * Warning: Can't perform a React state update on an unmounted component.
       * This is a no-op, but it indicates a memory leak in your application.
       * To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
       */
      setDataTableEvents({
        count: 0,
        events: [],
      });
    };
  }, [isUpdate]);

  const getTableData = useCallback(
    ({ params }) => {
      setTableParams({
        taskId,
        ...params,
      });

      return getEventsData({
        params: {
          taskId,
          ...params,
        },
      });
    },
    [getEventsData, taskId],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: getTableData,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
      saveViewParamsAfterLeave: true,
      sorting: { getDefaultSorting: () => [{ id: 'date', desc: true }] },
      tableId: EVENTS_TABLE_ID,
    }),
    [getTableData],
  );

  const {
    filters,
    setFilter,
    removeFilter,
    resetFilters,
    limit,
    page,
    setPage,
    setLimit,
    setSorting,
  } = useTableData(tableDataArgs);

  const filterProps = useMemo(
    () => ({
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [resetFilters, filters],
  );

  const columns = useMemo(
    () =>
      getEventsTableColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [],
  );

  const pageCount = useMemo(
    () => totalTable && calculateCountOfPages(totalTable, limit),
    [totalTable, limit],
  );

  const handleRowClick = useCallback(
    ({ eventId: id }) => {
      navigate(`${ROUTES.STATIC_DATA}/${id}`);
    },
    [navigate],
  );

  return (
    <Wrapper>
      <Table
        data={dataTableEvents?.events || []}
        hasFilters
        isFlexLayout
        hasPagination
        showTableInfo
        title="History"
        columns={columns}
        onSort={setSorting}
        tableId={EVENTS_TABLE_ID}
        saveViewParamsAfterLeave
        isLoading={isLoadingTable}
        pageSizes={[5, 10, 20, 30]}
        filteringProps={filterProps}
        handleRowClick={handleRowClick}
        displayedColumnKeys={displayedColumnKeys}
        manualSortBy
        defaultSortBy={[]}
        serverPaginationProps={{
          setPage,
          pageCount,
          pageSize: limit,
          pageIndex: page,
          setPageSize: setLimit,
          total: totalTable || 0,
        }}
      />
    </Wrapper>
  );
};

export default EventsTable;
