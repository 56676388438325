import React, { useCallback } from 'react';
import {
  Table,
  PanelHeader,
  PanelGroup,
  IconButton,
  useTheme,
} from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import { DeleteDialog } from '../../components/DeleteDialog';
import { useModal } from '../../hooks/useModal';

import { LocalizationForm } from './LocalizationForm/LocalizationForm';
import styles from './Localizations.module.css';
import { COLUMNS } from './constants';
import { useLocalizations } from './useLocalizations';

export const Localizations = () => {
  const theme = useTheme();
  const { opened, onClose, onOpen, data } = useModal();

  const {
    isShowLocalization,
    isNewLocalization,
    isLocalizationsLoading,
    locale,
    locales,
    localizations,
    handlers: {
      addLocale,
      addField,
      deleteField,
      deleteLocale,
      getSingleLocale,
      resetLocaleState,
      getLocales,
      createNewLocalization,
      deleteLocalization,
    },
  } = useLocalizations(onOpen);

  const onDelete = useCallback(
    async (key: string) => {
      await deleteLocalization(key);
      await getLocales();
    },
    [deleteLocalization, getLocales],
  );

  return (
    <ThemeProvider theme={theme}>
      <PanelHeader title="Localizations">
        <IconButton
          data-test-id="localizations__button--add"
          iconColor="action"
          iconName="add"
          iconSize={24}
          label="Add localization"
          onClick={createNewLocalization}
        />
      </PanelHeader>
      <PanelGroup className={styles.PanelGroup} equalWidth>
        <Table
          tableId="localizations"
          columns={COLUMNS}
          data={localizations}
          handleRowClick={({ key }) => getSingleLocale(key)}
          isHiddenColumnSelect
          hasPagination
          isLoading={isLocalizationsLoading}
          isFlexLayout
        />

        {isShowLocalization && locale !== null && (
          <LocalizationForm
            isNewLocalization={isNewLocalization}
            onClose={resetLocaleState}
            locale={locale}
            locales={locales}
            onUpdateLocalizations={getLocales}
            onDelete={onDelete}
            addLocale={addLocale}
            addField={addField}
            deleteField={deleteField}
          />
        )}
        <DeleteDialog
          isShown={opened}
          onConfirm={() => {
            deleteLocale(data as string);
            onClose();
          }}
          onDecline={onClose}
        />
      </PanelGroup>
    </ThemeProvider>
  );
};
