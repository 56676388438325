import { LegacyInstrument, TreeItem } from '~/types/models';

import { UnifiedTreeItem } from './types';

export const getUnifiedTreeItem = (item: TreeItem): UnifiedTreeItem => {
  return {
    abstract: item.abstract > 0,
    expiry: Boolean(item.expiry),
    id: item.id,
    name: item.name,
    trading: Boolean(item.trading),
    nodes: (item.child || []).map(getUnifiedTreeItem),
    syncOnSchedule: Boolean(item.syncOnSchedule),
  };
};

export const getUnifiedTreeItemFromInstrument = (
  id: string,
  item: LegacyInstrument,
): UnifiedTreeItem => {
  return {
    id,
    abstract: item.isAbstract,
    expiry: false,
    name: item.name,
    trading: item.isTrading,
    syncOnSchedule: item?._meta_?.syncOnSchedule,
    nodes: [],
  };
};
