import * as objectPath from 'object-path-immutable';

import { BrokerAccount } from '~/types/models';

import { InstrumentFormProps } from '../../../types';
import {
  InstrumentData,
  InstrumentFormState,
  PatchedAccount,
} from '../../../useForm';
import ACCOUNTS from '../configs/accounts';
import { OverrideNode } from '../types';

import { getIDMaps, getOverrideNodes, getOverrideEntities } from './helpers';

const ROOT_PATH = 'brokers.accounts';

export const getAccountName = (data: BrokerAccount): string =>
  `${data.providerName} : ${data.gatewayName} : ${data.name}`;

const getAccounts = (
  state: InstrumentFormState,
  dependencies: InstrumentFormProps['dependencies'],
): OverrideNode[] => {
  const getter = (id: string): string => {
    const result = dependencies.brokerAccounts?.find(
      (brokerAccount) => brokerAccount._id === id,
    );

    return result ? getAccountName(result) : 'Unknown Account';
  };

  const extractor = (
    source: InstrumentData,
    id: string,
  ): PatchedAccount | undefined => {
    return objectPath.get<typeof source, PatchedAccount | undefined>(
      source,
      `${ROOT_PATH}.${id}`,
    );
  };

  const rooter = (id: string): string | undefined => {
    const result = dependencies.brokerAccounts?.find(
      (brokerAccount) => brokerAccount._id === id,
    );

    return result?.providerId;
  };

  const entities = getOverrideEntities<PatchedAccount>(
    ROOT_PATH,
    getter,
    state,
  );

  return getOverrideNodes<PatchedAccount>(
    state,
    extractor,
    rooter,
    entities,
    ACCOUNTS,
    getIDMaps(dependencies),
  );
};

export default getAccounts;
