import { omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';
import { SuccessMessages } from '../Themes/constants';

import { ILocalization, ILocale } from './types';

const initialValue = {
  key: '',
  localization: {
    default: {
      default: '',
    },
  },
};

export const useLocalizations = (
  onModalOpen?: (localeName: string) => void,
) => {
  const [localizations, setLocalizations] = useState<Array<ILocalization>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isShowLocalization, setIsShowLocalization] = useState<boolean>(false);
  const [isNewLocalization, setIsNewLocalization] = useState<boolean>(false);
  const [locale, setLocale] = useState<ILocalization | null>(null);

  const getSingleLocale = useCallback(async (key?: string) => {
    try {
      const response = await symbolDBService().getSingleLocalization(key);
      setLocale(response);
    } catch (error: any) {
      Notification.error(error?.message);
    }
    setIsNewLocalization(false);
    setIsShowLocalization(true);
  }, []);

  const createNewLocalization = useCallback(() => {
    setLocale(initialValue);
    setIsNewLocalization(true);
    setIsShowLocalization(true);
  }, []);

  const resetLocaleState = useCallback(() => {
    setIsShowLocalization(false);
    setLocale(initialValue);
  }, []);

  const getLocales = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await symbolDBService().getLocalizations();
      setLocalizations(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteLocalization = useCallback(async (key: string) => {
    try {
      await symbolDBService().deleteLocalization(key);
      Notification.success({ title: SuccessMessages.Delete });
      setIsShowLocalization(false);
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getLocales();
    })();
  }, [getLocales]);

  const localizationsWithLocales = useMemo(() => {
    return localizations.map(({ localization, ...rest }) => ({
      locales: Object.keys(localization)
        .filter((key) => key !== 'default')
        .map((key) => key.toUpperCase()),
      localization,
      ...rest,
    }));
  }, [localizations]);

  const locales = useMemo(() => {
    return localizations
      .reduce(
        (acc, { localization }) => [
          ...new Set([
            ...acc,
            ...Object.keys(localization).map((key) => key.toUpperCase()),
          ]),
        ],
        [] as Array<string>,
      )
      .filter((key) => key !== 'DEFAULT');
  }, [localizations]);

  const addField = useCallback((value: string, localeName: string) => {
    if (!value) {
      Notification.error({
        title: 'The field name must not be an empty value',
      });
      return;
    }
    setLocale((prevState): ILocalization | null => {
      if (prevState === null) {
        return prevState;
      }

      return {
        ...prevState,
        localization: {
          ...prevState.localization,
          [localeName]: { ...prevState.localization[localeName], [value]: '' },
        },
      };
    });
  }, []);

  const deleteField = useCallback(
    (localeName: string, fieldName: string) => {
      if (fieldName === 'default') {
        if (typeof onModalOpen === 'function') {
          onModalOpen(localeName);
        }
        return;
      }

      setLocale((prevState): ILocalization | null => {
        if (prevState === null) {
          return prevState;
        }

        const result = omit(prevState.localization[localeName], [
          fieldName,
        ]) as ILocale;

        return {
          ...prevState,
          localization: {
            ...prevState.localization,
            [localeName]: result,
          },
        };
      });
    },
    [onModalOpen],
  );

  const deleteLocale = useCallback((localeName: string) => {
    setLocale((prevState) => {
      if (prevState === null) {
        return prevState;
      }

      const result = omit(prevState.localization, [localeName]) as {
        default: ILocale;
        [key: string]: ILocale;
      };

      return {
        ...prevState,
        localization: result,
      };
    });
  }, []);

  const addLocale = useCallback((localeName: string) => {
    if (!localeName) {
      Notification.error({
        title: 'The locale name must not be an empty value',
      });
      return;
    }
    setLocale((prevState) => {
      if (prevState === null) {
        return prevState;
      }
      return {
        ...prevState,
        localization: {
          ...prevState?.localization,
          [localeName]: { default: '' },
        },
      };
    });
  }, []);

  return useMemo(
    () => ({
      isShowLocalization,
      isNewLocalization,
      isLocalizationsLoading: isLoading,
      localizations: localizationsWithLocales,
      localizationKeys: localizations.map(({ key }) => key),
      locale,
      locales,
      handlers: {
        addField,
        addLocale,
        createNewLocalization,
        deleteField,
        deleteLocale,
        deleteLocalization,
        getLocales,
        getSingleLocale,
        resetLocaleState,
      },
    }),
    [
      addField,
      addLocale,
      createNewLocalization,
      deleteField,
      deleteLocale,
      deleteLocalization,
      getLocales,
      getSingleLocale,
      isLoading,
      isNewLocalization,
      isShowLocalization,
      locale,
      locales,
      localizations,
      localizationsWithLocales,
      resetLocaleState,
    ],
  );
};
