import { FilledInputProps } from '@mui/material/FilledInput';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { InstrumentFormContext } from '../../useForm';
import { InstrumentFormActions } from '../../useForm/actions';
import { getInheritValue, getSelfValue } from '../../useForm/utils';

const useSelect = (path: string, options: ISelectOption[]) => {
  const { state, dispatch } = useContext(InstrumentFormContext);
  const selfValue = getSelfValue<string>(path, state.values);
  const inheritValue = getInheritValue<string>(path, state.parents);

  const [value, setValue] = useState(selfValue || inheritValue);

  const disabled = state.saveStatus.pending;
  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const inheritTitle = useMemo(() => {
    const option = options.find((item) => item.value === inheritValue);

    return (option?.label as string) || inheritValue;
  }, [inheritValue, options]);

  const handleChange: FilledInputProps['onChange'] = ({ target }) => {
    if (!disabled) {
      setValue(target.value);
      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: target.value },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(inheritValue);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  useEffect(() => {
    setValue(selfValue || inheritValue);
  }, [path, selfValue, inheritValue]);

  return {
    isInherited,
    isResetButtonActive,
    inheritValue,
    inheritTitle,
    error,
    value,
    disabled,
    handleChange,
    handleReset,
  };
};

export default useSelect;
