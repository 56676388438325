import { yupResolver } from '@hookform/resolvers/yup';
import { orderBy, indexOf } from 'lodash';
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  IconButton,
  Input,
  Notification,
  Panel,
} from 'react-ui-kit-exante';

import { symbolDBService } from '../../../services/symbolDB.service';
import { getProp } from '../../../utils/getProp';
import { SuccessMessages } from '../../Themes/constants';
import stylesThemes from '../../Themes/themes.module.css';
import { EDITABLE_FIELDS, EXCLUDED_FIELDS, FIXED_ORDER } from '../constants';
import { IReportingFormProps, IReportingFormValues } from '../types';

import styles from './ReportingForm.module.css';
import { schema } from './ReportingForm.schema';

export const ReportingForm: FC<IReportingFormProps> = ({
  provider,
  isNewProvider,
  onDelete,
  onClose,
  onUpdateProviders,
  title = 'New reporting provider',
}) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IReportingFormValues>({
    defaultValues: provider,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ ...provider });
  }, [provider, reset]);

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id } = provider;
    onDelete(_id);
  }, [onDelete, provider]);

  const controls = useMemo(
    () => (
      <div className={stylesThemes.Controls}>
        <IconButton
          data-test-id="reporting__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="save"
          iconSize={24}
          label="Save"
          type="submit"
        />
        {!isNewProvider && (
          <IconButton
            data-test-id="reporting__button--delete"
            iconColor="radical"
            iconName="delete"
            iconSize={24}
            label="Delete"
            onClick={handleDelete}
            type="button"
          />
        )}
        <IconButton
          iconColor="secondary"
          iconName="close"
          iconSize={24}
          onClick={onClose}
        />
      </div>
    ),
    [isDirty, isNewProvider, handleDelete, onClose],
  );

  const onSubmit = async (values: IReportingFormValues) => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id } = values;
      const response = isNewProvider
        ? await symbolDBService().createReportingProvider(values)
        : await symbolDBService().updateReportingProvider(values, _id);
      if (response) {
        Notification.success({
          title: isNewProvider
            ? SuccessMessages.Create
            : SuccessMessages.Update,
        });
        onUpdateProviders();
        onClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  };

  const renderControl = (key: keyof IReportingFormValues): ReactNode => {
    const prop = getProp(provider, key);

    if (typeof prop === 'object' && !Array.isArray(prop) && prop !== null) {
      return Object.keys(prop).map((k) => {
        const nestedKey = `${key}.${k}`;

        return renderControl(nestedKey as keyof IReportingFormValues);
      });
    }

    if (EXCLUDED_FIELDS.includes(key)) {
      return null;
    }

    const isDisabled = !EDITABLE_FIELDS.includes(key);

    if (typeof prop === 'boolean') {
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          defaultValue={prop}
          render={({ field }) => {
            return (
              <Checkbox
                disabled={isDisabled}
                checked={field.value as boolean}
                label={key}
                {...field}
                sx={{ ml: '1px', mt: '16px' }}
              />
            );
          }}
        />
      );
    }

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            disabled={isDisabled}
            error={Boolean(errors[key])}
            fullWidth
            label={key}
            {...field}
            sx={{ mt: '16px', width: '50%' }}
          />
        )}
      />
    );
  };

  const orderedFields = useMemo(
    () =>
      orderBy(Object.keys(provider), (name) => indexOf(FIXED_ORDER, name), [
        'desc',
      ]),
    [provider],
  );

  return (
    <form className={styles.Wrapper} onSubmit={handleSubmit(onSubmit)}>
      <Panel title={provider?.name || title} action={controls}>
        <div className={styles.Fields}>
          {(orderedFields as Array<keyof typeof provider>).map(renderControl)}
        </div>
      </Panel>
    </form>
  );
};
