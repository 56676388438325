import styled from 'styled-components';

export const SwitchFieldContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  line-height: 1.4;
  user-select: none;
`;

export const SwitchFieldActions = styled.div<{ active: boolean }>`
  align-items: center;
  color: ${({ active, theme }) =>
    active ? theme.color.typo.source : theme.color.typo.secondary};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  gap: 6px;

  &:hover {
    text-decoration: underline;
  }
`;
