import { DateObject } from '~/types/shared';
import { trimLowerCase } from '~/utils/string';

import { IDMaps, ValueConfig } from '../../types';
import { EXCLUDED_ENTITIES_SET, FIXED_ENTITIES_MAP } from '../constants';

const getValueTitle = (
  path: string,
  type: ValueConfig['type'],
  value: unknown,
  idMaps: IDMaps,
): string => {
  let [root, ...parts] = path.split('.');

  if (EXCLUDED_ENTITIES_SET.has(root)) {
    [root, ...parts] = parts;
  }

  const normalized = FIXED_ENTITIES_MAP.get(root);

  const label = [normalized || trimLowerCase(root), ...parts].join(' : ');

  switch (type) {
    case 'array':
      return Array.isArray(value)
        ? `${label} : ${(value as string[]).join(', ')}`
        : label;

    case 'executionSchemeID':
      return `${label} : ${idMaps.executionSchemes.get(value as string)}`;

    case 'number':
    case 'string':
      return `${label} : ${value as string}`;

    case 'json':
      return `${label} : JSON`;

    case 'date': {
      const { day, month, year } = value as DateObject;
      return `${label} : ${
        day ? `${day}/${month}/${year}` : `${month}/${year}`
      }`;
    }

    default:
      return label;
  }
};

export default getValueTitle;
