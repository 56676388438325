import { useCallback, useState, useMemo, useEffect } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';
import { addToObject } from '../../utils/addToObject';

import { SuccessMessages } from './constants';
import { IFormValues, ITheme } from './types';

const initialValue = {
  weight: 0,
  visible: false,
  type: '',
  title: {
    key: '',
    default: '',
  },
  themePic: '',
  taglist: [],
  subTitle: {
    key: '',
    default: '',
  },
  popular: false,
  name: '',
  highlight: false,
  description: {
    key: '',
    default: '',
  },
  brandings: [],
  backgroundColour: '#FFFFFF',
};

export const useSDBThemes = () => {
  const [themes, setThemes] = useState<Array<ITheme>>([]);
  const [isThemesLoading, setIsThemesLoading] = useState<boolean>(false);
  const [theme, setTheme] = useState<IFormValues | typeof initialValue>(
    initialValue,
  );
  const [isShowTheme, setIsShowTheme] = useState<boolean>(false);
  const [isNewTheme, setIsNewTheme] = useState<boolean>(false);

  const getSingleTheme = useCallback(async (id?: string) => {
    try {
      const response = await symbolDBService().getSingleTheme(id);

      if (typeof response.type !== 'string') {
        const data = addToObject(response, 'type', '', 2);

        setTheme({ ...data, weight: data.weight.toString() });
      } else {
        setTheme({ ...response, weight: response.weight.toString() });
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
    setIsNewTheme(false);
    setIsShowTheme(true);
  }, []);

  const createNewTheme = useCallback(() => {
    setTheme(initialValue);
    setIsNewTheme(true);
    setIsShowTheme(true);
  }, []);

  const resetThemeState = useCallback(() => {
    setIsShowTheme(false);
    setTheme(initialValue);
  }, []);

  const deleteTheme = useCallback(async (id: string) => {
    try {
      const response = await symbolDBService().deleteTheme(id);
      if (response) {
        Notification.success({ title: SuccessMessages.Delete });
        setIsShowTheme(false);
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, []);

  const getThemes = async () => {
    try {
      setIsThemesLoading(true);
      const response = await symbolDBService().getThemes();
      setThemes(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsThemesLoading(false);
    }
  };

  const extractBrandings = (items: Array<ITheme>) => [
    ...new Set(
      items.reduce<Array<string>>(
        (acc, next) => [...acc, ...next.brandings],
        [],
      ),
    ),
  ];

  const extractTags = (items: Array<ITheme>) => [
    ...new Set(
      items.reduce<Array<string>>((acc, next) => [...acc, ...next.taglist], []),
    ),
  ];

  useEffect(() => {
    (async () => {
      await getThemes();
    })();
  }, []);

  return useMemo(
    () => ({
      themes,
      isThemesLoading,
      theme,
      isNewTheme,
      isShowTheme,
      brandings: extractBrandings(themes),
      tags: extractTags(themes),
      handlers: {
        createNewTheme,
        getSingleTheme,
        resetThemeState,
        deleteTheme,
        getThemes,
      },
    }),
    [
      themes,
      isThemesLoading,
      theme,
      isNewTheme,
      isShowTheme,
      createNewTheme,
      deleteTheme,
      resetThemeState,
      getSingleTheme,
    ],
  );
};
