import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

export const DefaultSequenceRow = styled.div`
  display: flex;
  gap: 12px;

  & > div:first-child {
    flex: 1 1 auto;
  }

  & > div:nth-child(2) {
    flex: 0 0 50%;
  }
`;

export const SequenceRow = styled.div`
  display: flex;
  gap: 12px;

  & > div {
    flex: 0 0 auto;
  }
`;
