import { FC, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { CodeEditor } from '../../../components/CodeEditor/CodeEditor';

import { EditorProps } from './Editor.types';

export const Editor: FC<EditorProps> = ({ name, error }) => {
  const formContext = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error: fieldError },
  } = useController({
    control: formContext.control,
    name,
  });

  formContext.register(name);

  const annotations = useMemo(() => {
    if (error) {
      return [
        {
          row: error.lineno - 1,
          type: 'error',
          text: error.error,
        },
      ];
    }

    if (fieldError) {
      return [
        {
          row: 0,
          type: 'error',
          text: fieldError.message || '',
        },
      ];
    }

    return [];
  }, [error, fieldError]);

  return (
    <CodeEditor
      mode="json"
      onChange={onChange}
      name="static-data-config-editor"
      value={value}
      height="770px"
      annotations={annotations}
    />
  );
};
