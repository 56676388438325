import { Instrument } from '~/types/models';

import { apiService } from './api/api.service';
import { getBOBaseUrl } from './api/helpers';
import { SESSION_ID_COOKIE_KEY } from './authorization/authorization.consts';
import { Endpoint } from './constants';
import { getSessionIdFromCookie } from './cookies/cookies.service';

const BOAPI = apiService(getBOBaseUrl());

export const BODataService = () => {
  const getInstrumentForm = async (id: string): Promise<Instrument> => {
    const { data } = await BOAPI.get({
      endPoint: `${Endpoint.ApiInstruments}/${id}`,
      sessionToken: getSessionIdFromCookie(SESSION_ID_COOKIE_KEY) || '',
    });

    return data.data;
  };

  const getCurrency = async () => {
    try {
      const result = await BOAPI.get({
        endPoint: `/api${Endpoint.Currencies}`,
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const registerSession = async () => {
    try {
      const result = await BOAPI.get({
        endPoint: `/api${Endpoint.RegisterSession}`,
        sessionToken: getSessionIdFromCookie(SESSION_ID_COOKIE_KEY) || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  return {
    getInstrumentForm,
    getCurrency,
    registerSession,
  };
};
