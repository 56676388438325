import styled from 'styled-components';

import { Theme } from '~/theme';

import { Color } from './types';

type ContainerProps = {
  color?: Color;
};

const getContainerColor = (
  theme: Theme,
  color: ContainerProps['color'] = 'default',
): string => {
  switch (color) {
    case 'green':
      return '#409F6B';

    case 'red':
      return '#BF3B4B';

    case 'orange':
      return '#ff9800';

    default:
      return '#999999';
  }
};

export const Container = styled.div<ContainerProps>`
  color: ${({ theme }) => theme.color.typo.inverse};
  background-color: ${(props) => getContainerColor(props.theme, props.color)};
  font-size: 13px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.typo.inverse};
  border-radius: 4px;
  padding: 0 8px;
  white-space: nowrap;
  cursor: default;
`;
