const isInsideRun = () => window.runUIhistoryContainer && window.WEB_BO_UI;

const getWarnMsg = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] WEB-BO application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getBaseBackOfficeUrl = () => {
  if (isInsideRun()) {
    const url = window.WEB_BO_UI?.api_urls?.BO;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseBackOfficeUrl',
          'window.WEB_BO_UI.api_urls.BO',
          'process.env.REACT_APP_API_BO',
        ),
      );

      return process.env.REACT_APP_API_BO;
    }

    return url;
  }

  return process.env.REACT_APP_API_BO;
};

export const getBaseBackOfficeSwaggerUrl = () => {
  if (isInsideRun()) {
    const url = window.WEB_BO_UI?.api_urls?.BO_SWAGGER;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseBackOfficeSwaggerUrl',
          'window.WEB_BO_UI.api_urls.BO_SWAGGER',
          'process.env.REACT_APP_API_BO_SWAGGER',
        ),
      );
      return process.env.REACT_APP_API_BO_SWAGGER;
    }

    return url;
  }

  return process.env.REACT_APP_API_BO_SWAGGER;
};

export const getBaseWorkflowUrl = () => {
  if (isInsideRun()) {
    const url = window.WEB_BO_UI?.api_urls?.WFE;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseWorkflowUrl',
          'window.WEB_BO_UI.api_urls.WFE',
          'process.env.REACT_APP_API_WFE',
        ),
      );
      return process.env.REACT_APP_API_WFE;
    }

    return url;
  }

  return process.env.REACT_APP_API_WFE;
};

export const getBaseAuditLogUrl = () => {
  if (isInsideRun()) {
    const url = window.WEB_BO_UI?.api_urls?.AUDIT_LOG;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseAuditLogUrl',
          'window.WEB_BO_UI.api_urls.AUDIT_LOG',
          'process.env.REACT_APP_API_AUDIT_LOG',
        ),
      );
      return process.env.REACT_APP_API_AUDIT_LOG;
    }

    return url;
  }

  return process.env.REACT_APP_API_AUDIT_LOG;
};
