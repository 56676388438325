import { InstrumentData, InstrumentFormState } from './types';

export const defaultValues: InstrumentData = {
  _creationTime: '',
  _id: '',
  _lastUpdateTime: '',
  _rev: '',

  path: [],
  isAbstract: false,
  isTrading: false,
  name: '',
};

export const initialState: InstrumentFormState = {
  parents: [],
  values: defaultValues,
  ref: defaultValues,

  errors: new Map([]),

  ready: false,
  dirty: false,

  convertStatus: {
    error: false,
    pending: false,
    succeed: false,
  },

  fetchStatus: {
    error: null,
    pending: false,
    succeed: false,
  },

  saveStatus: {
    error: false,
    pending: false,
    succeed: false,
  },

  deleteStatus: {
    error: false,
    pending: false,
    succeed: false,
  },
};
