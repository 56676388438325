import { TextInput } from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const ProxyProperties = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>Proxy properties</SectionTitle>
      <TextInput
        path={`feeds.providerOverrides.${id}.proxyProperties.feedSource`}
      >
        Feed source
      </TextInput>
    </Section>
  );
};

export default ProxyProperties;
