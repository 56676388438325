import { useReducer } from 'react';

import { initialFilterState } from '../constants';
import { FilterReducer, OrderBy } from '../types';

const reducer: FilterReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_EXPIRED': {
      return {
        ...state,
        expired: !state.expired,
      };
    }

    case 'CHANGE_TRADING': {
      return {
        ...state,
        trading: !state.trading,
      };
    }

    case 'CHANGE_ORDER_BY': {
      return {
        ...state,
        orderBy: action.payload,
      };
    }

    case 'SET_SEARCH': {
      return {
        ...state,
        search: action.payload,
      };
    }

    case 'RESET': {
      return initialFilterState;
    }

    default:
      return state;
  }
};

const useFilter = () => {
  const [state, dispatch] = useReducer<FilterReducer>(
    reducer,
    initialFilterState,
  );

  return {
    state,
    onExpiredChange: () => dispatch({ type: 'CHANGE_EXPIRED' }),
    onTradingChange: () => dispatch({ type: 'CHANGE_TRADING' }),
    onOrderByChange: (payload: OrderBy = initialFilterState.orderBy) =>
      dispatch({ type: 'CHANGE_ORDER_BY', payload }),
    onSearchChange: (payload: string) =>
      dispatch({ type: 'SET_SEARCH', payload }),
    onReset: () => dispatch({ type: 'RESET' }),
  };
};

export default useFilter;
