import axios, { AxiosInstance, AxiosPromise, Method } from 'axios';
import * as cookies from 'js-cookie';

import { SESSION_GOOGLE_COOKIE_KEY } from '../authorization/authorization.consts';

import { Headers, Methods } from './constants';
import { paramsSerializer } from './helpers';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 150000;

type TOptions = {
  contentType?: string;
  data?: any;
  method?: Method;
  endPoint?: string;
  url?: string;
  headers?: any;
  sessionToken?: string;
  params?: any;
};

export const apiService = (baseURL: string, service: AxiosInstance = axios) => {
  const getHeaders = (contentType: string, sessionToken?: string) => {
    const headers: any = {
      [Headers.CONTENT_TYPE]: contentType,
    };

    if (sessionToken) {
      headers[Headers.X_AUTH_SESSIONID] = sessionToken;
    }

    const accessToken = cookies.get(SESSION_GOOGLE_COOKIE_KEY);

    if (accessToken) {
      try {
        const { access_token: token } = JSON.parse(accessToken);

        headers[Headers.AUTHORIZATION] = `Bearer ${token}`;
      } catch (error) {
        console.error(`Access token error: ${error}`);
      }
    }

    return headers;
  };

  const request: (options: TOptions) => AxiosPromise = ({
    contentType = 'application/json',
    endPoint = '',
    headers,
    method,
    sessionToken,
    url = baseURL,
    ...options
  }) =>
    service({
      url: `${url}${endPoint}`,
      method,
      headers: {
        ...getHeaders(contentType, sessionToken),
        ...headers,
      },
      ...options,
      paramsSerializer,
    });

  const get: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.GET,
    });

  const head: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.HEAD,
    });

  const post = (options: TOptions) =>
    request({ method: Methods.POST, ...options });
  const put = (options: TOptions) =>
    request({ method: Methods.PUT, ...options });
  const patch = (options: TOptions) =>
    request({ method: Methods.PATCH, ...options });

  return {
    delete: (options: TOptions) =>
      request({ method: Methods.DELETE, ...options }),
    get,
    post,
    put,
    patch,
    head,
  };
};
