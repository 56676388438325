import { useContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SortableStackValue } from '~/components';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import { InstrumentFormProps } from '../../../../types';
import { InstrumentFormContext } from '../../../../useForm';
import { InstrumentFormActions } from '../../../../useForm/actions';
import { getFeedsProvider } from '../../services';
import { hasInheritedValues, hasSelfValues } from '../../utils';

const useFeedsList = (dependencies: InstrumentFormProps['dependencies']) => {
  const navigate = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(InstrumentFormContext);

  const feeds = getFeedsProvider(state, dependencies);

  const value = useMemo(
    () =>
      feeds.map<SortableStackValue<string>>((item) => ({
        key: item.id,
        label: item.name,
        isBold: hasSelfValues(item, id),
        withArrow: hasInheritedValues(item, id),
        providerType: item.providerType,
      })),
    [feeds, id],
  );

  const options = useMemo(() => {
    if (!dependencies.feedProviders) {
      return [];
    }

    return dependencies.feedProviders.map((result) => ({
      key: result._id,
      label: result.name,
    }));
  }, [dependencies.feedProviders]);

  const selected = useMemo(() => {
    const idString = pathname.split('/').pop();

    return idString === 'provider' ? undefined : idString;
  }, [pathname]);

  const handleChange = (_: SortableStackValue[], key: string) => {
    dispatch({
      type: InstrumentFormActions.AppendOverride,
      payload: {
        key,
        path: 'feeds.providerOverrides',
        data: {
          order: value.length,
        },
      },
    });

    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/feeds/provider/${key}`);
  };

  const handleSelect = (key: string): void => {
    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/feeds/provider/${key}`);
  };

  return {
    value,
    options,
    selected,
    handleChange,
    handleSelect,
  };
};

export default useFeedsList;
