import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchLegacyInstrument } from '~/api/SymbolDBService';
import { LegacyInstrumentResponse } from '~/types/models';

import { initialState } from '../TreeContext/constants';
import type { TreeState } from '../TreeContext/types';

import type { TreeRouteParams } from './types';

const useTree = () => {
  const { id } = useParams<TreeRouteParams>();
  const [state, setState] = useState<LegacyInstrumentResponse | null>(null);

  const instrument = useMemo<TreeState>(() => {
    if (state?.length) {
      const [mainInstrument, parentInstrument] = state;

      return {
        exchangeId: mainInstrument.exchangeId || '',
        id: mainInstrument._id,
        name: mainInstrument.name,
        type: mainInstrument.type || parentInstrument?.type || '',
      };
    }

    return initialState;
  }, [state]);

  const fetchTreeValues = async () => {
    try {
      const response = await fetchLegacyInstrument(id);

      if (response.length > 0) {
        setState(response);
      } else {
        setState(null);
      }
    } catch (e) {
      setState(null);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTreeValues();
    }
  }, [id]);

  return {
    instrument,
  };
};

export default useTree;
