import axios from 'axios';
import { Notification } from 'react-ui-kit-exante';

import { ErrorMessage, ErrorMetadata } from '../types/shared';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong!';

export const prepareMessage = (message: ErrorMessage): string => {
  let formattedMessage = '';

  if (typeof message === 'object') {
    formattedMessage = JSON.stringify(message);
  }

  if (typeof message === 'string') {
    formattedMessage = message;
  }

  return formattedMessage;
};

export const formatErrorForKitNotification = ({
  statusCode,
  message,
}: ErrorMetadata): string[] => {
  const notification = [];

  if (statusCode) {
    notification.push(`Status code: ${statusCode}`);
  }

  if (message) {
    const preparedMessage = prepareMessage(message);

    notification.push(preparedMessage);
  }

  return notification;
};
export const transformErrorToInfoObject = (
  error: unknown,
): ErrorMetadata | null => {
  if (axios.isAxiosError(error)) {
    const response = error.response;

    if (response) {
      const { data, request } = response;

      return {
        statusCode: data.statusCode,
        message: data.message,
        url: request.responseURL,
      };
    }
  }

  if (error instanceof Error) {
    return { message: `${error.name}: ${error.message}` };
  }

  return null;
};

export const sendUiKitErrorNotification = (error: unknown) => {
  if (!Notification) {
    return;
  }

  const errorInfo = transformErrorToInfoObject(error);

  if (!errorInfo || (!errorInfo.message && !errorInfo.statusCode)) {
    Notification.error({
      title: DEFAULT_ERROR_MESSAGE,
    });

    return;
  }

  const [title, description] = formatErrorForKitNotification(errorInfo);

  Notification.error({ title, description });
};
