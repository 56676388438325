import { InstrumentFormState } from '../types';

const onSaveStart = (state: InstrumentFormState): InstrumentFormState => ({
  ...state,

  saveStatus: {
    succeed: false,
    pending: true,
    error: false,
  },
});

export default onSaveStart;
