import {
  BoolRow,
  BoolTable,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';
import { ReutersProperties } from '~/types/models';

import { Actions, Section, SectionTitle } from '../../../../styled';

import { SwitchField } from './SwitchField';
import { SwitchFieldTypes } from './constants';
import { RicProps } from './types';
import useRic from './useRic';

const Ric = ({ id, title, type }: RicProps) => {
  const { reutersProperties } = useRic(id);

  if (
    typeof reutersProperties?.[type as keyof ReutersProperties] === 'string'
  ) {
    return (
      <div>
        <SwitchField
          path={`feeds.providerOverrides.${id}.reutersProperties.${type}`}
          switchField={SwitchFieldTypes.TEXT_FIELD}
        />
        <TextInput
          path={`feeds.providerOverrides.${id}.reutersProperties.${type}`}
        >
          {title}
        </TextInput>
      </div>
    );
  }

  return (
    <Section>
      <Actions
        style={{
          justifyContent: 'space-between',
        }}
      >
        <SectionTitle>{title}</SectionTitle>
        <SwitchField
          path={`feeds.providerOverrides.${id}.reutersProperties.${type}`}
          switchField={SwitchFieldTypes.OBJECT}
        />
      </Actions>
      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.${type}.base`}
      >
        Base
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.${type}.suffix`}
      >
        Suffix
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.${type}.optionSeparator`}
      >
        Option separator
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.${type}.nearLegPrefix`}
      >
        Near leg prefix
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.${type}.farLegPrefix`}
      >
        Far leg prefix
      </TextInput>
      <BoolTable>
        <BoolRow
          path={`feeds.providerOverrides.${id}.reutersProperties.${type}.truncateStrikePrice`}
        >
          Truncate strike price
        </BoolRow>
      </BoolTable>
      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.${type}.strikePriceFormat`}
      >
        Strike price format
      </TextInput>
    </Section>
  );
};

export default Ric;
