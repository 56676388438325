import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { TDefaultTheme } from 'react-ui-kit-exante';

export const StyledLink = styled(Link)(({ theme }: TDefaultTheme) => ({
  color: theme?.color.typo?.action,
  textDecoration: 'none',
  cursor: 'pointer',
  marginRight: '6px',
  marginLeft: '6px',
}));
