import { useCallback, useState } from 'react';

import { CURRENCIES } from '../../../services/constants';
import { LOCAL_STORAGE_KEY } from '../constants';
import { Currency } from '../types';

const isCurrency = (str: string): str is Currency =>
  CURRENCIES.some((cr) => cr === str);

const getInitialState = (): Currency | null => {
  const lsState = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (typeof lsState === 'string' && isCurrency(lsState)) {
    return lsState;
  }
  return null;
};

export const useActiveCurrency = () => {
  const [activeCurrency, setActiveCurrency] = useState<Currency | null>(() =>
    getInitialState(),
  );
  const onChangeActiveCurrency = useCallback((currency: Currency | null) => {
    if (currency === null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      setActiveCurrency(null);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, currency);
      setActiveCurrency(currency);
    }
  }, []);

  return { activeCurrency, onChangeActiveCurrency };
};
