/* eslint-disable no-underscore-dangle */

import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '../../../hooks/useFetchStatus';
import { symbolDBService } from '../../../services/symbolDB.service';
import { SchedulesResponseItem } from '../types';

import useSchedulesRouting from './useSchedulesRouting';

const useSchedulesList = () => {
  const [schedules, setSchedules] = useState<Array<SchedulesResponseItem>>([]);
  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();
  const { handleOpenSchedule, handleCloseScheduleForm } = useSchedulesRouting();

  const setSchedulesOptions = async (): Promise<void> => {
    fetchingStatusActions.handleStart();

    try {
      const data = await symbolDBService().getSchedules();

      setSchedules(data);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  const handleSuccessCreate = (payload: SchedulesResponseItem) => {
    setSchedules([payload, ...schedules]);
    handleOpenSchedule(payload._id);
  };

  const handleSuccessUpdate = (payload: SchedulesResponseItem) => {
    const existIndex = schedules.findIndex(
      (schedule) => schedule._id === payload._id,
    );

    if (existIndex !== -1) {
      setSchedules([
        ...schedules.slice(0, existIndex),
        payload,
        ...schedules.slice(existIndex + 1),
      ]);
    }
  };

  const handleSuccessDelete = (id: string) => {
    const existIndex = schedules.findIndex((schedule) => schedule._id === id);

    if (existIndex !== -1) {
      handleCloseScheduleForm();
      setSchedules([
        ...schedules.slice(0, existIndex),
        ...schedules.slice(existIndex + 1),
      ]);
    }
  };

  useEffect(() => {
    setSchedulesOptions();
  }, []);

  return {
    schedules,
    isLoading: fetchingStatus.isPending && !fetchingStatus.isSucceed,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  };
};

export default useSchedulesList;
