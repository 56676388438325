import { InfluxProxyRepository } from './influxProxy.repository';
import { ILogHandleTimeRequest, Logger } from './types';

export class InfluxProxyService {
  public logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger;
  }

  public async postLogHandleTime(data: ILogHandleTimeRequest) {
    try {
      InfluxProxyRepository.fetchLogHandleTime(data);
    } catch (error) {
      this.logger.error(error);
    }
  }
}
