import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/i/Close.svg';
import { ReactComponent as TrashIcon } from '../../assets/i/Trash.svg';
import { Button } from '../Button';
import Modal from '../Modal/Modal';
import { PromptDialog } from '../PromptDialog';

import { DeleteDialogProps } from './types';

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isShown,
  isLoading,
  onConfirm,
  onDecline,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleDecline = () => {
    if (onDecline) {
      onDecline();
    }
  };

  if (!isShown) {
    return null;
  }

  return (
    <Modal>
      <PromptDialog
        confirmButton={
          <Button
            isLoading={isLoading}
            icon={<TrashIcon />}
            onClick={handleConfirm}
          >
            Delete
          </Button>
        }
        declineButton={<Button icon={<CloseIcon />} onClick={handleDecline} />}
      >
        Are you sure want delete?
      </PromptDialog>
    </Modal>
  );
};

export default DeleteDialog;
