import { ValueConfig } from '../types';

const BROKERS_PROVIDER_OVERRIDES: ValueConfig[] = [
  {
    path: 'anyTimeTriggering',
    type: 'boolean',
  },
  {
    path: 'emulateDay',
    type: 'boolean',
  },
  {
    path: 'emulateGtc',
    type: 'boolean',
  },
  {
    path: 'emulateMarket',
    type: 'boolean',
  },
  {
    path: 'emulateStop',
    type: 'boolean',
  },
  {
    path: 'isShortSaleAvailable',
    type: 'boolean',
  },
  {
    path: 'isTrading',
    type: 'boolean',
  },
  {
    path: 'buyMarkup',
    type: 'number',
  },
  {
    path: 'contractMultiplier',
    type: 'number',
  },
  { path: 'legGap', type: 'number' },
  { path: 'markup', type: 'number' },
  {
    path: 'minLotSize',
    type: 'number',
  },
  {
    path: 'priceMultiplier',
    type: 'number',
  },
  {
    path: 'sellMarkup',
    type: 'number',
  },
  {
    path: 'strikePriceMultiplier',
    type: 'number',
  },
  {
    path: 'volumeMultiplier',
    type: 'number',
  },
  { path: 'currency', type: 'string' },
  {
    path: 'exchangeName',
    type: 'string',
  },
  {
    path: 'executionSchemeId',
    type: 'executionSchemeID',
  },
  {
    path: 'symbolIdOverride',
    type: 'string',
  },
  {
    path: 'symbolName',
    type: 'string',
  },
  {
    path: 'markupUnit',
    type: 'string',
  },
  {
    path: 'markupReporting.account',
    type: 'string',
  },
  {
    path: 'markupReporting.user',
    type: 'string',
  },
  {
    path: 'maturityDate',
    type: 'date',
  },
  {
    path: 'replaceMode',
    type: 'string',
  },
  {
    path: 'spreadType',
    type: 'string',
  },
  {
    path: 'symbolIdentifier.identifier',
    type: 'string',
  },
  {
    path: 'symbolIdentifier.type',
    type: 'string',
  },
];

export default BROKERS_PROVIDER_OVERRIDES;
