import { mapValues } from 'lodash';
import { lazy, object, ObjectShape, number, string } from 'yup';

import { TIME_MASK } from '~/constants/regexp';

export const RecordSchema = <T>(s: ObjectShape) =>
  lazy((obj) => object(mapValues(obj, () => object<T>(s))));

export const EmptyNumber = (
  msg: string,
  min?: { value: number; msg: string },
  max?: { value: number; msg: string },
) =>
  lazy((value) => {
    if (value === '') {
      return string();
    }

    if (min && max) {
      return number()
        .typeError(msg)
        .min(min.value, min.msg)
        .max(max.value, max.msg);
    }

    if (min) {
      return number().typeError(msg).min(min.value, min.msg);
    }

    return number().typeError(msg);
  });

export const EmptyTime = (msg: string) =>
  lazy((value) => (value === '' ? string() : string().matches(TIME_MASK, msg)));
