import * as yup from 'yup';

const brokerItemSchema = yup.object({
  brokerId: yup.string().required('Broker is required field'),
  override: yup.string().required('Override name is required field'),
});

const feedItemSchema = yup.object({
  brokerId: yup.string().required('Feed is required field'),
  override: yup.string().required('Override name is required field'),
});

const sectionItemSchema = yup.object({
  name: yup.string().required('Name is required field'),
  description: yup.string(),
  scheduleId: yup.string(),
  submarket: yup.string(),
  brokers: yup.array().of(brokerItemSchema),
  feeds: yup.array().of(feedItemSchema),
});

const formSchema = yup.object({
  name: yup.string().required('Name is required field'),
  exchangeName: yup.string().required('Exchange Name is required field'),
  treeName: yup.string(),
  description: yup.string(),
  figi: yup.string(),
  mic: yup.string(),
  brokers: yup.array().of(brokerItemSchema),
  feeds: yup.array().of(feedItemSchema),
  sections: yup.array().of(sectionItemSchema),
});

export default formSchema;
