import { useMemo } from 'react';

import { useAsyncRequest } from '~/hooks';
import { symbolDBService } from '~/services/symbolDB.service';

import {
  getCurrenciesOptions,
  getExchangeOptions,
  getSchedulesOptions,
  getSectionsOptions,
} from './utils';

const useDependencies = () => {
  const [, executionSchemesStatus, executionSchemes] = useAsyncRequest(
    symbolDBService().getExecutionSchemes,
  );

  const [, sectionsStatus, sections] = useAsyncRequest(
    symbolDBService().getSections,
  );

  const [, currenciesStatus, currencies] = useAsyncRequest(
    symbolDBService().getCurrencies,
  );

  const [, tasksStatus, tasks] = useAsyncRequest(symbolDBService().getTasks);

  const [, schedulesStatus, schedules] = useAsyncRequest(
    symbolDBService().getSchedules,
  );

  const [, feedProvidersStatus, feedProviders] = useAsyncRequest(
    symbolDBService().getFeedProviders,
  );

  const [, brokerProvidersStatus, brokerProviders] = useAsyncRequest(
    symbolDBService().getBrokerProviders,
  );

  const [, feedGatewaysStatus, feedGateways] = useAsyncRequest(
    symbolDBService().getFeedGateways,
  );

  const [, exchangesStatus, exchanges] = useAsyncRequest(
    symbolDBService().getExchanges,
  );

  const [, brokerAccountsStatus, brokerAccounts] = useAsyncRequest(
    symbolDBService().getBrokerAccounts,
  );

  const [, legalEntitiesStatus, legalEntities] = useAsyncRequest(
    symbolDBService().getLegalEntities,
  );

  const pending = [
    brokerAccountsStatus.pending,
    currenciesStatus.pending,
    exchangesStatus.pending,
    executionSchemesStatus.pending,
    feedGatewaysStatus.pending,
    feedProvidersStatus.pending,
    brokerProvidersStatus.pending,
    legalEntitiesStatus.pending,
    schedulesStatus.pending,
    sectionsStatus.pending,
    tasksStatus.pending,
  ].some(Boolean);

  const succeed = [
    brokerAccountsStatus.succeed,
    currenciesStatus.succeed,
    exchangesStatus.succeed,
    executionSchemesStatus.succeed,
    feedGatewaysStatus.succeed,
    feedProvidersStatus.succeed,
    brokerProvidersStatus.succeed,
    legalEntitiesStatus.succeed,
    schedulesStatus.succeed,
    sectionsStatus.succeed,
    tasksStatus.succeed,
  ].every(Boolean);

  const error = [
    brokerAccountsStatus.error,
    currenciesStatus.error,
    exchangesStatus.error,
    executionSchemesStatus.error,
    feedGatewaysStatus.error,
    feedProvidersStatus.error,
    brokerProvidersStatus.error,
    legalEntitiesStatus.error,
    schedulesStatus.error,
    sectionsStatus.error,
    tasksStatus.error,
  ].some(Boolean);

  const options = {
    currency: useMemo(() => getCurrenciesOptions(currencies), [currencies]),
    exchange: useMemo(() => getExchangeOptions(exchanges), [exchanges]),
    schedule: useMemo(() => getSchedulesOptions(schedules), [schedules]),
    section: useMemo(() => getSectionsOptions(sections), [sections]),
  };

  const dependencies = {
    brokerAccounts,
    currencies,
    exchanges,
    executionSchemes,
    feedGateways,
    feedProviders,
    brokerProviders,
    legalEntities: legalEntities?.values,
    schedules,
    sections,
    tasks,
  };

  return {
    dependencies,
    options,

    status: {
      pending,
      succeed,
      error,
    },
  };
};

export default useDependencies;
