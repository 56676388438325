import React, { useEffect, useState } from 'react';
import { SearchInput } from 'react-ui-kit-exante';

import { useDebounce } from '~/hooks';

import { DebounceSearchProps } from './types';

const DebounceSearch = ({
  onChange,
  debounce,
  initialValue,
  minChars = 0,
}: DebounceSearchProps) => {
  const [error, setError] = useState(false);

  const [searchString, setSearchString] = useState(initialValue || '');
  const debouncedSearch = useDebounce(searchString, debounce);

  useEffect(() => {
    if (minChars > 0 && debouncedSearch.length) {
      if (debouncedSearch.length >= minChars) {
        onChange(debouncedSearch);
        setError(false);
      } else {
        setError(true);
      }
    } else {
      onChange(debouncedSearch);
      setError(false);
    }
  }, [debouncedSearch, minChars]);

  return (
    <SearchInput
      onChange={setSearchString}
      inputProps={{
        value: searchString,
        error,
        message: error ? `Enter at least ${minChars} symbols for search` : '',
      }}
    />
  );
};

export default DebounceSearch;
