export const PROVIDER_TYPE = [
  'dxfeedProperties',
  'generatorSettings',
  'httpProperties',
  'idcProperties',
  'lambdaSettings',
  'nanexProperties',
  'proxyProperties',
  'reutersProperties',
  'syntheticSettings',
  'zeusCalcData',
];
