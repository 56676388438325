import { AxiosError } from 'axios';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getInstruments, getInstrumentsTree } from '~/api';
import { useFetchStatus } from '~/hooks';
import { findTreeItem, findTreeItemPath, getFlatTreeNodes } from '~/utils/tree';

import {
  DEFAULT_ORDER,
  NODES_REQUEST_LIMIT,
  SEARCH_MIN_CHARS_LENGTH,
} from '../constants';
import { getUnifiedTreeItem } from '../transformers';
import { FilterState, UnifiedTreeItem } from '../types';
import {
  getBaseParams,
  getID,
  getLoadableNodes,
  getPagedNodes,
  getParentId,
  insertTreeNodes,
  setFetchButtonCount,
  setFetchButtonLoading,
  setNodeLoading,
  setNodePlaceholder,
} from '../utils';

const useTree = (filter: FilterState) => {
  const nav = useNavigate();

  const { pathname } = useLocation();

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState('');

  const [data, setData] = useState<UnifiedTreeItem[]>([]);
  const [status, { handleStart, handleSuccess, handleError }] =
    useFetchStatus();

  const id = useMemo(() => getID(pathname), [pathname]);

  const loading = useMemo(
    () => status.isPending && !status.isSucceed,
    [status],
  );

  const request = useCallback(async (): Promise<UnifiedTreeItem[]> => {
    const params = getBaseParams(filter);

    if (filter.search && filter.search.length > SEARCH_MIN_CHARS_LENGTH) {
      const { data: response } = await getInstrumentsTree({
        order: DEFAULT_ORDER,
        orderBy: filter.orderBy,
        search: filter.search,
        ...params,
      });

      const result = response.map(getUnifiedTreeItem);

      const expandedPath = getFlatTreeNodes<UnifiedTreeItem>(
        result,
        'nodes',
        (item) => item.id,
      );

      setExpanded(expandedPath);

      return result;
    }

    if (id) {
      const { data: response } = await getInstrumentsTree({
        order: DEFAULT_ORDER,
        orderBy: filter.orderBy,
        pathTo: id,
        ...params,
      });

      const result = response.map(getUnifiedTreeItem);
      const finder = findTreeItemPath<UnifiedTreeItem>(
        'nodes',
        (item) => item.id === id,
        (item) => item.id,
      );

      const nodePath = finder(result);
      setExpanded(nodePath || []);

      const { data: instruments, pagination } = await getInstruments({
        pid: id,
        order: 'asc',
        orderBy: filter.orderBy,
        search: filter.search,
        limit: NODES_REQUEST_LIMIT,
        skip: 0,
      });

      return insertTreeNodes(
        id,
        result,
        getLoadableNodes(instruments, pagination.total),
      );
    }

    const { data: response, pagination } = await getInstruments({
      pid: null,
      order: 'asc',
      orderBy: filter.orderBy,
      limit: NODES_REQUEST_LIMIT,
      skip: 0,
    });

    return getLoadableNodes(response, pagination.total);
  }, [filter, id]);

  const handleRefresh = async () => {
    handleStart();

    try {
      const response = await request();

      setData(response);
      handleSuccess();
    } catch (e) {
      handleError(e as AxiosError);
      setData([]);
    }
  };

  const handleSelectNode = async (
    event: SyntheticEvent | null,
    pid: string,
  ) => {
    const finder = findTreeItem<UnifiedTreeItem>(
      'nodes',
      (item) => item.id.toString() === pid.toString(),
    );

    const nodeItem = finder(data);
    if (nodeItem?.isPlaceholder && event) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    if (
      (event && (event.target as HTMLElement).nodeName !== 'svg') ||
      !nodeItem?.abstract
    ) {
      nav({ pathname: pid });

      return;
    }

    const existIndex = expanded.findIndex(
      (expandedNodeID) => expandedNodeID === pid,
    );

    if (existIndex !== -1) {
      setExpanded([
        ...expanded.slice(0, existIndex),
        ...expanded.slice(existIndex + 1),
      ]);

      return;
    }

    setData(setNodeLoading(pid, data, true));

    const { data: response, pagination } = await getInstruments({
      pid,
      order: 'asc',
      orderBy: filter.orderBy,
      search: filter.search,
      limit: NODES_REQUEST_LIMIT,
      skip: 0,
    });

    if (!response.length) {
      setData(setNodePlaceholder(pid, data));
      setExpanded([...expanded, pid]);

      return;
    }

    setData(
      insertTreeNodes(pid, data, getLoadableNodes(response, pagination.total)),
    );

    setExpanded([...expanded, pid]);
  };

  const handleForceReset = (selectedId?: string) => {
    setExpanded([]);
    setSelected(selectedId || '');

    if (selectedId) {
      nav({ pathname: selectedId });
    }
  };

  const handleFetchMore = async (nodeId: string, count: number) => {
    let pid = null;
    const page = count + 1;

    setData((nodes) => {
      const loadedStateNodes = setFetchButtonLoading(nodeId, nodes, true);

      pid = getParentId(nodeId, nodes);

      return setFetchButtonCount(nodeId, loadedStateNodes, page);
    });

    const { data: response, pagination } = await getInstruments({
      pid,
      order: 'asc',
      orderBy: filter.orderBy,
      search: filter.search,
      limit: NODES_REQUEST_LIMIT,
      skip: page * NODES_REQUEST_LIMIT,
    });

    setData((nodes) => {
      return getPagedNodes(nodeId, nodes, response, pagination.total, page);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setSelected(id);
  }, [id]);

  return {
    data,
    loading,
    expanded,
    selected,
    handleFetchMore,
    handleForceReset,
    handleRefresh,
    handleSelectNode,
  };
};

export default useTree;
