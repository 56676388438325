import { SortableStack } from '~/components';

import { InstrumentFormProps } from '../../../../types';
import { Container, StackContainer } from '../styled';

import Form from './Form';
import useAccountsList from './useAccountsList';

const Accounts = ({ dependencies }: InstrumentFormProps) => {
  const {
    selected,
    options,
    value,
    handleChange,
    handleOrderChange,
    handleSelect,
  } = useAccountsList(dependencies);

  return (
    <Container>
      <StackContainer>
        <SortableStack
          searchable
          sortable
          value={value}
          options={options}
          selected={selected}
          onChange={handleChange}
          onSelect={handleSelect}
          onOrderChange={handleOrderChange}
        />
      </StackContainer>

      <Form id={selected} dependencies={dependencies} stackValue={value} />
    </Container>
  );
};

export default Accounts;
