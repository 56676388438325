import { InstrumentFormState } from '../types';

const onConvertStart = (state: InstrumentFormState): InstrumentFormState => ({
  ...state,

  convertStatus: {
    succeed: false,
    pending: true,
    error: false,
  },
});

export default onConvertStart;
