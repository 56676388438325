import { useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { SortableListItem } from '../../../../../../../components/SortableList/types';
import { ScheduleForm } from '../../../../../types';

const useRules = (form: UseFormReturn<ScheduleForm>) => {
  const rulesArray = form.watch('rules');
  const rulesFieldControl = useFieldArray({
    name: 'rules',
    control: form.control,
  });

  const rulesList = useMemo<Array<SortableListItem>>(() => {
    if (!rulesArray) {
      return [];
    }

    return rulesArray.map((rule) => ({
      name: rule.description,
      id: Math.random().toString(),
    }));
  }, [rulesArray]);

  const getRuleName = (id: string) => {
    const index = rulesList.findIndex((rule) => rule.id === id);
    const rule = form.watch(`rules.${index}`);

    return rule.description;
  };

  return { rulesList, rulesFieldControl, getRuleName };
};

export default useRules;
