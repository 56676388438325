import {
  BoolRow,
  BoolTable,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const DxFeedProperties = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>Dxfeed properties</SectionTitle>
      <BoolTable>
        <BoolRow
          path={`feeds.providerOverrides.${id}.dxfeedProperties.useLongMaturityFormat`}
        >
          Use long maturity format
        </BoolRow>
      </BoolTable>
      <TextInput path={`feeds.providerOverrides.${id}.dxfeedProperties.suffix`}>
        Suffix
      </TextInput>
      <TextInput
        path={`feeds.providerOverrides.${id}.dxfeedProperties.suffixSeparator`}
      >
        Suffix separator
      </TextInput>
      <BoolTable>
        <BoolRow path={`feeds.providerOverrides.${id}.dxfeedProperties.log`}>
          Log
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.dxfeedProperties.mixedOrderBook`}
        >
          Mixed order book
        </BoolRow>
      </BoolTable>
    </Section>
  );
};

export default DxFeedProperties;
