import { Skeleton } from 'react-ui-kit-exante';

import { createUniqueArr } from '../../helpers';

import { Wrapper, Event } from './SkeletonEvent.styled';

export const SkeletonEvent = () => {
  const uniqueKeys = createUniqueArr(5);

  return (
    <Wrapper>
      {uniqueKeys.map((key) => (
        <Event key={key}>
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={100} height={20} />
        </Event>
      ))}
    </Wrapper>
  );
};
