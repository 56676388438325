import { getCapitalizedWords } from '~/utils/string';

import { EXCLUDED_ENTITIES_SET, FIXED_ENTITIES_MAP } from '../constants';

const getValueTooltip = (path: string): string => {
  let [root, ...parts] = path.split('.');

  if (EXCLUDED_ENTITIES_SET.has(root)) {
    [root, ...parts] = parts;
  }

  const normalized = FIXED_ENTITIES_MAP.get(root);

  return getCapitalizedWords(normalized || root);
};

export default getValueTooltip;
