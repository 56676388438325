import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Notification,
  SelectedListWithSearch,
  PanelHeader,
  Panel,
  IUIISelectedListWithSearchProps,
  IconButton,
} from 'react-ui-kit-exante';

import { symbolDBService } from '~/services/symbolDB.service';
import { Currency } from '~/types/models';

import { SuccessMessages } from '../Themes/constants';

import styles from './Currency.module.css';
import { CurrencyForm } from './CurrencyForm/CurrencyForm';
import { Placeholder } from './Placeholder';

const initialValue: Currency = {
  _id: '',
  _rev: '',
  _creationTime: '',
  _lastUpdateTime: '',
  icon: '',
  description: '',
  leverageRate: 0,
  leverageRateShort: 0,
  currencySymbol: '',
  fractionDigits: null,
  extremeLeverageRate: null,
  extremeLeverageRateShort: null,
  code: null,
  weight: 0,
  isCrypto: false,
  isNav: false,
};

export const Currencies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [isNewCurrency, setIsNewCurrency] = useState(false);

  const [isShowCurrency, setIsShowCurrency] = useState(false);
  const [currency, setCurrency] = useState<Currency | typeof initialValue>(
    initialValue,
  );

  const openCurrencyForm = (values: Currency, id: string) => {
    setIsShowCurrency(true);
    // eslint-disable-next-line no-underscore-dangle
    const currencyItem = currencies.find((item) => item._id === id);

    if (currencyItem) {
      setIsNewCurrency(false);
      setCurrency(currencyItem);
    }
    setSelected(id);
  };

  const closeCurrencyForm = () => {
    setIsShowCurrency(false);
    setCurrency(initialValue);
    setSelected(null);
  };

  const getCurrencies = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await symbolDBService().getCurrencies();
      setCurrencies(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteCurrency = useCallback(async (id?: string) => {
    try {
      const response = await symbolDBService().deleteCurrency(id);
      if (response) {
        Notification.success({ title: SuccessMessages.Delete });
        setIsShowCurrency(false);
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, []);

  const createNewCurrency = useCallback(() => {
    setCurrency(initialValue);
    setIsNewCurrency(true);
    setIsShowCurrency(true);
  }, []);

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  const options = useMemo(() => {
    return currencies.map((item: Currency) => ({
      ...item,
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
      // eslint-disable-next-line no-underscore-dangle
      name: item._id,
    })) as IUIISelectedListWithSearchProps['options'];
  }, [currencies]);

  const onDelete = useCallback(
    async (id?: string) => {
      await deleteCurrency(id);
      await getCurrencies();
    },
    [deleteCurrency, getCurrencies],
  );
  // eslint-disable-next-line no-underscore-dangle
  const keyCurrency = currency ? currency._id : 'new';

  return (
    <div className={styles.Wrapper}>
      <PanelHeader className={styles.Head} title="Currency" />
      <div className={styles.Content}>
        <div className={styles.SelectedListBlock}>
          <Panel className={styles.CurrencyList}>
            <SelectedListWithSearch
              onListItemClick={openCurrencyForm}
              options={options}
              selected={selected}
              isLoading={isLoading}
            />
          </Panel>
          <div className={styles.AddBlock}>
            <IconButton
              iconColor="action"
              iconName="add"
              iconSize={24}
              label="Add Currency"
              onClick={createNewCurrency}
            />
          </div>
        </div>
        {isShowCurrency ? (
          <CurrencyForm
            key={keyCurrency}
            onClose={closeCurrencyForm}
            onDelete={onDelete}
            currency={currency}
            isNewCurrency={isNewCurrency}
            onUpdateCurrencies={getCurrencies}
          />
        ) : (
          <Placeholder create={createNewCurrency} />
        )}
      </div>
    </div>
  );
};
