/* eslint-disable import/no-mutable-exports */

import { InfluxProxyService } from './influxProxy';
import { Logger } from './influxProxy/types';
import { SessionService } from './session';

let influxProxyService: InfluxProxyService;
let sessionService: SessionService;

export function initResources(logger: Logger) {
  influxProxyService = new InfluxProxyService(logger);
  sessionService = new SessionService(logger);
}

export { influxProxyService, sessionService };
