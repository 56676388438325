import { styled } from '@mui/material/styles';
import { TDefaultTheme } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }: TDefaultTheme) => ({
  padding: '24px',
  display: 'flex',
  backgroundColor: theme?.color?.bg?.primary,
}));

export const Event = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '32px',
}));
