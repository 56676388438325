import { styled } from '@mui/material/styles';
import { useCallback } from 'react';

import { CurrencyItemProps, ButtonProps } from './CurrencyItem.types';

const Button = styled('button', {
  shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'isActive',
})(({ theme, isActive }: ButtonProps) => ({
  width: '100%',
  display: 'flex',
  'align-items': 'center',
  border: 'none',
  'border-bottom': `2px solid ${theme?.color?.table?.bg?.basic1}`,
  cursor: 'pointer',
  padding: '12px',
  'background-color': 'transparent',
  color: isActive ? theme?.palette.text.primary : theme?.color?.icon?.secondary,
  '&:hover': {
    color: theme?.palette.text.primary,
  },
}));

const Item = styled('div')(({ theme }) => ({
  width: '100%',
  [`&:hover ${Button}`]: {
    color: theme?.palette.text.primary,
  },
}));

export const CurrencyItem = ({
  title,
  id,
  onChangeActiveCurrency,
  isActive,
}: CurrencyItemProps) => {
  const handleAccordionClick = useCallback(
    () => onChangeActiveCurrency(id),
    [id, onChangeActiveCurrency],
  );

  return (
    <Item>
      <Button
        data-test-id="currency-holidays__button--choose-currency"
        type="button"
        onClick={handleAccordionClick}
        isActive={isActive}
      >
        {title}
      </Button>
    </Item>
  );
};
