import { InstrumentFormActions } from './actions';
import {
  onAddChild,
  onAppendOverride,
  onConvertError,
  onConvertStart,
  onConvertSucceed,
  onDeleteError,
  onDeleteStart,
  onDeleteSucceed,
  onDiscardChanges,
  onFetchError,
  onFetchStart,
  onFetchSucceed,
  onRemove,
  onSaveError,
  onSaveStart,
  onSaveSucceed,
  onSetFieldValue,
  onSetReady,
  onStackOrderChange,
} from './handlers';
import { InstrumentFormReducer, InstrumentFormState } from './types';
import validate from './validation';

const reducer: InstrumentFormReducer = (state, action): InstrumentFormState => {
  switch (action.type) {
    case InstrumentFormActions.AppendOverride:
      return validate(onAppendOverride(state, action.payload));

    case InstrumentFormActions.AddChild:
      return onAddChild(state);

    case InstrumentFormActions.ConvertError:
      return onConvertError(state);

    case InstrumentFormActions.ConvertStart:
      return onConvertStart(state);

    case InstrumentFormActions.ConvertSucceed:
      return onConvertSucceed(state);

    case InstrumentFormActions.DeleteError:
      return onDeleteError(state);

    case InstrumentFormActions.DeleteStart:
      return onDeleteStart(state);

    case InstrumentFormActions.DeleteSucceed:
      return onDeleteSucceed();

    case InstrumentFormActions.DiscardChanges:
      return validate(onDiscardChanges(state, action.payload));

    case InstrumentFormActions.FetchError:
      return onFetchError(state, action.payload);

    case InstrumentFormActions.FetchStart:
      return onFetchStart();

    case InstrumentFormActions.FetchSucceed:
      return onFetchSucceed(state, action.payload);

    case InstrumentFormActions.Remove:
      return validate(onRemove(state, action.payload));

    case InstrumentFormActions.SaveError:
      return onSaveError(state);

    case InstrumentFormActions.SaveStart:
      return validate(onSaveStart(state));

    case InstrumentFormActions.SaveSucceed:
      return onSaveSucceed(state);

    case InstrumentFormActions.SetFieldValue:
      return validate(
        onSetFieldValue(state, action.payload.path, action.payload.value),
      );

    case InstrumentFormActions.SetReady: {
      return onSetReady();
    }

    case InstrumentFormActions.StackOrderChange:
      return validate(
        onStackOrderChange(state, action.payload.path, action.payload.nodes),
      );

    default:
      return state;
  }
};

export default reducer;
