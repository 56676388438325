import MuiModal from '@mui/material/Modal';
import cx from 'classnames';
import React, { HTMLProps, useCallback, useEffect } from 'react';

import styles from './Modal.module.css';
import { ModalProps } from './types';

type Props = HTMLProps<HTMLDivElement> & ModalProps;

const Modal: React.FC<Props> = ({
  children,
  onPressEscape,
  className,
  ...props
}) => {
  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape' && onPressEscape) {
        onPressEscape();
      }
    },
    [onPressEscape],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <MuiModal open>
      <div {...props} className={cx(styles.Container, className)}>
        {children}
      </div>
    </MuiModal>
  );
};

export default Modal;
