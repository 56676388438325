import cx from 'classnames';
import { useMemo } from 'react';

import styles from './ToggleGroup.module.css';
import { ToggleGroupProps } from './types';

const ToggleGroup = <T extends string | number | undefined>({
  options,
  value,
  onChange,
}: ToggleGroupProps<T>) => {
  const valueSet = useMemo(() => new Set(value), [value]);

  const handleClick = (v: T) => {
    if (valueSet.has(v)) {
      valueSet.delete(v);
    } else {
      valueSet.add(v);
    }

    onChange(Array.from(valueSet));
  };

  return (
    <div className={styles.Container}>
      {options.map((item) => (
        <button
          role="tab"
          type="button"
          key={item.value}
          onClick={() => handleClick(item.value)}
          className={cx(styles.Item, {
            [styles.Active]: valueSet.has(item.value),
          })}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleGroup;
