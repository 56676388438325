import { useMemo } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

import { NAV } from '~/pages/routing';

import { ExchangesRoutes } from '../constants';

const useExchangesRouting = () => {
  const nav = useNavigate();
  const match = useMatch(`${NAV.EXCHANGES}/${ExchangesRoutes.EDIT}`);

  const selected = useMemo(() => {
    if (!match?.params.id || match?.params.id === ExchangesRoutes.NEW) {
      return null;
    }

    return match?.params.id;
  }, [match?.params.id]);

  const handleCloseExchangeForm = () => {
    nav(NAV.EXCHANGES, { replace: false });
  };

  const handleCreateExchange = () => {
    nav(`${NAV.EXCHANGES}/new`, { replace: false });
  };

  const handleOpenExchange = (id: string) => {
    nav(`${NAV.EXCHANGES}/${id}`, { replace: false });
  };

  return {
    selected,
    handleCloseExchangeForm,
    handleCreateExchange,
    handleOpenExchange,
  };
};

export default useExchangesRouting;
