import { PropsWithChildren } from 'react';
import { ThemeProvider, useTheme } from 'react-ui-kit-exante';

import { Container, Input, Title } from './styled';
import { RadioProps } from './types';

const Radio = ({
  disabled,
  checked,
  view,
  onClick,
  children,
}: PropsWithChildren<RadioProps>) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container disabled={disabled} onClick={onClick}>
        <Input checked={checked} view={view} />
        {children && <Title view={view}>{children}</Title>}
      </Container>
    </ThemeProvider>
  );
};

export default Radio;
