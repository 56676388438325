import { InstrumentFormState } from '../types';

const onDeleteStart = (state: InstrumentFormState): InstrumentFormState => ({
  ...state,

  deleteStatus: {
    succeed: false,
    pending: true,
    error: false,
  },
});

export default onDeleteStart;
