export const routes = {
  LIST: '',
  EDIT: ':id/*',
};

export const defaultOption = [{ value: 'default', label: 'default' }];

export const selectWidth = {
  minWidth: 190,
};

export enum Statuses {
  PROGRESS = 'PROGRESS',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum StepType {
  PREPARE = 'prepare',
  SYNC = 'sync',
}

export const pollingDelay = 15000;

export const IssuerTypes = [
  { value: 'Other', label: 'Other' },
  { value: 'Corporate', label: 'Corporate' },
  { value: 'Government', label: 'Government' },
  { value: 'Municipal', label: 'Municipal' },
];

export const MaturityTypes = [
  { value: 'NORMAL', label: 'NORMAL' },
  { value: 'Extendible', label: 'Extendible' },
  { value: 'Perpetual', label: 'Perpetual' },
  { value: 'Linked', label: 'Linked' },
  { value: 'Repayment', label: 'Repayment' },
  { value: 'Reverse Convertible', label: 'Reverse Convertible' },
  { value: 'Reverse Exchangable', label: 'Reverse Exchangable' },
  { value: 'CALLABLE', label: 'CALLABLE' },
  { value: 'CALLED', label: 'CALLED' },
];

export const DefaultBondFilters = {
  currency: {
    value: [
      {
        value: 'USD',
        label: 'USD',
      },
      {
        value: 'EUR',
        label: 'EUR',
      },
    ],
    isDirty: false,
  },
  clearAgent: {
    value: [
      {
        value: 'Clearstream',
        label: 'Clearstream',
      },
      {
        value: 'Euroclear',
        label: 'Euroclear',
      },
    ],
    isDirty: false,
  },
  issuerType: {
    value: [
      {
        value: 'Corporate',
        label: 'Corporate',
      },
      {
        value: 'Government',
        label: 'Government',
      },
    ],
    isDirty: false,
  },
  countryRisk: {
    value: [
      {
        value: 'US',
        label: 'United States of America',
      },
    ],
    isDirty: false,
  },
  issueAmount: { value: 200000000, isDirty: false },
  maturityType: {
    value: [
      {
        value: 'NORMAL',
        label: 'NORMAL',
      },
    ],
    isDirty: false,
  },
};

export const controlCls = { mb: '16px', mr: '16px', width: '384px' };

export const CleatAgentsOptions = [
  {
    value: 'DTCC',
    label: 'DTCC',
  },
  {
    value: 'CDS',
    label: 'CDS',
  },
  {
    value: 'Clearstream',
    label: 'Clearstream',
  },
  {
    value: 'Euroclear',
    label: 'Euroclear',
  },
  {
    value: 'Fed Wire',
    label: 'Fed Wire',
  },
  {
    value: 'NSCC',
    label: 'NSCC',
  },
  {
    value: 'Cajval',
    label: 'Cajval',
  },
  {
    value: 'Merval',
    label: 'Merval',
  },
  {
    value: 'MAE',
    label: 'MAE',
  },
  {
    value: 'CBLC',
    label: 'CBLC',
  },
  {
    value: 'Cetip',
    label: 'Cetip',
  },
  {
    value: 'DCV',
    label: 'DCV',
  },
  {
    value: 'Infoval',
    label: 'Infoval',
  },
  {
    value: 'Deceval',
    label: 'Deceval',
  },
  {
    value: 'Sipo',
    label: 'Sipo',
  },
  {
    value: 'Indeval',
    label: 'Indeval',
  },
  {
    value: 'Cavali',
    label: 'Cavali',
  },
  {
    value: 'Monte Titole',
    label: 'Monte Titole',
  },
  {
    value: 'Apothetirion Titlon AE (Central Securities Depository S.A.)',
    label: 'Apothetirion Titlon AE (Central Securities Depository S.A.)',
  },
  {
    value: 'Austraclear Ltd.',
    label: 'Austraclear Ltd.',
  },
  {
    value: 'Bursa Malaysia Depository Sdn. Bhd',
    label: 'Bursa Malaysia Depository Sdn. Bhd',
  },
  {
    value: 'Central Depository Systems (Pvt) Ltd.',
    label: 'Central Depository Systems (Pvt) Ltd.',
  },
  {
    value: 'Central Moneymarkets Unit (CMU)',
    label: 'Central Moneymarkets Unit (CMU)',
  },
  {
    value: 'Central Registry Agency Inc. (CRA)',
    label: 'Central Registry Agency Inc. (CRA)',
  },
  {
    value: 'Central Securities Clearing System Ltd.',
    label: 'Central Securities Clearing System Ltd.',
  },
  {
    value: 'Central Securities Depository of Poland',
    label: 'Central Securities Depository of Poland',
  },
  {
    value: 'Central Securities Depository of the Slovak Republic',
    label: 'Central Securities Depository of the Slovak Republic',
  },
  {
    value: 'China Securities Depository and Clearing Corporation Ltd.',
    label: 'China Securities Depository and Clearing Corporation Ltd.',
  },
  {
    value: 'Clearstream Banking A.G.',
    label: 'Clearstream Banking A.G.',
  },
  {
    value: 'Depozitarul Central S.A. (Romanian Central Depository)',
    label: 'Depozitarul Central S.A. (Romanian Central Depository)',
  },
  {
    value: 'Euroclear France',
    label: 'Euroclear France',
  },
  {
    value: 'Euroclear Nederland',
    label: 'Euroclear Nederland',
  },
  {
    value: 'Euroclear UK & Ireland Limited',
    label: 'Euroclear UK & Ireland Limited',
  },
  {
    value: 'IBERCLEAR',
    label: 'IBERCLEAR',
  },
  {
    value: 'Interbolsa',
    label: 'Interbolsa',
  },
  {
    value: 'Japan Securities Clearing Corporation (JSCC)',
    label: 'Japan Securities Clearing Corporation (JSCC)',
  },
  {
    value: 'KDD Centralna klirinsko depotna druzba d.d., Ljubljana (KDD)',
    label: 'KDD Centralna klirinsko depotna druzba d.d., Ljubljana (KDD)',
  },
  {
    value: 'KELER Ltd. (Central Clearing House and Depository (Budapest) Ltd.)',
    label: 'KELER Ltd. (Central Clearing House and Depository (Budapest) Ltd.)',
  },
  {
    value: 'Korea Securities Depository',
    label: 'Korea Securities Depository',
  },
  {
    value: 'LuxClear',
    label: 'LuxClear',
  },
  {
    value: 'Maroclear / Centrale des Titres Scripturaux',
    label: 'Maroclear / Centrale des Titres Scripturaux',
  },
  {
    value: 'Misr for Central Clearing, Depository and Registry (MCDR)',
    label: 'Misr for Central Clearing, Depository and Registry (MCDR)',
  },
  {
    value: 'National Securities Depository Ltd. (NSDL)',
    label: 'National Securities Depository Ltd. (NSDL)',
  },
  {
    value: 'National Settlement Depository (NSD)',
    label: 'National Settlement Depository (NSD)',
  },
  {
    value: 'New Zealand Central Securities Depository Ltd. (NZCSD)',
    label: 'New Zealand Central Securities Depository Ltd. (NZCSD)',
  },
  {
    value: 'Oesterreichische Kontrollbank AG (OeKB)',
    label: 'Oesterreichische Kontrollbank AG (OeKB)',
  },
  {
    value: 'PT Kustodian Sentral Efek Indonesia (KSEI)',
    label: 'PT Kustodian Sentral Efek Indonesia (KSEI)',
  },
  {
    value: 'Philippine Depository and Trust Corporation (PDTC)',
    label: 'Philippine Depository and Trust Corporation (PDTC)',
  },
  {
    value: 'SIS SegaIntersettle AG',
    label: 'SIS SegaIntersettle AG',
  },
  {
    value: 'Stredisko cennych papiru (Prague Securities Centre)',
    label: 'Stredisko cennych papiru (Prague Securities Centre)',
  },
  {
    value:
      'Suomen Arvopaperikeskus Oy (Finnish Central Securities Depository Ltd.)',
    label:
      'Suomen Arvopaperikeskus Oy (Finnish Central Securities Depository Ltd.)',
  },
  {
    value: 'TSD - Thailand Securities Depository Co.,Ltd.',
    label: 'TSD - Thailand Securities Depository Co.,Ltd.',
  },
  {
    value: 'Taiwan Depository & Clearing Corporation (TDCC)',
    label: 'Taiwan Depository & Clearing Corporation (TDCC)',
  },
  {
    value: 'The Central Depository (Pte) Ltd.',
    label: 'The Central Depository (Pte) Ltd.',
  },
  {
    value: 'The Central Depository Ltd.',
    label: 'The Central Depository Ltd.',
  },
  {
    value: 'The Norwegian Central Securities Depository',
    label: 'The Norwegian Central Securities Depository',
  },
  {
    value: 'The Tel Aviv Stock Exchange Clearing House Ltd.',
    label: 'The Tel Aviv Stock Exchange Clearing House Ltd.',
  },
  {
    value: 'VP Securities A/S',
    label: 'VP Securities A/S',
  },
  {
    value: 'VPC AB (Swedish Central Securities Depository Ltd.)',
    label: 'VPC AB (Swedish Central Securities Depository Ltd.)',
  },
  {
    value: 'Vietnam Securities Depository (VSD)',
    label: 'Vietnam Securities Depository (VSD)',
  },
  {
    value: 'National Clearing Company of Pakistan Limited (NCCPL)',
    label: 'National Clearing Company of Pakistan Limited (NCCPL)',
  },
  {
    value: 'Central Depository and Clearing Company Inc. (CDCC)',
    label: 'Central Depository and Clearing Company Inc. (CDCC)',
  },
  {
    value: 'MaltaClear',
    label: 'MaltaClear',
  },
  {
    value: 'Shanghai Clearing House (SHCH)',
    label: 'Shanghai Clearing House (SHCH)',
  },
  {
    value: 'China Central Depository And Clearing Co Ltd (CCDC)',
    label: 'China Central Depository And Clearing Co Ltd (CCDC)',
  },
  {
    value: 'Japan Securities Depository Center, Inc.',
    label: 'Japan Securities Depository Center, Inc.',
  },
  {
    value: 'Bursa Malaysia Depository Sdn Bhd',
    label: 'Bursa Malaysia Depository Sdn Bhd',
  },
  {
    value: 'Bank Negara Malaysia',
    label: 'Bank Negara Malaysia',
  },
  {
    value: 'MUFG Bank (Malaysia) Berhad',
    label: 'MUFG Bank (Malaysia) Berhad',
  },
  {
    value: 'Citibank NA London Branch',
    label: 'Citibank NA London Branch',
  },
  {
    value: 'DBS Nominees (Private) Limited',
    label: 'DBS Nominees (Private) Limited',
  },
  {
    value: 'Deutsche Bank AG London Branch',
    label: 'Deutsche Bank AG London Branch',
  },
  {
    value: 'Canadian Derivatives Clearing Corp. (CDCC)',
    label: 'Canadian Derivatives Clearing Corp. (CDCC)',
  },
  {
    value: 'Eurex Clearing AG',
    label: 'Eurex Clearing AG',
  },
  {
    value: 'LCH.Clearnet S.A.',
    label: 'LCH.Clearnet S.A.',
  },
  {
    value: 'HKEx Clearing',
    label: 'HKEx Clearing',
  },
  {
    value: 'Cassa di Compensazione e Garanzia (CCG)',
    label: 'Cassa di Compensazione e Garanzia (CCG)',
  },
  {
    value: 'Bursa Malaysia Derivatives Clearing',
    label: 'Bursa Malaysia Derivatives Clearing',
  },
  {
    value: 'Singapore Exchange (SGX)',
    label: 'Singapore Exchange (SGX)',
  },
];
