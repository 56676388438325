export const columns: any = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'textInput',
    width: '500',
  },
  {
    Header: 'Provider',
    accessor: 'providerId',
    type: 'textInput',
    width: '500',
  },
  {
    Header: 'Id',
    accessor: 'mapId',
  },
];

export const DISPLAYED_COLUMN_KEYS = ['name', 'providerId'];

export const initialValues = {
  name: '',
  parent: '',
  value: '',
};
