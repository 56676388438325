import Cookies from 'js-cookie';

import {
  ACCESS_TOKEN_COOKIE_KEY,
  SESSION_ID_COOKIE_KEY,
} from '../../constants/session';

export function getSessionIdFromCookie(
  sessionIdCookieKey = SESSION_ID_COOKIE_KEY,
) {
  return Cookies.get(sessionIdCookieKey);
}

export const getAccessTokenFromCookies = () =>
  Cookies.get(ACCESS_TOKEN_COOKIE_KEY);
