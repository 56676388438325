import * as yup from 'yup';

const MIN_LENGTH = 5;

export const schema = yup.object().shape({
  providerId: yup
    .string()
    .required('provider is a required field')
    .min(MIN_LENGTH, `provider must be at least ${MIN_LENGTH} characters`),
  name: yup.string().required().min(MIN_LENGTH),
  value: yup.string().required(),
});
