import { useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTheme } from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import { Sidebar } from '~/pages/Instruments/components';

import RegistrationSessionContainer from '../../../components/RegistrationSessionContainer';

import { Main } from './Main';
import { SidebarWrapper, Wrapper } from './Main.styled';
import { Placeholder } from './Placeholder';
import { SkeletonMainPage } from './SkeletonMainPage';
import { routes } from './constants';
import { SidebarRef } from './types';

const MainWrapper = () => {
  const theme = useTheme();
  const sidebarRef = useRef<SidebarRef>(null);

  return (
    <RegistrationSessionContainer loader={<SkeletonMainPage />}>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <SidebarWrapper>
            <Sidebar ref={sidebarRef} hideInstrumentIcons showSyncOnSchedule />
          </SidebarWrapper>

          <Routes>
            <Route path={routes.LIST} element={<Placeholder />} />
            <Route path={routes.EDIT} element={<Main />} />
          </Routes>
        </Wrapper>
      </ThemeProvider>
    </RegistrationSessionContainer>
  );
};

export default MainWrapper;
