import {
  BoolRow,
  BoolTable,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const IdcProperties = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>Idc feed</SectionTitle>
      <TextInput path={`feeds.providerOverrides.${id}.idcProperties.symbolId`}>
        SymbolID
      </TextInput>
      <TextInput path={`feeds.providerOverrides.${id}.idcProperties.source`}>
        Source
      </TextInput>
      <TextInput path={`feeds.providerOverrides.${id}.idcProperties.l2Source`}>
        L2 source
      </TextInput>
      <BoolTable>
        <BoolRow path={`feeds.providerOverrides.${id}.idcProperties.log`}>
          Log
        </BoolRow>
      </BoolTable>
    </Section>
  );
};

export default IdcProperties;
