import { FilterState } from './types';

export const DEFAULT_ORDER = 'asc';
export const NODES_REQUEST_LIMIT = 100;
export const SEARCH_MIN_CHARS_LENGTH = 2;

export const initialFilterState: FilterState = {
  expired: false,
  orderBy: 'name',
  search: '',
  trading: false,
};
