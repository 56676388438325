import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '../../../hooks/useFetchStatus';
import { symbolDBService } from '../../../services/symbolDB.service';

const useExecutionSchemesDependencies = () => {
  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();

  const [defaultLegalEntity, setDefaultLegalEntity] = useState('');
  const [legalEntitiesList, setLegalEntitiesList] = useState<Array<string>>([]);

  const fetchDependencies = async () => {
    fetchingStatusActions.handleStart();

    try {
      const response = await symbolDBService().getLegalEntities();

      setDefaultLegalEntity(response.default);
      setLegalEntitiesList(response.values);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  return {
    isDependenciesLoading: fetchingStatus.isPending,
    defaultLegalEntity,
    legalEntitiesList,
  };
};

export default useExecutionSchemesDependencies;
