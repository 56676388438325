import React, { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { IconButton, Input, Loader } from 'react-ui-kit-exante';

import { DeleteDialog } from '../../../../components/DeleteDialog';

import styles from './BrokerForm.module.css';
import { Gateways } from './Gateways';
import useBrokerForm from './hooks/useBrokerForm';
import { BrokerFormProps } from './types';

const BrokerForm: React.FC<BrokerFormProps> = ({
  onClose,
  onDelete,
  onDirty,
  onSuccess,
}) => {
  const {
    form,

    id,
    title,

    fetchingStatus,
    deletingStatus,

    deleteBrokerProvider,

    onSubmit,
  } = useBrokerForm({ onDelete, onSuccess });

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <div className={styles.Loader}>
        <Loader size="l" isCentered />
      </div>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit} className={styles.Container}>
          <div className={styles.Header}>
            <span className={styles.Title}>{title}</span>
            <div className={styles.Actions}>
              <IconButton
                disabled={!isDirty}
                iconColor={isDirty ? 'action' : 'secondary'}
                iconName="save"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {id && (
                <IconButton
                  iconColor="radical"
                  iconName="delete"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="close"
                iconSize={24}
                onClick={onClose}
              />
            </div>
          </div>

          <div className={styles.Body}>
            <Controller
              key="name"
              name="name"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Name *"
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={fieldState.error?.message}
                />
              )}
            />

            <Controller
              key="providerType"
              name="providerType"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Provider Type"
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={fieldState.error?.message}
                />
              )}
            />
          </div>

          <Gateways form={form} />
        </form>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={deleteBrokerProvider}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default BrokerForm;
