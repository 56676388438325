import { TextInput } from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const NanexProperties = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>Nanex properties</SectionTitle>
      <TextInput
        path={`feeds.providerOverrides.${id}.nanexProperties.suspiciousTradeDeviation`}
      >
        Suspicious trade deviation
      </TextInput>
    </Section>
  );
};

export default NanexProperties;
