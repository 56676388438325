import { IOption } from 'react-ui-kit-exante';

import { SchedulesResponseItem } from '../../types';

export const mapScheduleToOption = (item: SchedulesResponseItem): IOption => {
  return {
    ...item,
    // eslint-disable-next-line no-underscore-dangle
    id: item._id,
    name: item.name,
  };
};

export const getListOptions = (data: SchedulesResponseItem[]): IOption[] => {
  const sortedData = [...data].sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  );

  return sortedData.map((item) => mapScheduleToOption(item));
};
