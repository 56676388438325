export const checkIsValidIsoString = (str: string) => {
  const dateParsed = Date.parse(str);

  if (Number.isNaN(dateParsed)) {
    return false;
  }
  const date = new Date(dateParsed);

  return date.toISOString() === str;
};
