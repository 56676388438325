import { Loader } from 'react-ui-kit-exante';

import { ReactComponent as FolderIcon } from '~/assets/i/Folder.svg';
import { ReactComponent as InstrumentIcon } from '~/assets/i/Instrument.svg';
import { Theme } from '~/theme';

import { UnifiedTreeItem } from '../../types';

export const getColor = (
  theme: Theme,
  trading: boolean,
  expiry: boolean,
): string => {
  if (expiry) {
    return theme.color.icon.promo;
  }

  if (trading) {
    return theme.color.icon.action;
  }

  return '#047CBF';
};

export const getItemIcon = (loading?: boolean, abstract?: boolean) => {
  if (loading) {
    return Loader;
  }

  if (abstract) {
    return FolderIcon;
  }

  return InstrumentIcon;
};

export const getChildNodesAbstract = (nodes: UnifiedTreeItem[]) => {
  return nodes[0]?.abstract;
};
