import { AxiosError } from 'axios';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { BODataService } from '../../services/BO.service';
import { UnauthorizedError } from '../../utils/errors';

interface ISessionRegistrationContainerProps {
  loader?: ReactNode | string;
}

const RegistrationSessionContainer = ({
  children,
  loader,
}: PropsWithChildren<ISessionRegistrationContainerProps>) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('register-session');

  const handleOnSuccess = useCallback(() => {
    logHandleTime();
    setIsLoading(false);
  }, [logHandleTime]);

  const handleOnFail = useCallback((error: AxiosError) => {
    if (error instanceof UnauthorizedError) {
      Notification.error({
        title: error.message,
      });
    } else {
      Notification.error({
        title: `Unknown error occurred while register session${
          `: ${error}` || ''
        } `,
      });
    }
    setIsLoading(false);
  }, []);

  const registerSession = useCallback(async () => {
    setStartHandleTime();

    try {
      await BODataService().registerSession();
      handleOnSuccess();
    } catch (e) {
      handleOnFail(e as AxiosError);
    }
  }, [handleOnSuccess, handleOnFail, setStartHandleTime]);

  useEffect(() => {
    registerSession();
  }, [registerSession]);

  useEffect(() => {
    window.addEventListener('onUpdateAccessToken', registerSession);

    return () => {
      window.removeEventListener('onUpdateAccessToken', registerSession);
    };
  }, []);

  if (isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{loader}</>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default RegistrationSessionContainer;
