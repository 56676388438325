import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { NAV } from '~/pages/routing';

import { ExecutionSchemesRoutes } from '../constants';

const useExecutionSchemesRouting = () => {
  const nav = useNavigate();
  const match = useMatch(
    `${NAV.EXECUTION_SCHEMES}/${ExecutionSchemesRoutes.EDIT}`,
  );

  const selected = useMemo(() => {
    if (!match?.params.id || match?.params.id === ExecutionSchemesRoutes.NEW) {
      return null;
    }

    return match?.params.id;
  }, [match?.params.id]);

  const handleClose = () => {
    nav(NAV.EXECUTION_SCHEMES, { replace: false });
  };

  const handleNew = () => {
    nav(`${NAV.EXECUTION_SCHEMES}/new`, { replace: false });
  };

  const handleOpen = (id: string) => {
    nav(`${NAV.EXECUTION_SCHEMES}/${id}`, { replace: false });
  };

  return {
    selected,
    handleClose,
    handleNew,
    handleOpen,
  };
};

export default useExecutionSchemesRouting;
