import { Tooltip, Loader } from 'react-ui-kit-exante';

import { Button, Container } from './styled';
import { ConvertButtonProps } from './types';
import useConvertType from './useConvertType';

const ConvertButton = ({ loading, onClick }: ConvertButtonProps) => {
  const { title } = useConvertType();

  return (
    <Tooltip title={title}>
      <Container>
        {loading && <Loader size="m" />}
        {!loading && (
          <Button iconName="download" disabled={loading} onClick={onClick} />
        )}
      </Container>
    </Tooltip>
  );
};

export default ConvertButton;
