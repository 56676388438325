import { useEffect, useState } from 'react';

export type AsyncRequestOptions = {
  callOnMount: boolean;
};

type UseAsyncRequest<T> = [
  request: (...rest: any[]) => Promise<void>,
  status: { error: Error | null; pending: boolean; succeed: boolean },
  data?: T,
];

const useAsyncRequest = <T>(
  requestFn: (...rest: any[]) => Promise<T>,
  options: AsyncRequestOptions = {
    callOnMount: true,
  },
): UseAsyncRequest<T> => {
  const [error, setError] = useState<Error | null>(null);
  const [pending, setPending] = useState(false);
  const [succeed, setSucceed] = useState(false);

  const [data, setData] = useState<T | undefined>(undefined);

  const request = async (...rest: any[]) => {
    setError(null);
    setSucceed(false);
    setPending(true);

    try {
      const result = await requestFn(...rest);

      setPending(false);
      setSucceed(true);
      setData(result);
    } catch (e) {
      setPending(false);
      setError(e as unknown as Error);
    }
  };

  useEffect(() => {
    if (options.callOnMount) {
      request();
    }
  }, []);

  return [
    request,
    {
      error,
      pending,
      succeed,
    },
    data,
  ];
};

export default useAsyncRequest;
