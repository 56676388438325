import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { Actions, ActionsContainer, InheritTitle } from './styled';
import { InheritButtonProps } from './types';

const InheritButton = ({ isActive, value, onReset }: InheritButtonProps) => {
  return (
    <ActionsContainer>
      {!isActive && (
        <Actions active={isActive}>
          <InheritTitle active={isActive}>Inherited</InheritTitle>
          <TaskAltIcon />
        </Actions>
      )}
      {isActive && value && (
        <Actions active={isActive} onClick={onReset}>
          <InheritTitle active={isActive}>
            Inherit: {value.toString()}
          </InheritTitle>
          <DownloadForOfflineIcon />
        </Actions>
      )}
    </ActionsContainer>
  );
};

export default InheritButton;
