import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  height: calc(100vh - 157px);
  overflow: hidden;
`;

export const StackContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-rows: 64px 1fr;
  height: 100%;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  padding: 0 24px;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
`;

export const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const Section = styled.div`
  display: grid;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.bg.secondary};
  padding: 24px;
`;

export const SectionTitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.color.typo.primary};
`;
