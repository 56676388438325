import { apiService } from '~/services/api/api.service';
import { getSymbolDBBaseUrl } from '~/services/api/helpers';
import { Endpoint } from '~/services/constants';
import { getSessionIdFromCookie } from '~/services/cookies/cookies.service';
import {
  AffectedSymbols,
  LegacyInstrumentPayload,
  LegacyInstrumentResponse,
} from '~/types/models';
import { EmptyResponse } from '~/types/shared';

const symbolDBAPI = apiService(getSymbolDBBaseUrl());

export const fetchLegacyInstrument = async (
  id?: string,
): Promise<LegacyInstrumentResponse> => {
  if (!id) {
    return [];
  }

  const { data } = await symbolDBAPI.get({
    endPoint: `${Endpoint.Instruments}?childId=${id}`,
    sessionToken: getSessionIdFromCookie() || '',
  });

  return (data as LegacyInstrumentResponse).sort(
    (a, b) => b.path.length - a.path.length,
  );
};

export const saveLegacyInstrument = async (
  id: string,
  payload: Partial<LegacyInstrumentPayload>,
): Promise<EmptyResponse> => {
  const { data } = await symbolDBAPI.post({
    endPoint: `${Endpoint.Instruments}/${id}`,
    sessionToken: getSessionIdFromCookie() || '',
    data: payload,
  });

  return data;
};

export const createLegacyInstrument = async (
  payload: Partial<LegacyInstrumentPayload>,
): Promise<EmptyResponse> => {
  const { data } = await symbolDBAPI.post({
    endPoint: Endpoint.Instruments,
    sessionToken: getSessionIdFromCookie() || '',
    data: payload,
  });

  return data;
};

export const deleteLegacyInstrument = async (
  id: string,
): Promise<EmptyResponse> => {
  const { data } = await symbolDBAPI.delete({
    endPoint: `${Endpoint.Instruments}/${id}`,
    sessionToken: getSessionIdFromCookie() || '',
  });

  return data;
};

export const checkLegacyInstrumentDependencies = async (
  id?: string,
): Promise<AffectedSymbols> => {
  if (!id) {
    return { affectedSymbols: 0 };
  }

  const { data } = await symbolDBAPI.get({
    endPoint: `${Endpoint.Instruments}/${id}/deps`,
    sessionToken: getSessionIdFromCookie() || '',
  });

  return data;
};
