import { ValueConfig } from '../types';

const FEEDS_PROVIDER_OVERRIDES: ValueConfig[] = [
  {
    path: 'aggregateTillMinAllowedVolume',
    type: 'boolean',
  },
  {
    path: 'flipQuotes',
    type: 'boolean',
  },
  {
    path: 'forceAlignToMpi',
    type: 'boolean',
  },
  {
    path: 'useACICorrection',
    type: 'boolean',
  },
  {
    path: 'useBondDiscountQuotation',
    type: 'boolean',
  },
  {
    path: 'askMarkup',
    type: 'number',
  },
  {
    path: 'bidMarkup',
    type: 'number',
  },
  {
    path: 'contractMultiplier',
    type: 'number',
  },
  { path: 'legGap', type: 'number' },
  { path: 'markup', type: 'number' },
  {
    path: 'maxQuoteDepth',
    type: 'number',
  },
  {
    path: 'minAllowedVolume',
    type: 'number',
  },
  {
    path: 'minSpreadMarkup',
    type: 'number',
  },
  {
    path: 'priceDeviation',
    type: 'number',
  },
  {
    path: 'priceMultiplier',
    type: 'number',
  },
  {
    path: 'quotePriceMultiplier',
    type: 'number',
  },
  {
    path: 'quoteVolumeMultiplier',
    type: 'number',
  },
  {
    path: 'restartOnAbsentQuotesTimeout',
    type: 'number',
  },
  {
    path: 'restartSubscriptionTimeout',
    type: 'number',
  },
  {
    path: 'strikePriceMultiplier',
    type: 'number',
  },
  {
    path: 'tradePriceMultiplier',
    type: 'number',
  },
  {
    path: 'tradeVolumeMultiplier',
    type: 'number',
  },
  {
    path: 'volumeMultiplier',
    type: 'number',
  },
  { path: 'currency', type: 'string' },
  {
    path: 'exchangeName',
    type: 'string',
  },
  {
    path: 'symbolName',
    type: 'string',
  },
  {
    path: 'markupUnit',
    type: 'string',
  },
  {
    path: 'zeusCalcData.fetchGreeks',
    type: 'boolean',
  },
  {
    path: 'zeusCalcData.fetchImpliedForwardPrice',
    type: 'boolean',
  },
  {
    path: 'zeusCalcData.fetchImpliedVolatility',
    type: 'boolean',
  },
  {
    path: 'zeusCalcData.fetchRiskFreeRate',
    type: 'boolean',
  },
  {
    path: 'zeusCalcData.fetchTheoPrice',
    type: 'boolean',
  },
  {
    path: 'zeusCalcData.fetchVolatilityIndex',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchChange',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchDailyVolume',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchLastSessionClose',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchLimitHigh',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchLimitLow',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchSessionOpen',
    type: 'boolean',
  },
  {
    path: 'auxCalcData.fetchVolume24',
    type: 'boolean',
  },
  {
    path: 'bondCalcData.fetchAci',
    type: 'boolean',
  },
  {
    path: 'bondCalcData.fetchDirtyPrice',
    type: 'boolean',
  },
  {
    path: 'bondCalcData.fetchNextCouponDate',
    type: 'boolean',
  },
  {
    path: 'bondCalcData.fetchPrevCouponDate',
    type: 'boolean',
  },
  {
    path: 'bondCalcData.fetchYtm',
    type: 'boolean',
  },
  {
    path: 'bondCalcData.bondDataMultipliers.ytmMultiplier',
    type: 'number',
  },
  {
    path: 'bondCalcData.bondDataMultipliers.aciMultiplier',
    type: 'number',
  },
  {
    path: 'bondCalcData.YTMLimits.max',
    type: 'number',
  },
  {
    path: 'bondCalcData.YTMLimits.min',
    type: 'number',
  },
  {
    path: 'bondCalcData.ACIDelta',
    type: 'number',
  },
  /**
   * TODO: skipped due to not implemented in form
   {
    path: 'dxfeedProperties.log',
    type: 'boolean',
  },
   {
    path: 'dxfeedProperties.mixedOrderBook',
    type: 'boolean',
  },
   {
    path: 'dxfeedProperties.useLongMaturityFormat',
    type: 'boolean',
  },
   {
    path: 'dxfeedProperties.suffix',
    type: 'string',
  },
   {
    path: 'dxfeedProperties.suffixSeparator',
    type: 'string',
  },
   */
  {
    path: 'generatorSettings.auxChangeInterval',
    type: 'string',
  },
  {
    path: 'generatorSettings.bondDataChangeInterval',
    type: 'string',
  },
  {
    path: 'generatorSettings.optionDataChangeInterval',
    type: 'string',
  },
  {
    path: 'generatorSettings.priceChangeInterval',
    type: 'string',
  },
  {
    path: 'generatorSettings.priceMode',
    type: 'string',
  },
  {
    path: 'generatorSettings.sizeChangeInterval',
    type: 'string',
  },
  {
    path: 'generatorSettings.tradeInterval',
    type: 'string',
  },
  {
    path: 'httpProperties.renewIntervalAuxData',
    type: 'number',
  },
  {
    path: 'httpProperties.renewIntervalBondData',
    type: 'number',
  },
  {
    path: 'httpProperties.renewIntervalOption',
    type: 'number',
  },
  {
    path: 'httpProperties.renewIntervalQuote',
    type: 'number',
  },
  {
    path: 'symbolIdentifier.identifier',
    type: 'string',
  },
  {
    path: 'symbolIdentifier.type',
    type: 'string',
  },
  {
    path: 'idcProperties.l2Source',
    type: 'number',
  },
  {
    path: 'idcProperties.source',
    type: 'number',
  },
  {
    path: 'idcProperties.log',
    type: 'boolean',
  },
  {
    path: 'idcProperties.symbolId',
    type: 'string',
  },
  {
    path: 'lambdaSettings.sources',
    type: 'json',
  },
  {
    path: 'lambdaSettings.handler.description',
    type: 'string',
  },
  {
    path: 'lambdaSettings.handler.parameters',
    type: 'json',
  },
  {
    path: 'lambdaSettings.handler.transform',
    type: 'json',
  },
  {
    path: 'lambdaSettings.handler.type',
    type: 'string',
  },
  {
    path: 'maturityDate',
    type: 'date',
  },
  {
    path: 'nanexProperties.suspiciousTradeDeviation',
    type: 'number',
  },
  {
    path: 'proxyProperties.feedSource',
    type: 'string',
  },
  {
    path: 'reutersProperties.flippedBids',
    type: 'boolean',
  },
  {
    path: 'reutersProperties.log',
    type: 'boolean',
  },
  {
    path: 'reutersProperties.useYields',
    type: 'boolean',
  },
  {
    path: 'reutersProperties.sourceType',
    type: 'number',
  },
  {
    path: 'reutersProperties.tradeMinPrice',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.base',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.farLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.nearLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.optionSeparator',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.strikePriceFormat',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.suffix',
    type: 'string',
  },
  {
    path: 'reutersProperties.bondRic.truncateStrikePrice',
    type: 'string',
  },

  {
    path: 'reutersProperties.quoteRicRic.base',
    type: 'string',
  },
  {
    path: 'reutersProperties.quoteRicRic.farLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.quoteRicRic.nearLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.quoteRicRic.optionSeparator',
    type: 'string',
  },
  {
    path: 'reutersProperties.quoteRicRic.strikePriceFormat',
    type: 'string',
  },
  {
    path: 'reutersProperties.quoteRicRic.suffix',
    type: 'string',
  },
  {
    path: 'reutersProperties.quoteRicRic.truncateStrikePrice',
    type: 'string',
  },

  {
    path: 'reutersProperties.ric.base',
    type: 'string',
  },
  {
    path: 'reutersProperties.ric.farLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.ric.nearLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.ric.optionSeparator',
    type: 'string',
  },
  {
    path: 'reutersProperties.ric.strikePriceFormat',
    type: 'string',
  },
  {
    path: 'reutersProperties.ric.suffix',
    type: 'string',
  },
  {
    path: 'reutersProperties.ric.truncateStrikePrice',
    type: 'string',
  },

  {
    path: 'reutersProperties.tradeRic.base',
    type: 'string',
  },
  {
    path: 'reutersProperties.tradeRic.farLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.tradeRic.nearLegPrefix',
    type: 'string',
  },
  {
    path: 'reutersProperties.tradeRic.optionSeparator',
    type: 'string',
  },
  {
    path: 'reutersProperties.tradeRic.strikePriceFormat',
    type: 'string',
  },
  {
    path: 'reutersProperties.tradeRic.suffix',
    type: 'string',
  },
  {
    path: 'reutersProperties.tradeRic.truncateStrikePrice',
    type: 'string',
  },
  {
    path: 'spreadType',
    type: 'string',
  },
  {
    path: 'syntheticSettings.maxSourceDeviation',
    type: 'number',
  },
  {
    path: 'syntheticSettings.sources',
    type: 'json',
  },
];

export default FEEDS_PROVIDER_OVERRIDES;
