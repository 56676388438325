export const enum InstrumentFormActions {
  AddChild = 'Instruments Form: Add Child',
  AppendOverride = 'Instruments Form: Append Override',
  DeleteError = 'Instruments Form: Delete Error',
  DeleteStart = 'Instruments Form: Delete Start',
  DeleteSucceed = 'Instruments Form: Delete Succeed',
  ConvertError = 'Instruments Form: Convert Error',
  ConvertStart = 'Instruments Form: Convert Start',
  ConvertSucceed = 'Instruments Form: Convert Succeed',
  DiscardChanges = 'Instruments Form: Discard Changes',
  FetchError = 'Instruments Form: Fetch Error',
  FetchStart = 'Instruments Form: Fetch Start',
  FetchSucceed = 'Instruments Form: Fetch Succeed',
  Remove = 'Instruments Form: Remove',
  SaveError = 'Instruments Form: Save Error',
  SaveStart = 'Instruments Form: Save Start',
  SaveSucceed = 'Instruments Form: Save Succeed',
  SetFieldValue = 'Instruments Form: Set Field Value',
  SetReady = 'Instruments Form: Set Ready',
  StackOrderChange = 'Instruments Form: Stack Order Change',
}
