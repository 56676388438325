import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  BoolRow,
  BoolTable,
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import { Ric } from './components';
import type { SelectedFeedProvider } from './types';

const ReutersProperties = ({ id }: SelectedFeedProvider) => {
  const { current: sourceTypeOptions } = useRef<ISelectOption[]>([
    { value: 'bond', label: 'BOND' },
    { value: 'bond_rrps', label: 'BOND_RRPC' },
    { value: 'bond_otc', label: 'BOND_OTC' },
    { value: 'l1', label: 'L1' },
    { value: 'l2', label: 'L2' },
    { value: 'index', label: 'INDEX' },
  ]);

  return (
    <Section>
      <SectionTitle>Reuters feed</SectionTitle>

      <Ric id={id} title="Ric" type="ric" />
      <Ric id={id} title="Quote ric" type="quoteRic" />
      <Ric id={id} title="Trade ric" type="tradeRic" />
      <Ric id={id} title="Bond ric" type="bondRic" />

      <BoolTable>
        <BoolRow path={`feeds.providerOverrides.${id}.reutersProperties.log`}>
          Log
        </BoolRow>
      </BoolTable>

      <Select
        options={sourceTypeOptions}
        path={`feeds.providerOverrides.${id}.reutersProperties.sourceType`}
      >
        Source type
      </Select>

      <BoolTable>
        <BoolRow
          path={`feeds.providerOverrides.${id}.reutersProperties.flippedBids`}
        >
          Flipped bids
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.reutersProperties.useYields`}
        >
          Use yields
        </BoolRow>
      </BoolTable>

      <TextInput
        path={`feeds.providerOverrides.${id}.reutersProperties.tradeMinPrice`}
      >
        Trade min price
      </TextInput>
    </Section>
  );
};

export default ReutersProperties;
