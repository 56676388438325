import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input, Select } from 'react-ui-kit-exante';

import styles from '../../ExchangeForm.module.css';
import { ListFormPlaceholder } from '../ListFormPlaceholder';

import { TBrokersFormProps } from './types';

export const BrokersForm: FC<TBrokersFormProps> = ({
  addBroker,
  brokersArray,
  brokersFieldControl,
  brokersOptions,
  fieldName = 'brokers',
  form,
}) => {
  return (
    <div className={styles.List}>
      <div className={styles.ListHeader}>
        <span className={styles.ListTitle}>Brokers</span>
        <IconButton
          iconColor="action"
          iconName="add"
          iconSize={24}
          label="Add Broker"
          onClick={addBroker}
        />
      </div>

      {brokersArray?.length === 0 && <ListFormPlaceholder name="brokers" />}

      {brokersArray?.length > 0 && (
        <div className={styles.ListBody}>
          <div className={styles.ListForm}>
            {brokersArray.map((broker, index) => (
              <div className={styles.ListFormRow} key={broker.id}>
                <Controller
                  key={`${fieldName}.${index}.brokerId`}
                  name={`${fieldName}.${index}.brokerId`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Select
                      options={brokersOptions}
                      label="Broker *"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  key={`${fieldName}.${index}.override`}
                  name={`${fieldName}.${index}.override`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Input
                      label="Broker Name Override"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />

                <div className={styles.DeleteButton}>
                  <IconButton
                    iconColor="radical"
                    iconName="delete"
                    iconSize={20}
                    onClick={() => brokersFieldControl.remove(index)}
                    style={{ marginRight: '5px' }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
