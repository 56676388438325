import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  border-right: 1px solid ${(props) => props.theme.color.bg.basic};
`;
export const Header = styled.div`
  display: grid;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.color.bg.basic};
  grid-template-columns: 1fr;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 8px solid ${(props) => props.theme.color.dropdown.border};
`;

export const UpButton = styled(KeyboardArrowUpIcon)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  color: ${(props) => props.theme.color.icon.secondary}
  cursor: pointer;
  transform: scale(.75) translateY(3px);
  border-radius: 50%;

  ${({ disabled }) => {
    if (disabled) {
      return '';
    }

    return `
    cursor: pointer;
    &:hover {
      transform: scale(.85) translateY(3px);
    }
    `;
  }}
`;

export const DownButton = styled(KeyboardArrowDownIcon)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  color: ${(props) => props.theme.color.icon.secondary}
  cursor: pointer;
  transform: scale(.75) translateY(-3px);
  border-radius: 50%;

  ${({ disabled }) => {
    if (disabled) {
      return '';
    }

    return `
    cursor: pointer;
    &:hover {
      transform: scale(.85) translateY(-3px);
    }
    `;
  }}
`;

export const StackItemContainer = styled.div<{
  sortable?: boolean;
  selected?: boolean;
}>`
  min-height: 48px;
  user-select: none;
  display: grid;
  grid-template-columns: ${(props) => (props.sortable ? '48px 1fr' : '1fr')};

  &:hover {
    background: ${(props) => props.theme.color.bg.secondary};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.color.bg.basic};
  }

  background-color: ${(props) =>
    props.selected ? props.theme.color.bg.basic : props.theme.color.bg.primary};
`;

export const StackItemActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 48px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.color.bg.basic};
`;

export const StackItemLabel = styled.span<{
  isBold?: boolean;
  sortable?: boolean;
  selected?: boolean;
}>`
  color: ${(props) =>
    props.selected
      ? props.theme.color.typo.primary
      : props.theme.color.typo.action};
  font-size: 15px;
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  line-height: 24px;
  cursor: pointer;
  padding: 8px ${(props) => (props.sortable ? '16px' : '24px')};
  display: flex;
  align-items: center;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
`;

export const OptionItem = styled.div`
  display: flex;
  min-height: 48px;
  padding: 8px 24px;
  align-items: center;
  color: ${(props) => props.theme.color.typo.action};
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${(props) => props.theme.color.bg.secondary};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.color.bg.basic};
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
  }
`;

export const Placeholder = styled.div`
  height: 48px;
  padding: 8px 24px;
  color: ${(props) => props.theme.color.typo.primary};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SortableStackArrow = styled.div`
  display: flex;
  margin-left: 4px;

  & svg {
    color: ${(props) => props.theme.color.icon.action};
  }
`;
