import * as yup from 'yup';

export const schema = yup.object().shape({
  _id: yup
    .string()
    .required('Name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
  description: yup.string(),
  leverageRate: yup.number().required(),
  leverageRateShort: yup.number().required(),
  extremeLeverageRate: yup.number().nullable(),
  extremeLeverageRateShort: yup.number().nullable(),
  fractionDigits: yup.number().nullable(),
  isCrypto: yup.boolean(),
  isNav: yup.boolean(),
  code: yup.number().nullable(),
  weight: yup.number().nullable(),
  currencySymbol: yup.string().nullable(),
  icon: yup.string().url('Icon should be a valid URL'),
});
