import equal from 'fast-deep-equal';
import * as objectPath from 'object-path-immutable';

import { filterFromEmpty } from '~/utils/object';

import { InstrumentData, InstrumentFormState, OrderValue } from '../types';

const onStackOrderChange = (
  state: InstrumentFormState,
  path: string,
  [nodeA, nodeB]: [OrderValue, OrderValue],
): InstrumentFormState => {
  if (!nodeA.key || !nodeB.key) {
    return state;
  }

  let values = objectPath.set<InstrumentData>(
    state.values,
    `${path}.${nodeA.key}.order`,
    nodeB.index,
  );

  values = objectPath.set<InstrumentData>(
    values,
    `${path}.${nodeB.key}.order`,
    nodeA.index,
  );

  return {
    ...state,

    values,
    dirty: !equal(filterFromEmpty(values), filterFromEmpty(state.ref)),
  };
};

export default onStackOrderChange;
