import { styled } from '@mui/material/styles';
import { Switch, TDefaultTheme } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  backgroundColor: '#fff',
}));

export const SidebarWrapper = styled('div')(() => ({
  display: 'flex',
  width: '380px',

  '& > div': {
    width: '100%',
  },
}));

export const Tree = styled('div')(({ theme }: TDefaultTheme) => ({
  paddingTop: '10px',
  border: `1px ${theme?.color?.dropdown?.border} solid`,
}));

export const Forms = styled('div')(() => ({
  width: '100%',
}));

export const ScheduleBlock = styled('div')(({ theme }: TDefaultTheme) => ({
  padding: '24px 24px 15px',
  borderBottom: `1px ${theme?.color?.dropdown?.border} solid`,
  backgroundColor: '#ffff',
  h2: {
    font: '400 32px FoxSansPro,sans-serif',
    marginTop: '5px',
  },
  '.wrapper': {
    fontFamily: 'Inter, sans-serif',
  },
}));

export const StyledSwitch = styled(Switch)(() => ({
  marginBottom: '10px !important',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  minHeight: '500px',
  backgroundColor: '#fff',
}));

export const Form = styled('div')(({ theme }: TDefaultTheme) => ({
  borderRight: `1px ${theme?.color?.dropdown?.border} solid`,
  width: '45%',
}));

export const Label = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  span: {
    width: '100%',
  },
}));
