import React, { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { IconButton, Loader } from 'react-ui-kit-exante';

import { DeleteDialog } from '../../../../components/DeleteDialog';

import styles from './ExecutionSchemaForm.module.css';
import { MainForm, Trades } from './components';
import { useExecutionSchemaForm } from './hooks';
import { ExecutionSchemaProps } from './types';

const ExecutionSchemaForm: FC<ExecutionSchemaProps> = ({
  defaultLegalEntity,
  legalEntitiesList,
  onClose,
  onDelete,
  onSuccess,
  onDirty,
}) => {
  const {
    deletingStatus,
    fetchingStatus,
    form,
    handleDelete,
    isNew,
    isSaveDisabled,
    savingStatus,
    title,
    onSubmit,
    isDirty,
    id,
  } = useExecutionSchemaForm({ onDelete, onSuccess }, defaultLegalEntity);

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <div className={styles.Loader}>
        <Loader size="l" isCentered />
      </div>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit} className={styles.Container}>
          <div className={styles.Header}>
            <span className={styles.Title}>{title}</span>
            <div className={styles.Actions}>
              <IconButton
                disabled={isSaveDisabled || savingStatus.isPending}
                iconColor={isSaveDisabled ? 'secondary' : 'action'}
                iconName="save"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="delete"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="close"
                iconSize={24}
                onClick={onClose}
              />
            </div>
          </div>

          <MainForm form={form} />
          <Trades
            defaultLegalEntity={defaultLegalEntity}
            legalEntitiesList={legalEntitiesList}
            form={form}
          />
        </form>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDelete}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default ExecutionSchemaForm;
