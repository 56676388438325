import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { SortableListItem } from '../../../../../../../components/SortableList/types';
import { FormValue } from '../../../../../types';

const useTrades = (
  { control }: UseFormReturn<FormValue>,
  legalEntity: string,
) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { append, remove, fields, move } = useFieldArray({
    name: 'trades',
    control,
  });

  const handleAppend = () =>
    append({
      legalEntity,
      name: 'New',
      uuid: (fields.length + 1).toString(),
      logicType: 'client',
      applyMarkup: 'unset',
      overrideSymbolId: 'unset',
      accountId: '',
      allowedEntities: [],
      counterpartyAccountId: '',
      counterpartyClientId: '',
      executionCounterparty: '',
      forbiddenEntities: [],
      settlementCounterparty: '',
      userId: '',
    });

  const list = useMemo<Array<SortableListItem>>(
    () =>
      fields.map((field) => ({
        name: field.name,
        id: field.uuid,
      })),
    [fields],
  );

  const index = useMemo<number>(
    () => list.findIndex((item) => item.id === selectedId),
    [list, selectedId],
  );

  useEffect(() => {
    if (list.length > 0) {
      setSelectedId(list[0].id);
    }
  }, [list]);

  return {
    handleAppend,
    handleDelete: remove,
    handleOrder: move,

    selectedId,
    setSelectedId,

    fields,
    list,
    index,
    control,
  };
};

export default useTrades;
