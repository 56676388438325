import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  gap: 12px;
`;

export const DatePickerWrapper = styled.div`
  & > div > div {
    width: 100%;
  }
`;
