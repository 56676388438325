import {
  BoolRow,
  BoolTable,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Section, SectionTitle } from '../../styled';

import { Sources } from './components';
import type { SelectedFeedProvider } from './types';

const SyntheticSettings = ({ id }: SelectedFeedProvider) => {
  return (
    <Section>
      <SectionTitle>Synthetic settings</SectionTitle>
      <TextInput
        path={`feeds.providerOverrides.${id}.syntheticSettings.maxSourceDeviation`}
      >
        Max source deviation
      </TextInput>

      <Sources id={id} />

      <TextInput
        path={`feeds.providerOverrides.${id}.syntheticSettings.minSpread`}
      >
        Min spread
      </TextInput>
      <BoolTable>
        <BoolRow
          path={`feeds.providerOverrides.${id}.syntheticSettings.enableMarketDepth`}
        >
          Sources enable market depth
        </BoolRow>
        <BoolRow
          path={`feeds.providerOverrides.${id}.syntheticSettings.sendEmptyQuote`}
        >
          Sources send empty quote
        </BoolRow>
      </BoolTable>
    </Section>
  );
};

export default SyntheticSettings;
