import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.nav`
  display: grid;
  grid-template-rows: 1fr 48px;
  border-right: 1px solid ${({ theme }) => theme.color.line.secondary};
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
`;

export const ListItem = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.typo.primary};
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.color.bg.basic};
  }

  &.active {
    background-color: ${({ theme }) => theme.color.bg.promo};
    color: ${({ theme }) => theme.color.typo.inverse};
  }
`;
