import { Button as MaterialButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Panel, TDefaultTheme } from 'react-ui-kit-exante';

export const Wrapper = styled(Panel)(({ theme }: TDefaultTheme) => ({
  borderBottom: `1px ${theme?.color?.dropdown?.border} solid`,
  h2: {
    margin: '10px 0',
  },
}));

export const Time = styled('time')(({ theme, color }: TDefaultTheme) => ({
  fontSize: '15px',
  color: theme?.color?.typo?.promo,
  visibility: color ? 'inherit' : 'hidden',
}));

export const Indicator = styled('div')(
  ({ theme, color, onClick }: TDefaultTheme) => ({
    width: onClick ? '100%' : '32%',
    height: '40px',
    textAlign: 'center',
    borderBottom: `solid ${color || theme?.color?.bg?.basic} 5px`,
    color: theme?.color?.typo?.promo,
  }),
);

export const Indicators = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '12px',
  fontSize: '13px',
}));

export const IndicatorWithAction = styled('div')(() => ({
  width: '32%',
}));

export const Actions = styled('div')(() => ({
  marginTop: '9px',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Button = styled(MaterialButton)(
  ({ theme, disabled }: TDefaultTheme) => ({
    padding: 0,
    minWidth: 'auto',
    cursor: disabled ? 'auto' : 'pointer',
    '& > svg': {
      width: 32,
      height: 32,
    },
    '&:hover': {
      color: theme?.color?.icon?.primary,
    },
    '& > svg > path': {
      stroke: disabled ? theme?.color?.icon?.ghost : theme?.color?.icon?.action,
    },
  }),
);
