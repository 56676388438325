import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { SortableListItem } from '~/components/SortableList/types';
import { getUUIDKey } from '~/utils/uuid';

import { FormValues } from '../../types';

const useGateways = ({ control }: UseFormReturn<FormValues>) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { append, remove, fields } = useFieldArray({
    name: 'gateways',
    control,
  });

  const handleAppend = () =>
    append({
      name: 'New',
      uuid: getUUIDKey(),
      address: '',
      environment: '',
      manualExecution: false,
      accounts: [],
      capacity: '',
    });

  const list = useMemo<Array<SortableListItem>>(
    () =>
      fields.map((field) => ({
        name: field.name,
        id: field.uuid,
      })),
    [fields],
  );

  const index = useMemo<number>(
    () => list.findIndex((item) => item.id === selectedId),
    [list, selectedId],
  );

  useEffect(() => {
    if (list.length > 0) {
      setSelectedId(list[0].id);
    }
  }, []);

  useEffect(() => {
    if (list.length === 1) {
      setSelectedId(list[0].id);
    }
  }, [list]);

  return {
    handleAppend,
    handleDelete: remove,

    selectedId,
    setSelectedId,

    fields,
    list,
    index,
    control,
  };
};

export default useGateways;
