import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  IconButton,
  Input,
  Select,
  Switch,
  TimePicker,
} from 'react-ui-kit-exante';

import { DurationTypes } from '../../../../../../components/DurationTypes';
import { SortableList } from '../../../../../../components/SortableList';

import styles from './ScheduleSectionForm.module.css';
import useOptions from './hooks/useOptions';
import useScheduleSection from './hooks/useScheduleSection';
import { ScheduleSectionFormProps } from './types';

const ScheduleSectionForm: FC<ScheduleSectionFormProps> = ({
  form,
  ruleIndex,
}) => {
  const { markerOptions, sessionOptions } = useOptions();

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const {
    handleAppend,
    list,
    fieldControl,
    getLabel,
    getTooltip,
    scheduleRuleIndex,
    handleRemove,
  } = useScheduleSection(form, ruleIndex, selectedId, markerOptions);

  const feed = form.watch(
    `rules.${ruleIndex}.schedule.${scheduleRuleIndex}.feed`,
  );

  useEffect(() => {
    if (list.length > 0) {
      setSelectedId(list[0].id);
    }
  }, [list]);

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <IconButton
          iconColor="action"
          iconName="add"
          iconSize={24}
          label="Add schedule"
          onClick={handleAppend}
        />
      </div>

      {list.length === 0 && (
        <div className={styles.Placeholder}>This rule has no schedules.</div>
      )}

      {list.length > 0 && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={styles.Form}>
            <SortableList
              items={list}
              selectedId={selectedId}
              onSelect={setSelectedId}
              onOrderChange={fieldControl.move}
              getLabel={getLabel}
              getTooltip={getTooltip}
            />

            <div className={styles.Controls}>
              <div className={styles.Row}>
                <Controller
                  key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.from.time`}
                  name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.from.time`}
                  control={form.control}
                  render={({ field, fieldState }) => {
                    return (
                      <TimePicker
                        inputFormat="HH:MM:SS"
                        label="From"
                        value={field.value}
                        error={Boolean(fieldState.error?.message)}
                        onAccept={field.onChange}
                        onChange={() => {}}
                      />
                    );
                  }}
                />

                <Controller
                  key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.from.marker`}
                  name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.from.marker`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Select
                      options={markerOptions}
                      label="Marker"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <div className={styles.Row}>
                <Controller
                  key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.to.time`}
                  name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.to.time`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TimePicker
                      inputFormat="HH:MM:SS"
                      label="To"
                      value={field.value}
                      error={Boolean(fieldState.error?.message)}
                      onAccept={field.onChange}
                      onChange={() => {}}
                    />
                  )}
                />

                <Controller
                  key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.to.marker`}
                  name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.to.marker`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Select
                      options={markerOptions}
                      label="Marker"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <div className={styles.SubRow}>
                <Controller
                  key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.feed`}
                  name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.feed`}
                  control={form.control}
                  render={({ field }) => (
                    <Switch
                      checked={feed}
                      label="Feed"
                      onChange={field.onChange}
                    />
                  )}
                />

                <Controller
                  key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.type`}
                  name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.type`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Select
                      options={sessionOptions}
                      label="Session Type"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <Controller
                key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.availableOrderDurationTypes`}
                name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.availableOrderDurationTypes`}
                control={form.control}
                render={({ field }) => (
                  <DurationTypes
                    title="Available order duration types"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.description`}
                name={`rules.${ruleIndex}.schedule.${scheduleRuleIndex}.description`}
                control={form.control}
                render={({ field }) => (
                  <Input
                    label="Description"
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <IconButton
                iconColor="radical"
                iconName="delete"
                iconSize={20}
                onClick={() => handleRemove(scheduleRuleIndex)}
                style={{
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              />
            </div>
          </div>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default ScheduleSectionForm;
