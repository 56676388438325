export abstract class Logger {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public error(error: unknown): void {}
}

export interface ILogHandleTimeRequest {
  end: string;
  entity: string;
  start: string;
}
