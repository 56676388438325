import styled from 'styled-components';

import { Theme } from '~/theme';

import { RadioProps } from './types';

const getColor = (view: RadioProps['view'], theme: Theme): string => {
  if (view === 'secondary') {
    return theme.color.typo.ghost;
  }

  return theme.palette.primary.main;
};

const getSize = (view: RadioProps['view']): string => {
  return view === 'secondary' ? '8px' : '10px';
};

const getShift = (view: RadioProps['view']): string => {
  const shift = view === 'secondary' ? '3px' : '2px';

  return `translate(${shift}, ${shift})`;
};

export const Container = styled.div<{ disabled?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
export const Input = styled.span<{
  view: RadioProps['view'];
  checked: boolean;
}>`
  display: block;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.typo.secondary};
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.color.bg.primary};
  position: relative;

  &:before {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    content: '';
    position: absolute;
    width: ${({ view }) => getSize(view)};
    height: ${({ view }) => getSize(view)};
    background-color: ${({ view, theme }) => getColor(view, theme)};
    border-radius: 50%;
    transform: ${({ view }) => getShift(view)};
  }
`;
export const Title = styled.span<{ view: RadioProps['view'] }>`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 13px;
`;
