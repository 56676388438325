import * as objectPath from 'object-path-immutable';

import { InstrumentData } from '../../useForm';

import { OverrideNode } from './types';

export const canBeSortable = (data: InstrumentData, path: string): boolean =>
  Boolean(objectPath.get(data, path));

export const hasSelfValues = (node: OverrideNode, id?: string): boolean => {
  const result = node.sources.find((src) => src.id === id);

  if (!result) {
    return false;
  }

  return result.values.length > 0;
};

export const hasInheritedValues = (
  node: OverrideNode,
  id?: string,
): boolean => {
  const results = node.sources.filter((src) => src.id !== id);

  if (results.length === 0) {
    return false;
  }

  return results.some((result) => result.values.length > 0);
};
