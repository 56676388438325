import { useContext } from 'react';

import { InstrumentFormContext } from '../../useForm';

const useConvertType = () => {
  const { state } = useContext(InstrumentFormContext);

  const isRoot = state.values.isAbstract;

  return {
    isRoot,
    title: isRoot ? 'Convert to Child' : 'Convert to Folder',
  };
};

export default useConvertType;
