import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 232px 1fr;
  height: 100vh;
  width: 100vw;
`;

export const RoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
