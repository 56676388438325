import { forwardRef, Ref, useImperativeHandle } from 'react';

import { SidebarRef } from '../../types';

import { Filter, Tree } from './components';
import { SidebarProps } from './components/Tree/types';
import { useFilter, useTree } from './hooks';
import { Container } from './styled';

const Sidebar = (
  { hideInstrumentIcons, showSyncOnSchedule }: SidebarProps,
  ref: Ref<SidebarRef>,
) => {
  const filter = useFilter();

  const {
    data,
    expanded,
    handleForceReset,
    handleRefresh,
    handleSelectNode,
    handleFetchMore,
    loading,
    selected,
  } = useTree(filter.state);

  useImperativeHandle(ref, () => {
    return {
      refresh: (id?: string) => {
        filter.onReset();
        handleForceReset(id);
      },
    };
  });

  return (
    <Container>
      <Filter {...filter} onRefresh={handleRefresh} />
      <Tree
        data={data}
        expanded={expanded}
        hideInstrumentIcons={hideInstrumentIcons}
        loading={loading}
        onSelectNode={handleSelectNode}
        onFetchMore={handleFetchMore}
        searchText={filter.state.search}
        selected={selected}
        showSyncOnSchedule={showSyncOnSchedule}
      />
    </Container>
  );
};

export default forwardRef(Sidebar);
