import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import {
  Checkbox,
  IconButton,
  Input,
  Notification,
  Panel,
} from 'react-ui-kit-exante';

import { symbolDBService } from '~/services/symbolDB.service';
import { Currency } from '~/types/models';
import { getProp } from '~/utils/getProp';
import { sanitizedValues } from '~/utils/sanitizaValues';

import { SuccessMessages } from '../../Themes/constants';
import themeStyles from '../../Themes/themes.module.css';
import { EDITABLE_FIELDS, FIXED_ORDER } from '../constants';
import { ICurrencyFormProps } from '../types';

import styles from './CurrencyForm.module.css';
import { schema } from './CurrencyForm.schema';

export const CurrencyForm = ({
  currency,
  isNewCurrency,
  onDelete,
  onClose,
  onUpdateCurrencies,
  title = 'New Currency',
}: ICurrencyFormProps) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Currency>({
    defaultValues: currency,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // debugger;
    reset(
      { ...currency },
      // {
      //   keepValues: false,
      //   keepDirty: false,
      //   keepDefaultValues: false,
      // },
    );
  }, [currency, reset]);

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id } = currency;
    onDelete(_id);
  }, [onDelete, currency]);

  const onSubmit = async (values: Currency) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id } = values;
    try {
      if (isNewCurrency) {
        await symbolDBService().createCurrency(sanitizedValues(values));
      } else {
        await symbolDBService().updateCurrency(sanitizedValues(values), _id);
      }
      Notification.success({
        title: isNewCurrency ? SuccessMessages.Create : SuccessMessages.Update,
      });
      onUpdateCurrencies();
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      onClose();
    }
  };

  const controls = useMemo(
    () => (
      <div className={themeStyles.Controls}>
        <IconButton
          data-test-id="currency__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="save"
          iconSize={24}
          label="Save"
          type="submit"
        />
        {!isNewCurrency && (
          <IconButton
            data-test-id="currency__button--delete"
            iconColor="radical"
            iconName="delete"
            iconSize={24}
            label="Delete"
            onClick={handleDelete}
          />
        )}
        <IconButton
          iconColor="secondary"
          iconName="close"
          iconSize={24}
          onClick={onClose}
        />
      </div>
    ),
    [isDirty, isNewCurrency, handleDelete, onClose],
  );

  const renderControl = useCallback(
    (key: keyof Currency): ReactNode => {
      const prop = getProp(currency, key);
      const error = getProp(errors, key) as FieldError;

      if (typeof prop === 'object' && !Array.isArray(prop) && prop !== null) {
        return Object.keys(prop).map((k) => {
          const nestedKey = `${key}.${k}`;

          return renderControl(nestedKey as keyof Currency);
        });
      }

      const isDisabled = !EDITABLE_FIELDS.includes(key);

      if (typeof prop === 'boolean') {
        return (
          <Controller
            key={key}
            name={key}
            control={control}
            defaultValue={prop}
            render={({ field }) => {
              return (
                <div>
                  <Checkbox
                    disabled={isDisabled}
                    checked={field.value as boolean}
                    label={key}
                    {...field}
                    sx={{ ml: '1px', mt: '16px' }}
                  />
                </div>
              );
            }}
          />
        );
      }

      if (key === 'icon' && !isNewCurrency) {
        return (
          <Controller
            key={key}
            name={key}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <Input
                  disabled={isDisabled}
                  error={Boolean(errors[key])}
                  message={error?.message}
                  fullWidth
                  label={key}
                  {...field}
                  sx={{ mt: '16px', width: '50%' }}
                />
                {field.value && (
                  <div className={themeStyles.Image}>
                    <img alt="" src={field.value} width={50} height={50} />
                  </div>
                )}
              </>
            )}
          />
        );
      }

      return (
        <Controller
          key={key}
          name={key}
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <Input
              disabled={isDisabled || (!isNewCurrency && key === '_id')}
              error={Boolean(fieldState.error?.message)}
              fullWidth
              label={key === '_id' ? 'Name' : key}
              {...field}
              sx={{ mt: '16px', width: '50%' }}
              message={fieldState.error?.message}
            />
          )}
        />
      );
    },
    [control, errors, isNewCurrency, currency],
  );

  return (
    <form className={styles.Wrapper} onSubmit={handleSubmit(onSubmit)}>
      <Panel title={currency?._id || title} action={controls}>
        <div className={styles.Fields}>{FIXED_ORDER.map(renderControl)}</div>
      </Panel>
    </form>
  );
};
