import { ReactComponent as ChatIcon } from '~/assets/i/Chat.svg';

import { IconContainer, Message, PlaceholderContainer } from './styled';

const Placeholder = () => {
  return (
    <PlaceholderContainer>
      <IconContainer>
        <ChatIcon />
      </IconContainer>
      <Message>
        <span>Select any Instrument on the tree </span>
      </Message>
    </PlaceholderContainer>
  );
};

export default Placeholder;
