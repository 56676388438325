import { useEffect, useState } from 'react';

import { staticDataService } from '../../../services/staticData.service';

import { IMapping } from './types';

export const useMappings = () => {
  const [mappings, setMappings] = useState<IMapping[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { maps } = await staticDataService().getMaps();
        setMappings(maps);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    isLoading,
    mappings,
  };
};
