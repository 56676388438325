import { FC, useCallback, useState, SyntheticEvent, ChangeEvent } from 'react';
import { Autocomplete, Input } from 'react-ui-kit-exante';

import { AddButton } from '../Buttons/AddButton';

import { IAddLocale } from './AddField.types';
import { Root } from './styled';

export const AddField: FC<IAddLocale> = ({
  dataTestIdPrefix,
  className,
  onClick,
  placeholder,
  buttonText,
  options,
}) => {
  const [value, setValue] = useState<string>('');

  const handleOnClick = useCallback(() => {
    onClick(value);
    setValue('');
  }, [onClick, value]);

  const handleOnAutocompleteChange = useCallback(
    (event: SyntheticEvent<Element, Event>, val: string | string[] | null) => {
      if (!val) {
        setValue('');
      }
      setValue(val as string);
    },
    [],
  );

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  return (
    <Root className={className}>
      {options?.length ? (
        <Autocomplete
          dataTestId={`localizations__input--${dataTestIdPrefix}`}
          freeSolo
          fullWidth
          options={options}
          onInputChange={handleOnAutocompleteChange}
          controlProps={{
            placeholder,
          }}
          value={value}
          sx={{ flex: 'auto' }}
        />
      ) : (
        <Input
          data-test-id={`localizations__input--${dataTestIdPrefix}`}
          fullWidth
          onChange={handleOnInputChange}
          label={placeholder}
        />
      )}

      <AddButton
        dataTestId={`localizations__button--${dataTestIdPrefix}`}
        onClick={handleOnClick}
        text={buttonText}
      />
    </Root>
  );
};
