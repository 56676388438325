import { useContext, useMemo, useState } from 'react';

import { DateObject, DateTimeObject } from '~/types/shared';

import { InstrumentFormContext } from '../../useForm';
import { InstrumentFormActions } from '../../useForm/actions';
import { getInheritValue, getSelfValue } from '../../useForm/utils';
import {
  getDateObject,
  getDateValue,
  getDateTimeString,
  getInitialDateValue,
} from '../utils';

const useDateTime = (path: string) => {
  const { state, dispatch } = useContext(InstrumentFormContext);

  const selfDateValue = useMemo<DateObject | undefined>(() => {
    const data = getSelfValue<DateTimeObject>(path, state.values);

    if (!data) {
      return undefined;
    }

    const { year, day, month } = data;

    if (year !== undefined && day !== undefined && month !== undefined) {
      return { year, day, month };
    }

    return undefined;
  }, [path, state.values]);

  const selfTimeValue = getSelfValue<string>(`${path}.time`, state.values);

  const inheritDateValue = useMemo<DateObject | undefined>(() => {
    const data = getInheritValue<DateTimeObject>(path, state.parents);

    if (!data) {
      return undefined;
    }

    const { year, day, month } = data;

    if (year !== undefined && day !== undefined && month !== undefined) {
      return { year, day, month };
    }

    return undefined;
  }, [path, state.parents]);

  const inheritTimeValue = getInheritValue<string>(
    `${path}.time`,
    state.parents,
  );

  const initialDateValue = getDateValue(
    getInitialDateValue(selfDateValue, inheritDateValue),
  );

  const initialTimeValue = selfTimeValue || inheritTimeValue;

  const [dateValue, setDateValue] = useState<Date | null>(initialDateValue);
  const [timeValue, setTimeValue] = useState<string | null | undefined>(
    initialTimeValue,
  );

  const disabled = state.saveStatus.pending;
  const isDateInherited = inheritDateValue !== undefined;
  const isTimeInherited = inheritTimeValue !== undefined;

  const isResetDateButtonActive = Boolean(
    inheritDateValue && selfDateValue !== undefined,
  );

  const isResetTimeButtonActive = Boolean(
    inheritTimeValue && selfTimeValue !== undefined,
  );

  const inheritDateTitle = useMemo(
    () => getDateTimeString(inheritDateValue),
    [inheritDateValue],
  );

  const inheritTimeTitle = useMemo(() => inheritTimeValue, [inheritTimeValue]);

  const handleDateChange = (date: Date | null) => {
    if (!disabled) {
      setDateValue(date);

      const payloadValue = getDateObject(date);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: `${path}.day`, value: payloadValue?.day },
      });

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: `${path}.month`, value: payloadValue?.month },
      });

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: `${path}.year`, value: payloadValue?.year },
      });
    }
  };

  const handleTimeChange = (time: string | null) => {
    if (!disabled) {
      setTimeValue(time);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: `${path}.time`, value: time },
      });
    }
  };

  const handleDateReset = () => {
    if (isDateInherited && !disabled) {
      if (
        inheritDateValue.day &&
        inheritDateValue.month &&
        inheritDateValue.year
      ) {
        setDateValue(getDateValue(inheritDateValue));
      }

      if (timeValue) {
        dispatch({
          type: InstrumentFormActions.SetFieldValue,
          payload: { path, value: { time: timeValue } },
        });
      } else {
        dispatch({
          type: InstrumentFormActions.SetFieldValue,
          payload: { path, value: undefined },
        });
      }
    }
  };

  const handleTimeReset = () => {
    if (isTimeInherited && !disabled) {
      setTimeValue(inheritTimeValue);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path: `${path}.time`, value: undefined },
      });
    }
  };

  const dateError =
    state.errors.get(`${path}.day`) ||
    state.errors.get(`${path}.month`) ||
    state.errors.get(`${path}.year`);

  const timeError = state.errors.get(`${path}.time`);

  return {
    dateError,
    timeError,
    dateValue,
    timeValue,
    disabled,
    handleDateChange,
    handleTimeChange,
    handleDateReset,
    handleTimeReset,
    isDateInherited,
    isTimeInherited,
    inheritTimeValue,
    inheritDateTitle,
    inheritTimeTitle,
    inheritDateValue,
    isResetDateButtonActive,
    isResetTimeButtonActive,
  };
};

export default useDateTime;
