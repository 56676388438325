import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 380px;
  background-color: ${({ theme }) => theme.color.bg.primary};
  border-radius: 4px;
  grid-template-rows: 48px 120px 64px;
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  padding: 0 24px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  padding: 0 24px;
`;
