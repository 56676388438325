import { styled } from '@mui/material/styles';
import { Panel, TDefaultTheme } from 'react-ui-kit-exante';

export const Wrapper = styled(Panel)(({ theme }: TDefaultTheme) => ({
  borderBottom: `1px ${theme?.color?.dropdown?.border} solid`,
}));

export const Selects = styled('div')(() => ({
  display: 'flex',
  div: {
    marginRight: '5px',
  },
}));

export const Div = styled('div')(({ theme }: TDefaultTheme) => ({
  color: theme?.color?.icon?.action,
  marginBottom: '14px',
  cursor: 'pointer',
  fontSize: '13px',
}));
