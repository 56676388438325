import { Currency } from '~/types/models';

export const EDITABLE_FIELDS = [
  '_id',
  'icon',
  'description',
  'leverageRate',
  'leverageRateShort',
  'extremeLeverageRate',
  'extremeLeverageRateShort',
  'fractionDigits',
  'currencySymbol',
  'weight',
  'code',
  'isNav',
  'isCrypto',
];

export const FIXED_ORDER: (keyof Currency)[] = [
  '_id',
  'icon',
  'description',
  'leverageRate',
  'leverageRateShort',
  'extremeLeverageRate',
  'extremeLeverageRateShort',
  'fractionDigits',
  'currencySymbol',
  'weight',
  'code',
  'isNav',
  'isCrypto',
];
