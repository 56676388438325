import { Select as SelectComponent } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { SelectProps } from './types';
import useSelect from './useSelect';

const Select = ({ children, options, path }: SelectProps) => {
  const {
    isInherited,
    isResetButtonActive,
    inheritTitle,
    disabled,
    error,
    value,
    handleChange,
    handleReset,
  } = useSelect(path, options);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritTitle}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <SelectComponent
        fullWidth
        disabled={disabled}
        options={options}
        label={children}
        value={value || ''}
        onChange={handleChange}
        error={Boolean(error)}
        message={error}
      />
    </InputContainer>
  );
};

export default Select;
