import { Fragment } from 'react';
import { IconButton, TimePicker } from 'react-ui-kit-exante';

import { Section, SectionTitle } from '../../../../styled';
import type { SelectedFeedProvider } from '../../types';
import { DefaultSequences } from '../DefaultSequences';
import { Header, SequenceRow } from '../styled';

import useSequences from './useSequences';

const Sequences = ({ id }: SelectedFeedProvider) => {
  const {
    handleAddSequence,
    handleRemoveSequence,
    handleTimeChange,
    provider,
  } = useSequences(id);

  return (
    <Section>
      <Header>
        <SectionTitle>Sequences</SectionTitle>

        <IconButton
          iconColor="action"
          iconName="add"
          iconSize={24}
          label="Add Sequence"
          onClick={handleAddSequence}
        />
      </Header>

      {provider?.generatorSettings?.sequences?.map((item, index) => (
        <Fragment key={`${item.from}${item.to}`}>
          <SequenceRow>
            <TimePicker
              value={item.from || '00:00:00'}
              onChange={(value) =>
                handleTimeChange(value, 'from', index, 'change')
              }
              onAccept={(value) =>
                handleTimeChange(value, 'from', index, 'accept')
              }
              error={Boolean(
                item.from && item.from.length > 0 && item.from.length < 8,
              )}
            />
            <TimePicker
              value={item.to || '00:00:00'}
              onChange={(value) =>
                handleTimeChange(value, 'from', index, 'change')
              }
              onAccept={(value) =>
                handleTimeChange(value, 'to', index, 'accept')
              }
              error={Boolean(
                item.to && item.to.length > 0 && item.to.length < 8,
              )}
            />
            <IconButton
              iconColor="radical"
              iconName="delete"
              iconSize={20}
              onClick={() => handleRemoveSequence(index)}
            />
          </SequenceRow>

          <DefaultSequences
            id={id}
            label="Add Inner Sequence"
            sequenceIndex={index}
          />
        </Fragment>
      ))}
    </Section>
  );
};

export default Sequences;
