import { initialState } from '../constants';
import { InstrumentFormState } from '../types';

const onFetchStart = (): InstrumentFormState => ({
  ...initialState,

  fetchStatus: {
    error: null,
    pending: true,
    succeed: false,
  },
});

export default onFetchStart;
