import LaunchIcon from '@mui/icons-material/Launch';

import { OverrideProp } from '../../../../components';
import { OverrideNode } from '../../types';

import {
  Item,
  ItemLink,
  ItemTitle,
  ItemHeader,
  TargetName,
  Row,
} from './styled';

export const renderItem = (
  node: OverrideNode,
  id: string,
  path: string,
  link: string,
) => (
  <Item key={node.id}>
    <ItemHeader>
      <ItemTitle to={path}>{node.name}</ItemTitle>
      {link && (
        <ItemLink href={link} target="_blank">
          <LaunchIcon />
          <span>Goto</span>
        </ItemLink>
      )}
    </ItemHeader>

    {node.sources.map((row) => (
      <Row key={row.id}>
        {row.id !== id && <TargetName>@{row.name}</TargetName>}
        {row.values.map((value) => (
          <OverrideProp
            key={value.path}
            color={value.color}
            tooltip={value.tooltip}
          >
            {value.title}
          </OverrideProp>
        ))}
      </Row>
    ))}
  </Item>
);
