import has from 'lodash/has';

type TObj = { [key: string]: any };

export const getNestedObjectProp = (nestedObj: TObj, pathArr: Array<string>) =>
  pathArr.reduce(
    (obj, key) => (obj && has(obj, key) ? obj[key] : undefined),
    nestedObj,
  );

export const getProp = (obj: TObj, key: string) => {
  const path = key.split('.');
  return getNestedObjectProp(obj, path);
};
