import cx from 'classnames';
import React from 'react';

import styles from './PromptDialog.module.css';
import { PromptDialogProps } from './types';

const PromptDialog: React.FC<PromptDialogProps> = ({
  confirmButton,
  declineButton,
  children,
  classNames,
}) => {
  return (
    <div className={cx(styles.Container, classNames?.container)}>
      {children}
      <div className={cx(styles.Actions, classNames?.actions)}>
        {confirmButton}
        {declineButton}
      </div>
    </div>
  );
};

export default PromptDialog;
