import { ExchangeFormValue } from './types';

export const defaultValues: ExchangeFormValue = {
  name: '',
  exchangeName: '',
  treeName: '',
  description: '',
  feeds: [],
  brokers: [],
  sections: [],
  figi: '',
  mic: '',
};
