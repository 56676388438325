import { FilledInputProps } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { InstrumentFormContext } from '../../useForm';
import { InstrumentFormActions } from '../../useForm/actions';
import { getInheritValue, getSelfValue } from '../../useForm/utils';

const useTextInput = (path: string, isDisabled?: boolean) => {
  const { state, dispatch } = useContext(InstrumentFormContext);
  const selfValue = getSelfValue<string | string[]>(path, state.values);
  const inheritValue = getInheritValue<string>(path, state.parents);

  const [value, setValue] = useState(selfValue || inheritValue);

  const disabled = Boolean(state.saveStatus.pending || isDisabled);
  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const handleChange: FilledInputProps['onChange'] = ({ target }) => {
    if (!disabled) {
      setValue(target.value);
      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: target.value },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(inheritValue);

      dispatch({
        type: InstrumentFormActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  useEffect(() => {
    setValue(selfValue || inheritValue);
  }, [path, selfValue, inheritValue]);

  return {
    isInherited,
    isResetButtonActive,
    inheritValue,
    error,
    value,
    disabled,
    handleChange,
    handleReset,
  };
};

export default useTextInput;
