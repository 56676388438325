import DATES from '../../../../../../../constants/dates';
import { FormOption } from '../../../../../../../types/shared';

const useOptions = () => {
  const months = DATES.month.map((label, index) => ({
    value: index + 1,
    label,
  }));

  const monthOptions = [{ value: '*', label: 'Any' }, ...months];

  const weekDaysOptions: FormOption[] = DATES.weekDays.map((label, index) => ({
    value: String(index + 1),
    label,
  }));

  return { monthOptions, weekDaysOptions, occurrenceOptions: DATES.occurrence };
};

export default useOptions;
