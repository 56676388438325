import { InstrumentFormState } from '../types';

const onConvertSucceed = (state: InstrumentFormState): InstrumentFormState => {
  const values = {
    ...state.values,
    isAbstract: !state.values.isAbstract,
  };

  return {
    ...state,

    values,
    ref: values,

    convertStatus: {
      succeed: true,
      pending: false,
      error: false,
    },
  };
};

export default onConvertSucceed;
